import React from 'react';

import { Theme, styled } from '@material-ui/core/styles';
import { MapConstants, MapMode } from '../../../MapConstants';
import { IOpening, OpeningType } from '../../../Models/IOpening';
import * as ContextMenuItem from '../MenuContext/ContextMenuItems';
import * as CarpentryFactory from './CarpentryFactory';

import { makeStyles } from '@material-ui/styles';
import { useIsMobile } from '../../../../../Web/Context/PlatformContext/PlatformContext';
import { useMapContextState } from '../../../Context/MapContext';
import { RoomMove } from '../../../Utils/Room/RoomMove';
import { useOpeningGestureController } from './OpeningGestureController';
import { YesNoChoiceDialog } from '../../../../../Web/Pages/Projects/Components/ChoiceDialog/YesNoChoiceDialog';

type PlacingOpeningProps = { item: IOpening; readonly?: boolean };
export const PlacingOpening = ({ item, readonly }: PlacingOpeningProps) => {
    //! hinge?: string; //! ALWAYS 'normal'
    //const hingeStatus = item.hinge || 'hinge'; //* normal - reverse
    const hingeStatus = 'normal';
    const hingeUpdate = hingeStatus === 'normal' ? 1 : -1;
    const containerPosition = `translate(${item.x || 0},${item.y || 0}) scale(${hingeUpdate}, 1)`;
    const transform = `rotate(${item.angle},0,0)`;
    const type = item.type;
    const size = item.size!;

    const selected = useMapContextState().selectedOpeningId === item.openingId;

    const { onMove, onDuplicate, onModify, onDelete, menuContext, deleteDialog, gestures } =
        useOpeningGestureController(item);
    const gesturesToUse = readonly ? {} : gestures;
    const isMobile = useIsMobile();
    const classes = useStyles({ isMoving: item.isMoving, mode: MapConstants.mode, selected, readonly, isMobile });
    const readonlyStyle = `
        .CarpentryMoving { display: none }
        .inline { stroke: ${CarpentryFactory.OpeningDefaultColor} }
        .outline { stroke: ${CarpentryFactory.OpeningStrokeColor} }
        .incircle { display: none }
        .outcircle { display: inline; fill: ${CarpentryFactory.OpeningDefaultColor}; stroke: ${CarpentryFactory.OpeningStrokeColor} }
        .picto { fill: ${CarpentryFactory.OpeningStrokeColor} }
        .CarpentryMoving { display: none }
   `;
    return (
        <GroupMovable id={RoomMove.openingRoomId(item.roomId!)} className={RoomMove.class(item.roomId!)}>
            <Group transform={containerPosition} {...gesturesToUse} ref={menuContext.menuAnchorEl}>
                <style type="text/css">{readonly && readonlyStyle}</style>
                <g className={classes.root}>
                    {type === OpeningType.Door && <CarpentryFactory.Door size={size} transform={transform} />}
                    {type === OpeningType.ArmoredDoor && (
                        <CarpentryFactory.ArmoredDoor size={size} transform={transform} />
                    )}
                    {type === OpeningType.FrenchDoor && (
                        <CarpentryFactory.FrenchDoor size={size} transform={transform} />
                    )}
                    {type === OpeningType.FrontDoor && <CarpentryFactory.FrontDoor size={size} transform={transform} />}
                    {type === OpeningType.PatioDoor && <CarpentryFactory.PatioDoor size={size} transform={transform} />}
                    {type === OpeningType.Window && <CarpentryFactory.Window size={size} transform={transform} />}
                    {type === OpeningType.Opening && <CarpentryFactory.Opening size={size} transform={transform} />}
                    {!readonly && <CarpentryFactory.Moving size={size} transform={transform} />}
                </g>
                <menuContext.PopupMenu>
                    <ContextMenuItem.Modify onClick={onModify} />
                    <ContextMenuItem.Divider />
                    <ContextMenuItem.Move onClick={onMove} />
                    <ContextMenuItem.Divider />
                    <ContextMenuItem.Duplicate onClick={onDuplicate} />
                    <ContextMenuItem.Divider />
                    <ContextMenuItem.Delete onClick={onDelete} />
                </menuContext.PopupMenu>
            </Group>
            {deleteDialog.showDelete && (
                <YesNoChoiceDialog
                    title="êtes -vous sûr ?"
                    subtitle={`Supprimer l'ouverture "${item.name}"`}
                    onClickYes={deleteDialog.onDelete}
                    onClickNo={() => deleteDialog.close('FROM_onClickNo')}
                    onClose={() => deleteDialog.close('FROM_onClose')}
                    enableCloseOutside={false}
                    noHighlight
                />
            )}
        </GroupMovable>
    );
};
const readonlyStyle = `
        .CarpentryMoving { display: none }
        .inline { stroke: ${CarpentryFactory.OpeningDefaultColor} }
        .outline { stroke: ${CarpentryFactory.OpeningStrokeColor} }
        .incircle { display: none }
        .outcircle { display: inline; fill: ${CarpentryFactory.OpeningDefaultColor}; stroke: ${CarpentryFactory.OpeningStrokeColor} }
        .picto { fill: ${CarpentryFactory.OpeningStrokeColor} }
        .CarpentryMoving { display: none }
   `;
const GroupMovable = styled('g')({});
const Group = styled('g')({ cursor: 'pointer' });

type IStyleProps = { isMoving?: boolean; mode?: string; selected?: boolean; readonly?: boolean; isMobile?: boolean };
const useStyles = makeStyles<Theme, IStyleProps>({
    root: (props: IStyleProps) => ({
        cursor: props.readonly ? 'default' : 'move',
        '& .CarpentryMoving': { display: props.isMoving ? 'inline' : 'none' },
        '& .inline':
            props.selected || props.isMoving
                ? { stroke: CarpentryFactory.OpeningStrokeColor }
                : { stroke: CarpentryFactory.OpeningDefaultColor },
        '& .outline': props.isMoving
            ? { stroke: CarpentryFactory.OpeningStrokeColor }
            : props.selected
            ? { stroke: 'white' }
            : { stroke: CarpentryFactory.OpeningStrokeColor },
        '& .incircle': props.isMoving ? { display: 'inline' } : { display: 'none' },
        '& .outcircle': props.isMoving
            ? { display: 'none' }
            : {
                  display: 'inline',
                  fill: props.selected ? '#0F0E2D' : CarpentryFactory.OpeningDefaultColor,
                  stroke: props.selected ? 'white' : CarpentryFactory.OpeningStrokeColor,
              },
        '& .picto': { fill: props.selected ? 'white' : CarpentryFactory.OpeningStrokeColor },
        '&:hover':
            props.mode !== MapMode.MODE_TYPE_OPENING_PLACING && !props.readonly && !props.isMobile
                ? {
                      '& .CarpentryMoving': { display: 'inline' },
                      '& .inline': { stroke: CarpentryFactory.OpeningStrokeColor },
                      '& .incircle': { display: 'inline' },
                      '& .outcircle': { display: 'none' },
                  }
                : {},
    }),
});
