import { ILaizeOpening, ILaizeWall } from '../../Components/Laizes/LaizeCalc';
import { MapConstants } from '../../MapConstants';
import { IOpening } from '../../Models/IOpening';
import { IRoom } from '../../Models/IRoom';
import { IRoomItem } from '../../Models/IRoomItem';
import { Editor } from '../Editor';
import { PointUtils } from '../PointUtils';
import { qSVG } from '../qSVG';
import { AdjacentRooms, BestVertice, CoordPoint, Wall, WallLine, WallSelected } from '../Types';

export class WallUtils {
    public static wallSize = (wall: WallLine) => {
        return (qSVG.measure(wall.start, wall.end) * 100) / MapConstants.meter;
    };

    public static createWall(pointA: CoordPoint, pointB: CoordPoint, room: IRoom, sideName: string) {
        var wall = Editor.wall(
            pointA,
            pointB,
            'normal',
            MapConstants.wallMinSize(room.type),
            room.shape,
            room.roomId,
            sideName
        );
        return wall;
    }

    public static refreshWallsMinimumSizeAndOriginalAngle(
        walls: Array<Wall>,
        currentOpenings: Array<IOpening>,
        rooms: Array<IRoom>
    ) {
        for (const wall of walls) {
            let totalObjSizes = 0;
            for (const obj of Editor.objFromWallRoom(wall, currentOpenings)) {
                totalObjSizes += obj.size!;
            }
            const room = rooms.find((x) => x.roomId === wall.roomId);
            const wallMinSize = MapConstants.wallMinSize(room?.type);
            wall.minimumSize = totalObjSizes < wallMinSize ? wallMinSize : totalObjSizes;
            wall.originalAngle = wall.angle;
        }
    }

    public static getWallsAdjacentList = (
        walls: Array<Wall>,
        allWalls: Array<Wall>,
        roomItems: Array<IRoomItem>
    ): Array<Array<Wall>> => {
        //returns only list of wall
        let adjacentWalls = [];
        for (const wallA of walls) {
            for (const wallB of allWalls) {
                if (
                    wallA !== wallB &&
                    wallA.roomId !== wallB.roomId &&
                    this.testIfWallAdjacent(wallA, wallB, allWalls, roomItems)
                ) {
                    adjacentWalls.push([wallA, wallB]);
                }
            }
        }
        return adjacentWalls;
    };

    public static testIfWallAdjacent(wallA: Wall, wallB: Wall, walls: Array<Wall>, roomItems: Array<IRoomItem>) {
        const tolerance_round_value = MapConstants.tolerance_round_value;
        if (wallA.angle === wallB.angle || Math.abs(Math.abs(wallA.angle) - Math.PI) === Math.abs(wallB.angle)) {
            if (
                (qSVG.btwn(wallB.start.x, wallA.start.x, wallA.end.x, false, tolerance_round_value) &&
                    qSVG.btwn(wallB.start.y, wallA.start.y, wallA.end.y, false, tolerance_round_value)) ||
                (qSVG.btwn(wallB.end.x, wallA.start.x, wallA.end.x, false, tolerance_round_value) &&
                    qSVG.btwn(wallB.end.y, wallA.start.y, wallA.end.y, false, tolerance_round_value)) ||
                (qSVG.btwn(wallA.start.x, wallB.start.x, wallB.end.x, false, tolerance_round_value) &&
                    qSVG.btwn(wallA.start.y, wallB.start.y, wallB.end.y, false, tolerance_round_value)) ||
                (qSVG.btwn(wallA.end.x, wallB.start.x, wallB.end.x, false, tolerance_round_value) &&
                    qSVG.btwn(wallA.end.y, wallB.start.y, wallB.end.y, false, tolerance_round_value))
            ) {
                //* To avoid #22870
                const points = [wallA.start, wallA.end, wallB.start, wallB.end];
                const supperposedPoint = PointUtils.getSupperposedPoints(points);
                if (supperposedPoint != null) {
                    const bbox = PointUtils.calculateBoundingBox({ points });
                    if (
                        bbox &&
                        (qSVG.strictlyBetween(supperposedPoint.x, bbox.xMin, bbox.xMax) ||
                            qSVG.strictlyBetween(supperposedPoint.y, bbox.yMin, bbox.yMax))
                    ) {
                        return false;
                    }
                }
                return true;
            }
        }
        return false;
    }

    public static calculatePerimeter(walls: Array<Wall> = []) {
        let value = 0;
        for (const wall of walls) {
            value += qSVG.measure(wall.start, wall.end);
        }
        return value;
    }

    public static determineAdjacentRooms(
        walls: Array<ILaizeWall> = [],
        openings: Array<ILaizeOpening>,
        roomIds: Array<string>
    ): Array<AdjacentRooms> {
        let adjacentRooms: Array<AdjacentRooms> = [];
        const wallsObjs = walls.map((wall) => Editor.objFromWall(wall, openings));
        for (const opening of openings) {
            let appearance: Array<number> = [];
            for (const wallObjs of wallsObjs) {
                //! comment ça peut marcher
                //if (wallObjs.find((x) => x.openingId === opening.openingId)) {
                if (wallObjs.find((x) => x.openingId === opening.openingId)) {
                    appearance.push(wallsObjs.indexOf(wallObjs));
                }
            }
            if (appearance.length > 1) {
                adjacentRooms.push({
                    opening: opening,
                    roomIds: appearance.map((x) => walls[x].roomId!).filter((x) => roomIds.includes(x)),
                    openingAngle: opening.angle!,
                });
            }
        }
        return adjacentRooms;
    }

    public static isWallHorV(wall: WallLine) {
        return this.wallDirection(wall) === 'h' || this.wallDirection(wall) === 'v';
    }

    public static wallDirection(wall: WallLine) {
        const wallAngle = qSVG.angleRadToDeg(wall.angle!);
        return this.angleDirection(wallAngle);
    }

    //* when door mode placement activated
    public static nearWallOpening = (
        walls: Array<Wall>,
        rooms: Array<IRoom>,
        snap: CoordPoint,
        range: number = Infinity,
        fromRoomId?: string
    ) => {
        const wallsToWorkWith = fromRoomId ? walls.filter((x) => x.roomId === fromRoomId) : walls;
        return this.nearWallFromOpening(rooms, snap, range, wallsToWorkWith);
    };

    public static nearWallFromOpening = (
        rooms: Array<IRoom>,
        snap: CoordPoint,
        range = Infinity,
        wallsToWorkWith: Array<Wall>
    ): WallSelected | undefined => {
        let wallDistance = Infinity;
        let wallSelected: WallSelected | undefined = undefined;
        let result;
        if (wallsToWorkWith.length === 0) {
            return undefined;
        }

        //filter walls if snap is inside a room
        const snapedRoom = Editor.rayCastingRoom(snap, rooms);
        if (snapedRoom) {
            wallsToWorkWith = wallsToWorkWith.filter((x) => x.roomId === snapedRoom.roomId);
        }

        for (let e = 0; e < wallsToWorkWith.length; e++) {
            const eq = qSVG.createEquation(
                wallsToWorkWith[e].start.x,
                wallsToWorkWith[e].start.y,
                wallsToWorkWith[e].end.x,
                wallsToWorkWith[e].end.y
            );
            result = qSVG.nearPointOnEquation(eq, snap);
            if (
                result.distance < wallDistance &&
                qSVG.btwn(result.x, wallsToWorkWith[e].start.x, wallsToWorkWith[e].end.x) &&
                qSVG.btwn(result.y, wallsToWorkWith[e].start.y, wallsToWorkWith[e].end.y)
            ) {
                wallDistance = result.distance;
                wallSelected = {
                    wall: wallsToWorkWith[e],
                    roomId: wallsToWorkWith[e].roomId,
                    x: result.x,
                    y: result.y,
                    distance: result.distance,
                };
            }
        }

        if (wallDistance <= range) {
            return wallSelected;
        } else {
            return undefined;
        }
    };

    //* when click after door mode placement activated
    public static nearWall = (walls: Array<Wall>, snap: CoordPoint, range: number = Infinity, fromRoomId?: string) => {
        const wallsToWorkWith = fromRoomId ? walls.filter((x) => x.roomId === fromRoomId) : walls;
        return this.nearWallFrom(snap, range, wallsToWorkWith);
    };

    /**
     * Finds the nearest wall.
     * Param prevWall can be used to prioritize this wall when deciding which wall is closer.
     */
    public static nearWallFrom = (
        snap: CoordPoint,
        range: number = Infinity,
        walls: Array<WallLine>,
        prevWall?: WallLine,
        excludeDiagWalls?: boolean
    ): WallSelected | undefined => {
        let wallsToWorkWith = walls;
        let wallDistance = Infinity;
        let wallSelected = undefined;
        let prevWallDistance = Infinity;
        let prevWallSelected = undefined;
        let result;
        let foundWall = false;
        if (wallsToWorkWith.length === 0) {
            return undefined;
        }

        if (excludeDiagWalls) {
            wallsToWorkWith = wallsToWorkWith.filter((x) => this.isWallHorV(x));
        }
        for (let e = 0; e < wallsToWorkWith.length; e++) {
            const eq = qSVG.createEquation(
                wallsToWorkWith[e].start.x,
                wallsToWorkWith[e].start.y,
                wallsToWorkWith[e].end.x,
                wallsToWorkWith[e].end.y,
            );
            result = qSVG.nearPointOnEquation(eq, snap);
            if (
                result.distance < wallDistance &&
                qSVG.btwn(result.x, wallsToWorkWith[e].start.x, wallsToWorkWith[e].end.x) &&
                qSVG.btwn(result.y, wallsToWorkWith[e].start.y, wallsToWorkWith[e].end.y)
            ) {
                foundWall = true;
                wallDistance = result.distance;
                wallSelected = {
                    wall: wallsToWorkWith[e],
                    roomId: wallsToWorkWith[e].roomId,
                    x: result.x,
                    y: result.y,
                    distance: result.distance,
                };
            } else if (prevWall && wallsToWorkWith[e] === prevWall) {
                prevWallDistance = result.distance;
                prevWallSelected = {
                    wall: wallsToWorkWith[e],
                    roomId: wallsToWorkWith[e].roomId,
                    x: result.x,
                    y: result.y,
                    distance: result.distance,
                };
            }
        }

        if (prevWall && (!foundWall || prevWallDistance + 100 < wallDistance)) {
            wallDistance = prevWallDistance;
            wallSelected = prevWallSelected;
        }
        const vv = this.nearVertice(snap, 10000, wallsToWorkWith);

        if (vv && ((prevWall && vv.distance + 50 < wallDistance) || (!prevWall && vv.distance < wallDistance))) {
            wallDistance = vv.distance;
            wallSelected = {
                wall: vv.number,
                roomId: vv.roomId,
                x: vv.x,
                y: vv.y,
                distance: vv.distance,
            };
        }
        return wallDistance <= range ? wallSelected : undefined;
    };

    public static nearVertice = (
        snap: CoordPoint,
        range: number = 10000,
        walls: Array<WallLine>
    ): BestVertice | undefined => {
        let bestDistance = Infinity;
        let bestVertice: BestVertice | undefined;
        for (let i = 0; i < walls.length; i++) {
            const wall = walls[i];

            const distance1 = qSVG.gap(snap, { x: wall.start.x, y: wall.start.y });
            const distance2 = qSVG.gap(snap, { x: wall.end.x, y: wall.end.y });

            if (distance1 < distance2 && distance1 < bestDistance) {
                bestDistance = distance1;
                bestVertice = {
                    number: wall,
                    x: wall.start.x,
                    y: wall.start.y,
                    roomId: wall.roomId!,
                    distance: Math.sqrt(bestDistance),
                };
            }
            if (distance2 < distance1 && distance2 < bestDistance) {
                bestDistance = distance2;
                bestVertice = {
                    number: wall,
                    x: wall.end.x,
                    y: wall.end.y,
                    roomId: wall.roomId!,
                    distance: Math.sqrt(bestDistance),
                };
            }
        }
        return bestDistance < range * range ? bestVertice : undefined;
    };

    public static angleDirection(angle: number) {
        if (angle === 0 || angle === 180) {
            return 'h';
        } else if (angle === 90 || angle === -90) {
            return 'v';
        } else if (angle === 45 || angle === -45 || angle === 135 || angle === -135) {
            return 'd';
        }
    }
}
