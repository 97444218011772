import _ from 'lodash';

import { ILaizeRoom } from '../Components/Laizes/LaizeCalc';
import { MapConstants } from '../MapConstants';
import { IRoomItem } from '../Models/IRoomItem';
import { Editor } from './Editor';
import { JstsPolygon, JstsUtils } from './JstsUtils';
import { qSVG } from './qSVG';
import { BoundingBox, CoordPoint, Equation, LineSegment, RotationParams, Wall, WallLine, WallWithCorrection } from './Types';
import { WallUtils } from './Wall/WallUtils';

type BoundingBoxParams = {
    walls?: Array<Wall>;
    points?: Array<CoordPoint>;
    roomItems?: Array<IRoomItem>;
    includeBands?: Array<CoordPoint>;
};

const reducer = (accumulator: number, currentValue: number) => accumulator + currentValue;

export class PointUtils {
    public static toPosition = (coord: CoordPoint): CoordPoint | undefined => {
        if (coord.x && coord.y) {
            return { x: coord.x!, y: coord.y! };
        }
        return undefined;
    };

    public static translationByMeanPoint(points: Array<CoordPoint>, destinationPoint: CoordPoint, opposite?: boolean) {
        const mp = this.meanPoint(points);

        points.forEach((point) => {
            const newPoints = !opposite
                ? {
                    x: point.x + destinationPoint.x - mp.x,
                    y: point.y + destinationPoint.y - mp.y,
                }
                : {
                    x: point.x - destinationPoint.x + mp.x,
                    y: point.y - destinationPoint.y + mp.y,
                };
            point.x = newPoints.x;
            point.y = newPoints.y;
        });
    }

    public static meanPoint(points: Array<CoordPoint>) {
        return {
            x: Array.from(points, (p) => p.x).reduce(reducer) / points.length,
            y: Array.from(points, (p) => p.y).reduce(reducer) / points.length,
        };
    }

    public static convertToCoordinates(point: CoordPoint, roundPoints?: boolean) {
        const meter = MapConstants.meter;
        if (roundPoints) {
            point.x = Math.round(point.x * meter);
            point.y = Math.round(point.y * meter);
        } else {
            point.x *= meter;
            point.y *= meter;
        }
        return point;
    }

    public static placeToZero(points: Array<CoordPoint>) {
        const topLeftCorner = {
            x: Math.abs(Math.min(...points.map((point) => point.x))),
            y: Math.abs(Math.min(...points.map((point) => point.y))),
        };

        if (topLeftCorner.x !== 0 || topLeftCorner.y !== 0) {
            points.forEach((point) => {
                const newPoint = this.translation(point, topLeftCorner);
                point.x = newPoint.x;
                point.y = newPoint.y;
            });
        }
    }

    public static translation(point: CoordPoint, vector: CoordPoint, opposite?: boolean) {
        const newPoints = !opposite
            ? {
                x: point.x + vector.x,
                y: point.y + vector.y,
            }
            : {
                x: point.x - vector.x,
                y: point.y - vector.y,
            };
        return newPoints;
    }

    public static placeAlongSideExistingRooms(points: Array<CoordPoint>, walls: Array<Wall>) {
        const meter = MapConstants.meter;
        const boundingBox = this.calculateBoundingBox({ walls });

        const topRightBoundingBox = boundingBox
            ? { x: boundingBox.xMax, y: boundingBox.yMin }
            : { x: 40 * meter, y: 80 * meter };

        topRightBoundingBox.x += MapConstants.roomDefaultSpacing;

        points.forEach((point) => {
            const newPoint = this.translation(point, topRightBoundingBox);
            point.x = newPoint.x;
            point.y = newPoint.y;
        });
    }

    // /**
    //  * Determines maximums and minimums on the x and y axis for a series of points.
    //  * @param {*} points If left undefined, uses WALLS starting points.
    //  * @returns BoundingBox: {xMin: number, xMax: number, yMin: number, yMax: number}
    //  */
    // public static calculateBoundingBox(walls: Array<Wall>, roomItems: Array<IRoomItem> = [], points?: Array<CoordPoint>, includeRoomItems = false): BoundingBox | undefined {
    //     let allPoints: Array<CoordPoint> = [];

    //     if (!points) {
    //         walls.forEach((wall) => allPoints.push(wall.start));

    //         if (includeRoomItems) {
    //             roomItems.forEach((roomItem: IRoomItem) => {
    //                 if (roomItem.isMoveableOutside) {
    //                     roomItem.coordsReal?.forEach((coord) => allPoints.push(coord));
    //                 }
    //             });
    //         }
    //     } else {
    //         allPoints = points;
    //     }

    //     const xSeries = allPoints.map((point) => point.x);
    //     const ySeries = allPoints.map((point) => point.y);

    //     return xSeries.length > 0 && ySeries.length > 0
    //         ? {
    //             xMin: Math.min(...xSeries),
    //             xMax: Math.max(...xSeries),
    //             yMin: Math.min(...ySeries),
    //             yMax: Math.max(...ySeries),
    //         }
    //         : undefined;
    // }

    /**
     * Determines maximums and minimums on the x and y axis for a series of points.
     * @param {*} points If left undefined, uses WALLS starting points.
     * @returns {} {xMin: number, xMax: number, yMin: number, yMax: number}
     */
    public static calculateBoundingBox({
        points,
        walls = [],
        roomItems,
        includeBands,
    }: BoundingBoxParams): BoundingBox | undefined {
        let allPoints = [];
        if (!points) {
            walls.forEach((wall) => allPoints.push(wall.start));
            if (roomItems) {
                roomItems.forEach((roomItem) => {
                    if (roomItem.isMoveableOutside) {
                        roomItem.coordsReal?.forEach((coord) => allPoints.push(coord));
                    }
                });
            }
            if (includeBands) {
                allPoints.push(...includeBands);
            }
        } else {
            allPoints = points;
        }

        const xSeries = allPoints.map((point) => point.x);
        const ySeries = allPoints.map((point) => point.y);

        return xSeries.length > 0 && ySeries.length > 0
            ? {
                xMin: Math.min(...xSeries),
                xMax: Math.max(...xSeries),
                yMin: Math.min(...ySeries),
                yMax: Math.max(...ySeries),
            }
            : undefined;
    }

    public static smoothOutPoints(points: Array<CoordPoint>) {
        for (const point of points) {
            this.smoothOutPoint(point);
        }
    }

    public static smoothOutPoint(point: CoordPoint) {
        point.x = _.round(point.x, 1);
        point.y = _.round(point.y, 1);
    }

    public static degreeToRad(degrees: number) {
        return degrees * (Math.PI / 180);
    }

    public static distanceBetweenPoints(pointA: CoordPoint, pointB: CoordPoint) {
        return Math.sqrt(Math.pow(pointB.x - pointA.x, 2) + Math.pow(pointB.y - pointA.y, 2));
    }

    public static inflatePolygon(poly: Array<CoordPoint> = [], spacing: number) {
        const geoInput = JstsUtils.vectorCoordinates2JTS(poly);
        geoInput.push(geoInput[0]);
        const polygon = JstsUtils.buffer(geoInput, spacing);
        let inflatedCoordinates: Array<CoordPoint> = [];
        const coordinates = polygon.getCoordinates();

        for (let i = 0; i < coordinates.length; i++) {
            const point = coordinates[i];
            inflatedCoordinates.push({ x: point.x, y: point.y });
        }
        return inflatedCoordinates;
    }

    public static limitObj = (
        equation: Equation,
        size: number,
        coords: CoordPoint,
        message?: string
    ): Array<CoordPoint> => {
        //equation à fix lorsqu'une ouverture est sur un angle
        if (message) console.log(message);
        const Px = coords.x;
        const Py = coords.y;
        const Aq = equation.A;
        const Bq = equation.B;

        let pos1;
        let pos2;
        if (Aq === 'v') {
            pos1 = { x: Px, y: Py - size / 2 };
            pos2 = { x: Px, y: Py + size / 2 };
        } else if (Aq === 'h') {
            pos1 = { x: Px - size / 2, y: Py };
            pos2 = { x: Px + size / 2, y: Py };
        } else {
            const A = 1 + Aq * Aq;
            const B = -2 * Px + 2 * Aq * Bq + -2 * Py * Aq;
            const C = Px * Px + Bq * Bq - 2 * Py * Bq + Py * Py - (size * size) / 4; // -N
            const Delta = B * B - 4 * A * C;
            const posX1 = (-B - Math.sqrt(Delta)) / (2 * A);
            const posX2 = (-B + Math.sqrt(Delta)) / (2 * A);
            pos1 = { x: posX1, y: Aq * posX1 + Bq };
            pos2 = { x: posX2, y: Aq * posX2 + Bq };
        }
        return [pos1, pos2];
    };

    public static getSupperposedPoints(points: Array<CoordPoint>): CoordPoint | undefined {
        for (let i = 0; i < points.length; i++) {
            for (let j = 0; j < points.length; j++) {
                if (i !== j && points[i].x === points[j].x && points[i].y === points[j].y) {
                    return points[i];
                }
            }
        }
        return undefined;
    }

    public static rotation(points: Array<CoordPoint>, degrees: number, rotationCenter: CoordPoint = { x: 0, y: 0 }) {
        const rad = this.degreeToRad(degrees);
        points.forEach((point) => {
            const tPoint = {
                x: point.x - rotationCenter.x,
                y: point.y - rotationCenter.y,
            };

            const newPoints = {
                x: tPoint.x * Math.cos(rad) - tPoint.y * Math.sin(rad) + rotationCenter.x,
                y: tPoint.x * Math.sin(rad) + tPoint.y * Math.cos(rad) + rotationCenter.y,
            };
            point.x = newPoints.x;
            point.y = newPoints.y;
        });
    }

    public static intersect(l1: LineSegment, l2: LineSegment) {
        // Check if none of the lines are of length 0
        if ((l1.p1.x === l1.p2.x && l1.p1.y === l1.p2.y) || (l2.p1.x === l2.p2.x && l2.p1.y === l2.p2.y)) {
            return false;
        }

        const denominator = (l2.p2.y - l2.p1.y) * (l1.p2.x - l1.p1.x) - (l2.p2.x - l2.p1.x) * (l1.p2.y - l1.p1.y);

        // Lines are parallel
        if (denominator === 0) {
            return false;
        }

        let ua = ((l2.p2.x - l2.p1.x) * (l1.p1.y - l2.p1.y) - (l2.p2.y - l2.p1.y) * (l1.p1.x - l2.p1.x)) / denominator;
        let ub = ((l1.p2.x - l1.p1.x) * (l1.p1.y - l2.p1.y) - (l1.p2.y - l1.p1.y) * (l1.p1.x - l2.p1.x)) / denominator;

        // is the intersection along the segments
        if (ua < 0 || ua > 1 || ub < 0 || ub > 1) {
            return false;
        }

        // Return a object with the x and y coordinates of the intersection
        let x = l1.p1.x + ua * (l1.p2.x - l1.p1.x);
        let y = l1.p1.y + ua * (l1.p2.y - l1.p1.y);

        return { x, y };
    }

    public static inflatePolygonHV(
        poly: Array<CoordPoint>,
        horizontalSpacing: number,
        verticalSpacing: number,
        wallsWithCorrection?: Array<WallWithCorrection>,
        color?: string
    ) {
        // var offset = new Offset();
        // var horizontalPolygon = offset.data(poly.map(p => [p.x, p.y])).padding(horizontalSpacing)[0].map(p => ({x: p[0], y: p[1]})).reverse();
        // var verticalPolygon = offset.data(poly.map(p => [p.x, p.y])).padding(verticalSpacing)[0].map(p => ({x: p[0], y: p[1]})).reverse();

        // Avoids a bug when spacing = 0
        if (verticalSpacing === 0) {
            verticalSpacing += 0.0001;
        }
        if (horizontalSpacing === 0) {
            horizontalSpacing += 0.0001;
        }

        const horizontalPolygon = this.inflatePolygon(poly, horizontalSpacing).reverse();
        const verticalPolygon = this.inflatePolygon(poly, verticalSpacing).reverse();

        let mergedPolygon = [];
        for (let i = 0; i < horizontalPolygon.length; i++) {
            const v = Math.abs(verticalSpacing); //1
            const vDiff = Math.abs(poly[i].y - verticalPolygon[i].y); //0.5
            if (vDiff < v) {
                if (poly[i].y < verticalPolygon[i].y) {
                    verticalPolygon[i].y += v - vDiff;
                } else {
                    verticalPolygon[i].y -= v - vDiff;
                }
            }

            const h = Math.abs(horizontalSpacing); //1
            const hDiff = Math.abs(Math.abs(poly[i].x) - Math.abs(horizontalPolygon[i].x)); //0.5
            if (hDiff < h) {
                if (poly[i].x < horizontalPolygon[i].x) {
                    horizontalPolygon[i].x += h - hDiff;
                } else {
                    horizontalPolygon[i].x -= h - hDiff;
                }
            }

            if (wallsWithCorrection && wallsWithCorrection.length) {
                for (const wallWithCorrection of wallsWithCorrection) {
                    if (Editor.testPointBelongToWall(wallWithCorrection.wall, poly[i])) {
                        const direction = WallUtils.wallDirection(wallWithCorrection.wall);
                        let correction = wallWithCorrection.correction;
                        if (direction === 'h') {
                            if (verticalSpacing < 0) {
                                correction = -correction;
                            }
                            if (poly[i].y < verticalPolygon[i].y) {
                                // BOTTOM walls
                                verticalPolygon[i].y += correction;
                            } else {
                                // TOP walls
                                verticalPolygon[i].y -= correction;
                            }
                        } else if (direction === 'v') {
                            if (horizontalSpacing < 0) {
                                correction = -correction;
                            }
                            if (poly[i].x < horizontalPolygon[i].x) {
                                // RIGHT walls
                                horizontalPolygon[i].x += correction;
                            } else {
                                // LEFT walls
                                horizontalPolygon[i].x -= correction;
                            }
                        }
                    }
                }
            }

            mergedPolygon.push({ x: horizontalPolygon[i].x, y: verticalPolygon[i].y });
        }

        return mergedPolygon;
    }

    /**
     * Returns true if the two polygons intersect. If the polygons are next to each other
     * and their only intersection is a line, returns false.
     */
    public static testPolygonsIntersect = (polyA: Array<CoordPoint> = [], polyB: Array<CoordPoint> = []) => {
        const intersection = this.calcIntersection(polyA, polyB);
        return intersection?.getCoordinates()?.length > 2;
    };

    /** Returns the jsts intersection object of the two polygons */
    public static calcIntersection = (polyA: Array<CoordPoint>, polyB: Array<CoordPoint>) => {
        const geometryFactory = JstsUtils.GeometryFactory();

        const geoInputPolyA = JstsUtils.vectorCoordinates2JTS(polyA);
        geoInputPolyA.push(geoInputPolyA[0]);
        const jstsPolyA = geometryFactory.createPolygon(geoInputPolyA);

        const geoInputPolyB = JstsUtils.vectorCoordinates2JTS(polyB);
        geoInputPolyB.push(geoInputPolyB[0]);
        const jstsPolyB = geometryFactory.createPolygon(geoInputPolyB);

        return jstsPolyA.intersection(jstsPolyB);
    };

    /** Returns true if the point is within the room (including on the room's walls).   */
    public static testPointWithinRoom = (point: CoordPoint, room: ILaizeRoom) => {
        const polygon = [room.coords!.map((point) => [point.x, point.y])];
        const isWithin = JstsUtils.within(point, polygon);
        let isOnWall = false;
        room.walls?.every((wall) => {
            if (Editor.testPointBelongToWall(wall, point)) {
                isOnWall = true;
                return false;
            }
            return true;
        });
        return isWithin || isOnWall;
    };

    public static getClosestPointIndex(point: CoordPoint, list: Array<CoordPoint>) {
        let distance = Infinity;
        let closestPoint: CoordPoint;
        for (const lp of list) {
            const measure = qSVG.measure(lp, point);
            if (measure < distance) {
                distance = measure;
                closestPoint = lp;
            }
            distance = measure < distance ? measure : distance;
        }
        return { closestPointDistance: distance, closestPointIndex: list.indexOf(closestPoint!) }; //list.sort((a, b) => Math.abs(number - a) - Math.abs(number - b))[0];
    }

    public static bboxCenter(points: Array<CoordPoint>) {
        const bbox = this.calculateBoundingBox({ points })!;
        return { x: (bbox.xMin + bbox.xMax) / 2, y: (bbox.yMin + bbox.yMax) / 2 };
    }

    /**
     * Gets the coordinates of a polygon that has multiple geometries.
     * (Happens when the intersection between two polygons results in more than one polygon).
     * @param {*} multiPoly
     * @returns
     */
    public static getMultiPolyCoords(multiPoly: JstsPolygon) {
        const multiPolyCoords: Array<CoordPoint> = [];
        for (let i = 0; i < multiPoly.getNumGeometries(); i++) {
            const subIntersection = multiPoly.getGeometryN(i);
            const subIntersectionCoords = subIntersection.getCoordinates();
            for (let j = 0; j < subIntersectionCoords.length; j++) {
                multiPolyCoords.push({
                    x: subIntersectionCoords[j].x,
                    y: subIntersectionCoords[j].y,
                });
            }
        }
        return multiPolyCoords;
    }

    public static testPointWithinPolygonWithoutWalls(point: CoordPoint, polygon: JstsPolygon, geometryFactory: any) {
        return polygon.contains(geometryFactory.createPoint(point));
    }

    public static testPointWithinPolygon(point: CoordPoint, polygon: JstsPolygon, geometryFactory?: any) {
        if (!geometryFactory) {
            geometryFactory = JstsUtils.GeometryFactory();
        }

        const isContained = PointUtils.testPointWithinPolygonWithoutWalls(point, polygon, geometryFactory);

        const polyWalls = this.toWalls(polygon.getCoordinates());
        let isOnWall = false;
        polyWalls.forEach((wall) => {
            if (Editor.testPointBelongToWall(wall, point)) {
                isOnWall = true;
            }
        });
        return isContained || isOnWall;
    }

    public static toWalls(coords: Array<CoordPoint>) {
        let walls: Array<WallLine> = [];
        for (let i = 0; i < coords.length - 1; i++) {
            walls.push({ start: coords[i], end: coords[(i + 1) % coords.length] });
        }
        return walls;
    }

    public static isLineVertical(line: Array<CoordPoint>) {
        const angle = Math.atan2(line[1].y - line[0].y, line[1].x - line[0].x);
        const angleDeg = qSVG.angleRadToDeg(angle);
        return angleDeg === 90 || angleDeg === -90;
    }

    public static rotateCyclicCoords(coords: Array<CoordPoint>, rotationParams: RotationParams) {
        // Room coords have the same first and last coordinates, but
        // rotation of cyclic coordinates doesn't work well. So we pop
        // the last coord and add it back at the end.
        coords.pop();
        this.rotation(coords, rotationParams.angle, rotationParams.center);
        coords.push(coords[0]);
    }

    // equivalent of excel mround function
    public static mround(number: number, multiple: number) {
        return multiple * Math.round(number / multiple);
    }
}
