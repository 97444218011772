import { Theme } from '@material-ui/core';
import { makeStyles, styled } from '@material-ui/styles';
import React, { CSSProperties } from 'react';
import { FormattedMessage } from 'react-intl';
import { ProductHelper } from '../../../../../../../Helpers/ProductHelper';
import { I18n } from '../../../../../../../Locales/I18nService';
import { FlooringDirection, ProductType } from '../../../../../../../Plugins/FloorPlan/Components/Laizes/Laize';
import { useMapContext } from '../../../../../../../Plugins/FloorPlan/Context/MapContext';
import {
    FlooringDirectionAssets,
    FlooringDirectionProps,
} from '../../../../../../assets/flooring_direction/FlooringDirectionAssets';
import { Focus } from '../../Widgets/Focus';
import { useRoomEdit } from '../Controllers/RoomEditController';
import { RoomInfoIds, useRoomInfosFocus } from '../Controllers/RoomInfosFocus';
import { Scroller, useScroller } from '../../../../Helpers/Scroller';
import { QuestionsHelper } from '../../../../../../../Helpers/QuestionsHelper';
import { QuestionType } from '../../../../../../../Models/Questions/QuestionDto';

export const RoomDirection = () => {
    const scroller = useScroller();
    const { state: map } = useMapContext();
    const { state, update, getState } = useRoomEdit();
    const { focus } = useRoomInfosFocus(state);

    const productType = ProductHelper.getProductType(state.questionProduitPrincipal?.produitValue);
    if (productType === undefined) {
        return null;
    }
    const possibleDirections = map.laizeResults?.find((x) => x.roomIds?.some((r) => r === state.roomId))
        ?.possibleFlooringDirectionByRoomId?.[state.roomId!];

    const onChange = (value: FlooringDirection) => {
        update({ values: { flooringDirection: value }, refreshLaizeCalc: true, logs: { newFlooringDirection: value } });

        setTimeout(() => {
            const questionsPose = getState().questionsPose?.questions || [];
            const nextQuestionWithNoResponse = QuestionsHelper.firstQuestionWithNoResponse(questionsPose);

            const respirationQuestion = questionsPose.find(
                (q) => q.type === QuestionType.Respiration && Boolean(q.showQuestion)
            );

            scroller.scrollToQuestion({
                scrollerId: Scroller.ID,
                itemId: getState().roomId!,
                toQuestion: respirationQuestion || nextQuestionWithNoResponse,
                questions: questionsPose,
                delay: 0,
            });
        }, Scroller.ShortDelay);
    };

    type CardProps = { value: FlooringDirection };
    const Card = ({ value }: CardProps) => {
        const selected = state.flooringDirection === value;
        const classes = useCardContainerStyles({ selected, hoverable: !Boolean(state.flooringDirection) });
        return (
            <div className={classes.root} onClick={() => onChange(value)}>
                <ImageContainer>
                    <FlooringDirectionIcon direction={value} productType={productType} selected={selected} />
                </ImageContainer>
                <CardTitle>
                    <FormattedMessage id={'RoomEdit_FlooringDirection_' + value} />
                </CardTitle>
            </div>
        );
    };

    return (
        <Field
            id={RoomInfoIds.FlooringDirection}
            focus={focus.flooringDirection}
            title={I18n.get('RoomEdit_FlooringDirection')}>
            {productType === ProductType.Rouleau ? (
                <>
                    <Card value={FlooringDirection.LaizeHorizontal} />
                    <Card value={FlooringDirection.LaizeVertical} />
                </>
            ) : (
                <>
                    {possibleDirections?.some((x) => x === FlooringDirection.LameHorizontal) && (
                        <Card value={FlooringDirection.LameHorizontal} />
                    )}
                    {possibleDirections?.some((x) => x === FlooringDirection.LameVertical) && (
                        <Card value={FlooringDirection.LameVertical} />
                    )}
                    <Card value={FlooringDirection.ClassiqueHorizontal} />
                    <Card value={FlooringDirection.ClassiqueVertical} />
                </>
            )}
        </Field>
    );
};

const ImageContainer = styled('div')({ width: 48, height: 48 });
type ICardContainerProps = { selected: boolean; hoverable: boolean };
const useCardContainerStyles = makeStyles<Theme, ICardContainerProps>({
    root: (props: ICardContainerProps) => ({
        display: 'flex',
        flexDirection: 'row',
        gap: 16,
        padding: 16,
        alignItems: 'center',
        width: '100%',
        height: 83,
        cursor: 'pointer',
        '@media (hover)': {
            '&:hover': props.hoverable
                ? {
                      border: '2px solid #13123A',
                      backgroundColor: '#FFFFFF',
                  }
                : {},
        },
        border: props.selected ? '2px solid #13123A' : '',
        backgroundColor: props.selected ? '#FFFFFF' : '#EFEFEF',
    }),
});

const CardTitle = styled('div')({
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '16px',
    color: '#000000',
});

type FieldProps = { id?: string; title?: React.ReactNode; style?: CSSProperties; focus?: boolean };
const Field: React.FC<FieldProps> = ({ id, title, style, focus, children }) => {
    return (
        <FieldContainer id={id} style={style}>
            {focus && <Focus />}
            <Title>{title}</Title>
            {children}
        </FieldContainer>
    );
};

const FieldContainer = styled('div')({
    marginTop: 24,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    gap: 8,
});
const Title = styled('div')({
    fontWeight: 700,
    fontSize: 14,
    color: '#1D1D1F',
    lineHeight: '21px',
    marginBottom: 10,
});

type FlooringDirectionIconProps = {
    direction: FlooringDirection;
    productType: ProductType;
    selected?: boolean;
    props?: FlooringDirectionProps;
};
export const FlooringDirectionIcon = React.memo(
    ({ direction, productType, selected = false, props }: FlooringDirectionIconProps) => {
        if (productType === ProductType.Rouleau) {
            if (direction === FlooringDirection.LaizeHorizontal) {
                return selected ? (
                    <FlooringDirectionAssets.LaizeHorizontalSelected />
                ) : (
                    <FlooringDirectionAssets.LaizeHorizontal props={props} />
                );
            } else if (direction === FlooringDirection.LaizeVertical) {
                return selected ? (
                    <FlooringDirectionAssets.LaizeVerticalSelected />
                ) : (
                    <FlooringDirectionAssets.LaizeVertical props={props} />
                );
            }
        }
        if (direction === FlooringDirection.LameHorizontal) {
            return selected ? (
                <FlooringDirectionAssets.LameHorizontalSelected />
            ) : (
                <FlooringDirectionAssets.LameHorizontal props={props} />
            );
        } else if (direction === FlooringDirection.LameVertical) {
            return selected ? (
                <FlooringDirectionAssets.LameVerticalSelected />
            ) : (
                <FlooringDirectionAssets.LameVertical props={props} />
            );
        } else if (direction === FlooringDirection.ClassiqueHorizontal) {
            return selected ? (
                <FlooringDirectionAssets.ClassiqueHorizontalSelected />
            ) : (
                <FlooringDirectionAssets.ClassiqueHorizontal props={props} />
            );
        }
        return selected ? (
            <FlooringDirectionAssets.ClassiqueVerticalSelected />
        ) : (
            <FlooringDirectionAssets.ClassiqueVertical props={props} />
        );
    }
);
