import { wrapError } from '../../../../Errors/PolErrorHandler';
import { ProductHelper } from '../../../../Helpers/ProductHelper';
import { QuestionContext, QuestionContextHelper } from '../../../../Helpers/QuestionContextHelper';
import {
    QuestionsHelper,
    SetValueOnQuestionParameter,
    UpdateQuestionAfterLoadProduitsParameters,
} from '../../../../Helpers/QuestionsHelper';
import { QuestionItem, QuestionType } from '../../../../Models/Questions/QuestionDto';
import { useIncompatibilityContext } from '../../../../Plugins/FloorPlan/Context/IncompatibilityContext';
import { useMapContext } from '../../../../Plugins/FloorPlan/Context/MapContext';
import { ContextQuestionList, IMapItem } from '../../../../Plugins/FloorPlan/Models/IMapItem';
import { QuoteStep } from '../../../../Types/QuoteStep';
import { useIsVendeur } from '../../../Hooks/Vendeur/useVendeur';
import { QuestionPrepare } from '../Helpers/QuestionPrepare';
import { Scroller, useScroller } from '../Helpers/Scroller';
import { useVersionId } from '../QuotePageController';
import { ContextQuestionsUtils } from '../Utils/ContextQuestionsUtils';

export interface AfterUpdateQuestionParameters<T extends IMapItem> {
    item: T;
    contextQuestions: ContextQuestionList;
    question: QuestionItem;
    scrollToNextQuestion?: boolean;

    questionsModified?: boolean;
    logs?: any;
}

export interface ShowNextQuestionIfNeededParameters<T extends IMapItem> {
    item: T;
    contextQuestions: ContextQuestionList;
    fromQuestion: QuestionItem;
    scrollToNextQuestion?: boolean;

    questionsModified?: boolean;
    logs?: any;
}

export type UpdateStepQuestionsParams = {
    contextQuestions: ContextQuestionList;
    questionsModified?: boolean;
    logs?: any;
    openNextSectionIfNeeded?: boolean;
};

type StepQuestionsParams<T extends IMapItem> = {
    step: QuoteStep;
    updateStepQuestions: (params: UpdateStepQuestionsParams) => void;
    getItemId: (item: T) => string;
    handleScroll: (params: ScrollToStepQuestionParams) => void;
};

export type ScrollToStepQuestionParams = {
    scrollToNextQuestion?: boolean;
    nextQuestionToScroll?: QuestionItem;
    contextQuestions: ContextQuestionList
}

export const useStepQuestionsController = <T extends IMapItem>({
    step,
    updateStepQuestions,
    getItemId,
    handleScroll
}: StepQuestionsParams<T>) => {

    const versionId = useVersionId();
    const scroller = useScroller();
    const incompatibility = useIncompatibilityContext();
    const isVendeur = useIsVendeur();
    const map = useMapContext();

    const getProduitPrincipal = (item: IMapItem) => {
        const { rooms = [] } = map.getState();
        const room = rooms.find((r) => r.roomId === item.roomId);
        return room?.questionProduitPrincipal?.produitValue;
    };

    //#region //* ANSWER QUESTION

    const onSetValueOnQuestion = (
        item: T,
        contextQuestions: ContextQuestionList,
        question: QuestionItem,
        valueParameters: SetValueOnQuestionParameter
    ): void => {
        wrapError(async () => {
            const questions: Array<QuestionItem> = contextQuestions.questions || [];
            const prevVisibleQuestion = QuestionsHelper.getPreviousVisibleQuestion(question, questions);
            const prevStepLastContext: QuestionContext = QuestionPrepare.getPreviousStepContext(item, step);
            question.context = QuestionContextHelper.mergeContext(
                prevVisibleQuestion?.context || prevStepLastContext,
                {}
            );

            const produitPrincipal = getProduitPrincipal(item);
            QuestionsHelper.setValueOnQuestion(question, valueParameters, produitPrincipal);
            const questionsUpdated: Array<QuestionItem> = QuestionsHelper.updateQuestions(questions, question);
            QuestionsHelper.initializeQuestions({ questions: questionsUpdated, initialContext: prevStepLastContext, produitPrincipal });
            contextQuestions.questions = questionsUpdated;

            const { checkValue = true } = valueParameters;
            if (checkValue && question.isValid) {
                const findIncompatibilityResult = QuestionsHelper.findFirstIncompatibility(questionsUpdated);
                if (findIncompatibilityResult.findIncompatibility) {
                    contextQuestions.questions = questionsUpdated;
                    contextQuestions.endQuestion = false;
                    updateStepQuestions({
                        contextQuestions,
                        logs: { event: 'onSetValueOnQuestion findIncompatibilityResult', findIncompatibilityResult },
                    });
                    const cancelCurrentQuestion = () => {
                        //* when click on close button or close dialog
                        //* mettre la question en non répondue
                        QuestionsHelper.invalidValueOnQuestion({ question });

                        const updatedQuestions = QuestionsHelper.updateQuestions(questions, question);
                        contextQuestions.questions = updatedQuestions;
                        contextQuestions.endQuestion = false;
                        updateStepQuestions({
                            contextQuestions,
                            questionsModified: true,
                            logs: {
                                event: 'onSetValueOnQuestion cancelCurrentQuestion',
                                findIncompatibilityResult,
                            },
                        });
                        if (question.isValid) {
                            afterUpdateQuestion({ item, contextQuestions, question });
                        }
                    };
                    incompatibility.showIncompatibilityDialog(item.roomId!, findIncompatibilityResult, cancelCurrentQuestion);
                } else {
                    incompatibility.reset(item.roomId!);
                    afterUpdateQuestion({
                        item,
                        contextQuestions,
                        question,
                        scrollToNextQuestion: checkValue,
                        questionsModified: true,
                        logs: {
                            itemId: item.roomId,
                            event: 'onSetValueOnQuestion valid',
                            item,
                            question,
                            valueParameters,
                            step,
                        },
                    });
                }
            } else {
                incompatibility.reset(item.roomId!);
                const result = ContextQuestionsUtils.verifyIfQuestionsFinished({ contextQuestions, produitPrincipal: getProduitPrincipal(item), isVendeur });
                contextQuestions.isEmpty = result.isEmpty;
                contextQuestions.isStepCompleted = result.isStepCompleted;
                updateStepQuestions({
                    contextQuestions,
                    questionsModified: true,
                    logs: { event: 'onSetValueOnQuestion not valid', item, question, valueParameters, step },
                });
                //* SCROLL TO SHOW validate "OK" button for QMC
                if (question.type === QuestionType.MultipleChoices && question.optionValues?.length && !question.isValid) {
                    // scroller.scrollToQuestion({
                    //     scrollerId: Scroller.ID,
                    //     itemId: getItemId(item),
                    //     toQuestion: question,
                    //     delay: Scroller.ShortDelay,
                    //     forceScrollToBottom: true
                    // });

                    scroller.scrollToAlignBottomQuestionOnBottom(
                        Scroller.ID,
                        getItemId(item),
                        question,
                        Scroller.ShortDelay,
                    );
                }
            }
        });
    };

    const afterUpdateQuestion = (afterUpdateQuestionParameters: AfterUpdateQuestionParameters<T>) => {
        const { item, contextQuestions, question, scrollToNextQuestion, questionsModified, logs } =
            afterUpdateQuestionParameters;
        showNextQuestionIfNeeded({
            item,
            contextQuestions,
            fromQuestion: question,
            scrollToNextQuestion,
            questionsModified,
            logs,
        });
        if (question.type === QuestionType.Produit) {
            const findQuestionProduit: QuestionItem | undefined =
                QuestionsHelper.findQuestionProduitWhichRefersTheQuestion(question, contextQuestions.questions);
            if (findQuestionProduit) {
                loadProduitsForQuestion(item, contextQuestions, findQuestionProduit, findQuestionProduit.produitSearch);
            }
        }
    };

    //#endregion

    //#region //* LOAD PRODUITS

    const loadProduitsForQuestion = (
        item: T,
        contextQuestions: ContextQuestionList,
        question: QuestionItem,
        search?: string
    ): void => {
        const produitQuestionNotFirstLoad = Boolean(question.produitValue) || Boolean(question.excludeResponseSelected);
        QuestionsHelper.loadProduits({
            question,
            context: question.context,
            search: search ?? '',
            versionId: versionId!,
            updateQuestion: ({ question: questionProduitUpdated }: UpdateQuestionAfterLoadProduitsParameters) => {
                const { questions = [] } = contextQuestions;

                if (!search && question.produit_properties.allow_copy_last_choosed_product) {
                    questionProduitUpdated = ContextQuestionsUtils.addPreviousSelectedProductSuggestions(
                        item,
                        questionProduitUpdated
                    );
                }

                const updatedQuestions = QuestionsHelper.updateQuestions(questions, questionProduitUpdated);
                contextQuestions.questions = updatedQuestions;
                const result = ContextQuestionsUtils.verifyIfQuestionsFinished({ contextQuestions, produitPrincipal: getProduitPrincipal(item), isVendeur });
                contextQuestions.isEmpty = result.isEmpty;
                contextQuestions.isStepCompleted = result.isStepCompleted;

                if (questionProduitUpdated.produitValue?.is_default) {
                    questionProduitUpdated.isValid = true;
                }

                updateStepQuestions({
                    contextQuestions,
                    questionsModified: questionProduitUpdated.produitsLoaded,
                    openNextSectionIfNeeded: false,
                    logs: { events: 'loadProduitsForQuestion', produitQuestionNotFirstLoad },
                });

                scroller.scrollToQuestion({
                    scrollerId: Scroller.ID,
                    itemId: getItemId(item),
                    toQuestion: questionProduitUpdated,
                    questions: contextQuestions.questions,
                    delay: 500
                });

                if (!produitQuestionNotFirstLoad && questionProduitUpdated.isValid) {
                    showNextQuestionIfNeeded({
                        item,
                        contextQuestions,
                        fromQuestion: questionProduitUpdated,
                        scrollToNextQuestion: false,
                    });
                }
            },
            showNextQuestionIfNeeded: (fromQuestion: QuestionItem) => {
                showNextQuestionIfNeeded({
                    item,
                    contextQuestions,
                    fromQuestion,
                    scrollToNextQuestion: false,
                });
            },
        });
    };

    //#endregion

    //* SHOW NEXT QUESTIONS (make it visible)
    //* called when user answer a question
    //* or user skip a question with type === 'Comment'
    //? je pense que ça doit retourner une liste de question
    const showNextQuestionIfNeeded = (params: ShowNextQuestionIfNeededParameters<T>): void => {
        const { item, fromQuestion, contextQuestions, scrollToNextQuestion, questionsModified, logs } = params;
        const questions = contextQuestions.questions || [];
        const { lastShownQuestion } = QuestionsHelper.getLastShownQuestion(questions);

        const produitPrincipalCompleteCode = ProductHelper.getCompleteCode(getProduitPrincipal(item));

        if (!lastShownQuestion?.isValid) {
            ContextQuestionsUtils.setFocusQuestion({ contextQuestions, questions, focusQuestion: lastShownQuestion });
            updateStepQuestions({ contextQuestions, questionsModified, logs });
        } else {
            const nextQuestionWithNoAnswer: QuestionItem | undefined = QuestionsHelper.getNextQuestionToShow({
                fromQuestion,
                questions,
                produitPrincipalCompleteCode,
            });
            if (nextQuestionWithNoAnswer) {
                ContextQuestionsUtils.setFocusQuestion({ contextQuestions, questions, focusQuestion: nextQuestionWithNoAnswer });
                updateStepQuestions({ contextQuestions, questionsModified, logs });
            } else {
                contextQuestions.endQuestion = true;
                contextQuestions.questions = questions;
                const result = ContextQuestionsUtils.verifyIfQuestionsFinished({ contextQuestions, produitPrincipal: getProduitPrincipal(item), isVendeur });
                contextQuestions.isEmpty = result.isEmpty;
                contextQuestions.isStepCompleted = result.isStepCompleted;
                questions.forEach((q) => (q.focusQuestion = false));

                updateStepQuestions({ contextQuestions, questionsModified, logs });
            }
        }

        if (scrollToNextQuestion) {
            //* SCROLL VERS LA PROCHAINE QUESTION VISIBLE (même si il y a déjà une réponse)
            const nextQuestionToScroll: QuestionItem | undefined = QuestionsHelper.getNextVisibleQuestion(
                fromQuestion,
                questions
            );
            if (nextQuestionToScroll) {
                scroller.scrollToQuestion({
                    scrollerId: Scroller.ID,
                    itemId: getItemId(item),
                    toQuestion: nextQuestionToScroll,
                    questions: contextQuestions.questions,
                    delay: Scroller.ShortDelay
                });
            }
        }
    };


    return { onSetValueOnQuestion, loadProduitsForQuestion };
};


