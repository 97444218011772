import { QuestionContext } from '../../Helpers/QuestionContextHelper';
import { LocalitiesSearchResultDto, LocalityDto } from '../TravelExpenses/TravelExpenseDto';
import { ProduitDto } from './ProduitDto';

export enum QuestionStep {
    StepHabitation = 'StepHabitation',
    StepTva = 'StepTva',
    StepProduit = 'StepProduit',
    StepPose = 'StepPose',
    StepSupport = 'StepSupport',
    StepPreparation = 'StepPreparation',
    StepFinitions = 'StepFinitions',
    StepServices = 'StepServices',
}

export enum QuestionType {
    SingleChoice = 'SingleChoice',
    MultipleChoices = 'MultipleChoices',
    Number = 'Number',
    Comment = 'Comment',
    Produit = 'Produit',
    Respiration = 'Respiration',
    ZipCode = 'ZipCode',
}

export enum QuestionContextType {
    General = 'General',
    Rooms = 'Rooms',
    Walls = 'Walls',
    Openings = 'Openings',
    RoomElements = 'RoomElements',
}

export enum PrixContextType {
    MetierDePose = 'MetierDePose',
    Rooms = 'Rooms',
    Walls = 'Walls',
    Openings = 'Openings',
    RoomElements = 'RoomElements',
}

export interface ProductFamilySummary {
    readonly id: string;
    readonly name: string;
}

export interface ConditionDto {
    readonly json_logic: string;
}

export interface Infobulle {
    readonly id: string;
    readonly index: number;
    readonly label: string;
    readonly visibility_condition: ConditionDto;
}

export interface SyntheseDto {
    readonly id: string;
    readonly index: number;
    readonly template: string;
    readonly visibility_condition: ConditionDto;
}

export interface PoseDto {
    readonly numero_rubrique: number;
    readonly tarif_ht: number;
    readonly label: string;
}

//#region //* SingleChoice

export enum ChoiceDisplayStyle {
    BigSquares = 'BigSquares',
    LittleRectangles = 'LittleRectangles',
    CardsWithDetails = 'CardsWithDetails',
    Supplement = 'Supplement',
}

export interface OptionDto {
    readonly id: string;
    readonly label: string;
    readonly description?: string;
    readonly show_only_to_sellers: boolean;
    readonly is_recommended_choice: boolean;
    readonly include_price: boolean;
    readonly exclude_other_options: boolean;
    readonly index: number;
    readonly value: number;
    readonly image_id: string;
    readonly pose: PoseDto;
    readonly visibility_condition: ConditionDto;
}
export interface SingleChoiceProperties {
    readonly display_style: ChoiceDisplayStyle;
    readonly option_list: Array<OptionDto>;
}
export interface MultipleChoicesProperties {
    readonly option_list: Array<OptionDto>;
    readonly display_style: ChoiceDisplayStyle;
}
//#endregion
export interface NumberProperties {
    readonly minimum: number;
    readonly maximum: number;
    readonly placeholder: string;
    readonly unit: string;
}
export interface CommentProperties {
    readonly placeholder: string;
    readonly input_type: CommentPropertiesInputType;
}

export enum CommentPropertiesInputType {
    FreeText = 'FreeText',
    DateMMAAAA = 'DateMMAAAA',
}
export interface RespirationProperties {
    image_id: string;
}

export interface ZipCodeProperties {
    placeholder: string;
}
export interface QuestionSummary {
    id: string;
}

//#region  PRIX

export enum FrontCalculType {
    SendToAPI = 'SendToAPI',
    FrontLaize = 'FrontLaize',
}

export interface ModeCalculDto {
    readonly id: string;
    readonly name: FrontCalculType;
}

export enum BaseDeCalculDto {
    NoBase = 'NoBase',
    RoomSurfaceMetreCarre = 'RoomSurfaceMetreCarre',
    RoomPerimeterMetre = 'RoomPerimeterMetre',
    RoomPerimeterPlinthesMetre = 'RoomPerimeterPlinthesMetre',
    OpeningDimensionCentimetre = 'OpeningDimensionCentimetre',
    LongueurRaccordMetre = 'LongueurRaccordMetre',
    RoomLongueurTotaleLaizeMetre = 'RoomLongueurTotaleLaizeMetre',
    RoomElementsArea = 'RoomElementsArea',
    RoomElementsMetreLineaire = 'RoomElementsMetreLineaire',
    RoomSurfaceManipuleeProrataMetreCarre = 'RoomSurfaceManipuleeProrataMetreCarre',
    MarcheLengthMetreLineaire = 'MarcheLengthMetreLineaire',
    RoomLamePleineQuantity = 'RoomLamePleineQuantity',

    //* ROOM CONTOURNEMENTS
    RoomRadiatorCount = 'RoomRadiatorCount',
    RoomThingsOnAStandCount = 'RoomThingsOnAStandCount',
    RoomObstacleCount = 'RoomObstacleCount',
    RoomStoneWallMetre = 'RoomStoneWallMetre',
    RoomStairsCount = 'RoomStairsCount',
    RoomPipeOnFloorCount = 'RoomPipeOnFloorCount',
    RoomTreeCount = 'RoomTreeCount',
    RoomRailingCount = 'RoomRailingCount',

    //* WALL CONTOURNEMENTS
    WallPipeCount = 'WallPipeCount',
    WallPipeHoleCount = 'WallPipeHoleCount',
    ElectricEquipmentCount = 'ElectricEquipmentCount',
    WallPillarCount = 'WallPillarCount',
    HotspotCount = 'HotspotCount',
}

export interface PrixDto {
    readonly id: string;
    readonly base_de_calcul_type: BaseDeCalculDto;
    readonly visibility_condition: ConditionDto;

    readonly show_only_to_sellers: boolean;
    readonly pre_visibility_condition: ConditionDto;
    readonly context_type: PrixContextType;
    readonly openings_context_properties: OpeningsContextPropertiesDto;
    readonly room_elements_context_properties: RoomElementsContextPropertiesDto;
    readonly famille_produit_code_list: Array<string>;
}

//#endregion

export type ExcludeResponse = { label: string; imageId: string };

export type ProductProperties = Partial<{
    is_required: boolean;
    have_suggestions: boolean;
    placeholder: string;
    catalogue_search_allowed: boolean;
    allow_copy_last_choosed_product: boolean;
    exclude_response_label?: string;
    exclude_response_image_id?: string;
}>

export interface EvaluationDto {
    readonly id: string;
    readonly index: number;
    readonly name: string;
    readonly value: string;
    readonly evaluation_condition: ConditionDto;
}

export enum IncompatibilityTypeDto {
    Produit = 'Produit',
    FinDeParcours = 'FinDeParcours',
}

export interface IncompatibilityDto {
    readonly id: string;
    readonly index: number;
    readonly explanation: string;
    readonly redirect_url: string;
    readonly cta: string;
    readonly type: IncompatibilityTypeDto;
    readonly visibility_condition: ConditionDto;
}

export interface OpeningsContextPropertiesDto {
    readonly is_for_porte: boolean;
    readonly is_for_porte_blindee: boolean;
    readonly is_for_porte_entree: boolean;
    readonly is_for_porte_fenetre: boolean;
    readonly is_for_passage_ouvert: boolean;
    readonly is_for_baie_vitree: boolean;
}

export interface RoomElementsContextPropertiesDto {
    readonly is_for_shower_bathtub: boolean;
    readonly is_for_fixed_furniture: boolean;
    readonly is_for_cupboard: boolean;
    readonly is_for_kitchen_furniture: boolean;
    readonly is_for_trap: boolean;
    readonly is_for_fireplace: boolean;
    readonly is_for_opening: boolean;
    readonly is_for_stairs: boolean;
    readonly is_for_divider: boolean;
    readonly is_for_heavy_element: boolean;
    readonly is_for_sewer_plate: boolean;
    readonly is_for_door: boolean;
    readonly is_for_window: boolean;
    readonly is_for_unlaid_area: boolean;
}
export interface QuestionItem {
    readonly id: string;
    readonly title: string;
    readonly label: string;
    readonly label_single_choice: string;
    readonly related_step: QuestionStep;
    readonly famille_produit_code_list: Array<string>;
    readonly additional_informations: string;
    readonly comment: string;
    readonly index: number;
    readonly type: QuestionType;
    readonly context_type: QuestionContextType;
    readonly show_only_to_sellers: boolean;

    readonly openings_context_properties: OpeningsContextPropertiesDto;
    readonly room_elements_context_properties: RoomElementsContextPropertiesDto;

    readonly number_properties: NumberProperties;
    readonly comment_properties: CommentProperties;
    readonly single_choice_properties?: SingleChoiceProperties;
    readonly multiple_choices_properties?: MultipleChoicesProperties;
    readonly respiration_properties: RespirationProperties;
    readonly zip_code_properties: ZipCodeProperties;
    //* retrieve products from question id
    //* in parameter : reference question response : id ?
    readonly produit_properties: ProductProperties;

    readonly infobulle_list: Array<Infobulle>;
    readonly evaluation_list: Array<EvaluationDto>;
    readonly visibility_condition: ConditionDto;
    //readonly prix_list: Array<PrixDto>;//! TO DELETE LATER
    readonly incompatibility_list: Array<IncompatibilityDto>;
    readonly synthese_list: Array<SyntheseDto>;

    isFirstProduct?: boolean;
    loadingProduits?: boolean;
    produits: Array<ProduitDto>;
    produitsLoaded?: boolean;
    produitValue?: ProduitDto;
    produitPrincipalCode?: string;
    produitSearch?: string;
    needLoadProduits?: boolean;
    produitReadonlyReference?: string;

    excludeResponseSelected?: boolean;

    showQuestion?: boolean;
    optionValue?: OptionDto;
    optionValues?: Array<OptionDto>;
    numberValue?: string;
    commentValue?: string;

    zipCodeData?: LocalitiesSearchResultDto;
    zipCodeValue?: LocalityDto;
    zipCodeValueLoading?: boolean;
    errorInvalidNumberValue?: boolean;

    isFirstQuestion?: boolean;

    //* answer is valid
    isValid?: boolean;
    //! or user skip to next question to make it valid (question type Comment)
    //! isSkip?: boolean;

    //* review de la gestion du context sur les questions (14/03/2021)
    context: QuestionContext;
    previousVisibleQuestion?: QuestionItem;

    focusQuestion?: boolean;

    linkedWithStepPlanId?: string;

    modifiedAt?: string;
}
