import loadash from 'lodash';
import React from 'react';

//#region //! TODO remove => not used

export default function useDebounce<T extends any>(value: T, delay: number) {
    const [debouncedValue, setDebouncedValue] = React.useState(value);

    React.useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);

    return debouncedValue;
}

/**
 * Debounce call to onChangeCallback and provide live feedback for the view.
 * @param value the debounced value stored in the state
 * @param onChangeCallback to be debounced callback. In order to work the same callback need to be passed to each render.
 * @param delay
 */


export function useDebounceOnChangeCallback<T extends any>(value: T, onChangeCallback: (v: T) => void, delay: number) {
    const [previousValue, setPreviousValue] = React.useState(value);
    const [liveValue, setLiveValue] = React.useState(value);

    if (previousValue !== value) {
        setPreviousValue(value);
        setLiveValue(value);
    }

    const debounceFunc = React.useMemo(() => {
        return loadash.debounce(onChangeCallback, delay);
    }, [onChangeCallback, delay]);

    const onChange = React.useCallback(
        (v: T) => {
            setLiveValue(v);
            debounceFunc(v);
        },
        [debounceFunc]
    );

    return { onChange, liveValue };
}

//#endregion

type ThrottleParams<T> = {
    initialValue: T;
    onDelayChange: (v: T) => void;
    onLiveChange?: (value: T) => void;
    delay: number
};

export function useThrottle<T extends any>({ initialValue, onDelayChange, onLiveChange, delay }: ThrottleParams<T>) {

    const [liveValue, setLiveValue] = React.useState<T>(initialValue);

    const debounceFunc = React.useMemo(() => {
        return loadash.debounce(onDelayChange, delay);
    }, [onDelayChange, delay]);

    React.useEffect(() => {
        if (initialValue !== liveValue) {
            setLiveValue(initialValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onLocalChange = React.useCallback((v: T) => {
        setLiveValue(v);
        onLiveChange?.(v);
        debounceFunc(v);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { onChange: onLocalChange, liveValue, setLiveValue };
}
