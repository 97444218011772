import React, { CSSProperties } from 'react';

import { IOpening } from '../../Models/IOpening';
import { IRoom } from '../../Models/IRoom';
import { IRoomItem } from '../../Models/IRoomItem';
import { PointUtils } from '../../Utils/PointUtils';
import { CoordPoint, Wall } from '../../Utils/Types';
import { LaizeBandItemV3 } from '../Laizes/v3/LaizeBandTrace';
import { LaizeV3TraceList } from '../Laizes/v3/LaizeV3Traces';
import { RawBoxRoomsRibs } from '../Widgets/BoxWalls/BoxWallRibs';
import { RawBoxWalls } from '../Widgets/BoxWalls/BoxWalls';
import { RawCarpentryItems } from '../Widgets/Openings/BoxCarpentryItems';
import { RawBoxRoomItems } from '../Widgets/RoomItems/BoxRoomItems';
import { RawBoxAreas } from '../Widgets/Rooms/BoxAreas';

type LaizeDebugMapSvgV3Props = {
    svgWrapper?: React.RefObject<SVGSVGElement>;
    walls?: Array<Wall>;
    roomItems?: Array<IRoomItem>;
    rooms: Array<IRoom>;
    openings: Array<IOpening>;

    roomId?: string;
    style?: CSSProperties;
    bands_v3?: Array<LaizeBandItemV3>;
    exportMode?: boolean;
};

export const LaizeDebugMapSvgV3 = ({
    style,
    svgWrapper,
    roomId,
    walls = [],
    roomItems = [],
    rooms,
    openings,
    bands_v3 = [],
    exportMode,
}: LaizeDebugMapSvgV3Props) => {
    const legendHeight = 50;

    const selectedRoom = rooms.find((x) => x.roomId === roomId);
    const rotationAngle = selectedRoom?.rotationAngle || 0;

    const margin = rotationAngle !== 0 ? 300 : 100;
    const boxMargin = margin / 5;
    const exportWidth = 1080;

    const points = [
        ...walls.map((x) => x.start),
        ...roomItems.reduce<Array<CoordPoint>>((items, x) => [...items, ...(x.coordsReal || [])], []),
        ...bands_v3.reduce<Array<CoordPoint>>((items, x) => [...items, ...x.points], []),
    ];

    const overallBBox = PointUtils.calculateBoundingBox({ points: points })!;

    if (!overallBBox) {
        return null;
    }

    const laizeTraceDesciptionWidth = 100;

    const xMin = overallBBox.xMin - boxMargin;
    const yMin = overallBBox.yMin - boxMargin;
    const xMax = overallBBox.xMax + boxMargin;
    const yMax = overallBBox.yMax + boxMargin + legendHeight;
    const width = xMax - xMin + laizeTraceDesciptionWidth;
    const height = yMax - yMin + legendHeight;

    const viewWidth = exportMode ? exportWidth : width;
    const viewHeight = exportMode ? (exportWidth * height) / width : height;

    return (
        <div id="LaizeDebugMapV3" style={style}>
            {/* <SVGPanZoom width={viewWidth} height={viewHeight}> */}
            <svg
                id="LaizeDebugMapSvgV3"
                ref={svgWrapper}
                xmlns="http://www.w3.org/2000/svg" //!\ IMPORTANT FOR EXPORT
                viewBox={`${xMin} ${yMin} ${width} ${height}`}
                width={viewWidth}
                height={viewHeight}
                transform={`rotate(${rotationAngle} 0 0)`}>
                <g id="planGroup">
                    <g id="boxwall">
                        <RawBoxWalls readonly roomId={roomId} rooms={rooms} />
                    </g>
                    <g id="boxcarpentry">
                        <RawCarpentryItems readonly roomId={roomId} openings={openings} />
                    </g>
                    <g id="boxRoomItem">
                        <RawBoxRoomItems readonly roomId={roomId} roomItems={roomItems} />
                    </g>
                    <g id="boxArea">
                        <RawBoxAreas readonly roomId={roomId} rooms={rooms} />
                    </g>
                    <g id="boxCote">
                        <RawBoxRoomsRibs readonly roomId={roomId} rooms={rooms} roomItems={roomItems} />
                    </g>
                    <g id="boxDebug">
                        <LaizeV3TraceList bands={bands_v3} roomId={roomId} />
                    </g>
                </g>
            </svg>
            {/* </SVGPanZoom> */}
        </div>
    );
};
