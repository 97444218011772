import { Logger } from '../../../Errors/Logger';
import { LaizeCalculatorResult, LaizeProps, LaizeResult } from '../Components/Laizes/Laize';
import { CalculLaizeParams, LaizeCalc } from '../Components/Laizes/LaizeCalc';
import { IOpening } from '../Models/IOpening';
import { IRoom } from '../Models/IRoom';
import { IRoomItem } from '../Models/IRoomItem';
import { Wall } from '../Utils/Types';

export type LaizeResultParams = {
    rooms: Array<IRoom>;
    roomItems: Array<IRoomItem>;
    walls: Array<Wall>;
    openings: Array<IOpening>;
    logs?: any;
    laizeProps: LaizeProps;
};

export class LaizeComputer {
    public static compute = (props: LaizeResultParams) => {
        let { laizeProps, openings, roomItems, rooms, walls } = props;
        Logger.isLogEnabled() && console.groupCollapsed(`LaizeComputer : LAIZES CALC - ${laizeProps.productCode}`, props.logs);

        let laizesCalc: LaizeCalculatorResult | undefined = undefined;
        let laizeResult: LaizeResult | undefined = undefined;
        const calcParams: CalculLaizeParams = {
            laizeCalcProps: LaizeCalc.toLaizeCalcProps(laizeProps),
            rooms,
            openings,
            roomItems,
            walls,
            flooringDirectionByRoomId: LaizeCalc.getFlooringDirectionByRoomId(rooms),
        };

        let logName = `from LaizeComputer with laizeDirectionSelected or default direction`;
        laizesCalc = LaizeCalc.calculLaize({ ...calcParams, logName });
        laizeResult = {
            laizes: laizesCalc?.roomsBandsDetailsContiguous,
            flooringDirectionByRoomId: laizesCalc?.flooringDirectionByRoomId,
            calculator: laizesCalc,
            possibleFlooringDirectionByRoomId: laizesCalc?.possibleFlooringDirectionByRoomId,
            roomIds: rooms.map((r) => r.roomId!),
            productCode: laizeProps.productCode,
            result: laizesCalc?.laizeResult,
            roomsRaccordLength: laizesCalc?.roomsRaccordLength,
        };
        console.log('laizesCalc / Result', laizesCalc, laizeResult);

        Logger.isLogEnabled() && console.groupEnd();

        return { laizesCalc, laizeResult };
    };
}
