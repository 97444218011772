import { CoordPoint, WallLine } from '../Utils/Types';
import { IMapItem, YesOrNo } from './IMapItem';

export enum RoomItemType {
    //* Sol
    ShowerBathtub = 'shower_bathtub',
    FixedFurniture = 'fixed_furniture',
    Cupboard = 'cupboard',
    KitchenFurniture = 'kitchen_furniture',
    Trap = 'trap',
    Fireplace = 'fireplace',
    Opening = 'opening',
    Stairs = 'stairs',
    Divider = 'divider',
    HeavyElement = 'heavy_element',
    SewerPlate = 'sewer_plate',

    //* Wall
    Door = 'door',
    Window = 'window',
    UnlaidArea = 'unlaid_area',
}

export function toRoomItemKeyFromValue(value: string): string | undefined {
    const entry = Object.entries(RoomItemType).find(([key, val]) => val === value);
    return entry ? entry[0] : undefined;
}

export interface IRoomItem extends IMapItem {
    roomItemId?: string;

    //* INFOS
    type?: RoomItemType;
    putFlooring?: YesOrNo;
    putPlinthes?: string;
    width?: number;
    height?: number;
    dimensId?: number;
    isMoveableOutside?: boolean; // #20350

    //* COORDS
    coords?: Array<CoordPoint>;
    coordsReal?: Array<CoordPoint>;
    graphTranslation?: GraphTranslation;

    surface?: number;
    horizontal_size?: number;
    vertical_size?: number;
    room_elements_length?: number;

    //* Used for gestures
    isMoving?: boolean;
}

type GraphTranslation = CoordPoint & {
    //!only set in TestCase
    wall?: WallLine;
    roomId?: string;
    distance?: number;
};

export type RoomItemWithAngle = { roomItem: IRoomItem; angle: number };
