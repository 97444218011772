type ObjetType = Record<string, any>;

//TODO: Remove this function, use lodash
export const isObjectsEquals = (a: ObjetType, b: ObjetType, message?: any) => {
    if (message) console.log(message);
    let isOK = true;
    for (let prop in a) {
        if (a[prop] !== b[prop]) {
            isOK = false;
            break;
        }
    }
    return isOK;
};
