import { IRoom } from '../../Models/IRoom';
import { IRoomItem } from '../../Models/IRoomItem';
import { JstsPolygon, JstsUtils } from '../JstsUtils';

export class InvalidPositionDetection {
    public static roomPolyById: { [key: string]: JstsPolygon } = {};
    public static getInvalidRoomIds(rooms: Array<IRoom>, roomId: string): Array<string> {
        const invalidRoomIds: Set<string> = new Set<string>();
        for (const room of rooms) {
            if (!this.roomPolyById[room.roomId!] || room.roomId === roomId) {
                this.roomPolyById[room.roomId!] = JstsUtils.createJstsPolygon(room.coords!);
            }
        }

        //Check if the current room is invalid
        if (!this.roomPolyById[roomId].isValid()) {
            invalidRoomIds.add(roomId);
            return Array.from(invalidRoomIds);
        }
        //Check if the current room overlaps/contains other rooms
        for (const otherRoomId of rooms.map((x) => x.roomId!).filter((x) => x !== roomId)) {
            const overlaps = this.roomPolyById[roomId].overlaps(this.roomPolyById[otherRoomId]);
            if (overlaps) {
                invalidRoomIds.add(otherRoomId);
            } else {
                const contains =
                    this.roomPolyById[roomId].contains(this.roomPolyById[otherRoomId]) ||
                    this.roomPolyById[otherRoomId].contains(this.roomPolyById[roomId]);
                if (contains) {
                    invalidRoomIds.add(otherRoomId);
                }
            }
        }
        if (invalidRoomIds.size > 0) {
            invalidRoomIds.add(roomId);
        }
        return Array.from(invalidRoomIds);
    }

    public static getInvalidRoomItemIds(roomItems: Array<IRoomItem>, roomItemId: string): Array<string> {
        const roomItemPolyById: Record<string, JstsPolygon> = {};
        const invalidRoomItemIds: Set<string> = new Set<string>();
        roomItems.forEach(r => roomItemPolyById[r.roomItemId!] = JstsUtils.createJstsPolygon(r.coordsReal!));

        //Check if the current roomItem overlaps/contains other roomItems
        for (const otherRoomItemId of roomItems.map((x) => x.roomItemId!).filter((x) => x !== roomItemId)) {
            const overlaps = roomItemPolyById[roomItemId].overlaps(roomItemPolyById[otherRoomItemId]);
            if (overlaps) {
                invalidRoomItemIds.add(otherRoomItemId);
            } else {
                const contains =
                    roomItemPolyById[roomItemId].contains(roomItemPolyById[otherRoomItemId]) ||
                    roomItemPolyById[otherRoomItemId].contains(roomItemPolyById[roomItemId]);
                if (contains) {
                    invalidRoomItemIds.add(otherRoomItemId);
                }
            }
        }
        if (invalidRoomItemIds.size > 0) {
            invalidRoomItemIds.add(roomItemId);
        }
        return Array.from(invalidRoomItemIds);
    }
}
