import { styled } from '@material-ui/styles';
import React, { CSSProperties } from 'react';

import { ThemeRes } from '../../../../../Web/Themes/ThemeRes';
import { MapConstants } from '../../../MapConstants';
import { IRoom } from '../../../Models/IRoom';
import { IRoomItem } from '../../../Models/IRoomItem';
import { PointUtils } from '../../../Utils/PointUtils';
import { CoordPoint } from '../../../Utils/Types';
import { LaizeBand, LaizeCalculatorResult } from '../../Laizes/Laize';
import { LaizeBandDimensions, LaizeBands } from '../../Laizes/v3/LaizeBands';
import { LaizeBandItemV3 } from '../../Laizes/v3/LaizeBandTrace';
import { RawBoxWalls } from '../../Widgets/BoxWalls/BoxWalls';
import { RawBoxRoomItems } from '../../Widgets/RoomItems/BoxRoomItems';
import { ExportMapImageHelper } from '../Helpers/ExportMapPngHelper';
import { SvgHelpers } from '../Helpers/SvgGenerationHelpers';
import { ExportRoomLaizeBandsSvgItem, SvgByRoom } from './LaizeExportBands';

type ExportRoomLaizeBandsSvgV3Params = {
    room: IRoom;
    rooms: Array<IRoom>;
    roomItems: Array<IRoomItem>;
    laizeResult?: LaizeCalculatorResult;
    getHolder: () => SvgByRoom;
};

export const LaizeExportRoomBandsSvgV3 = ({
    room,
    rooms,
    roomItems,
    laizeResult,
    getHolder,
}: ExportRoomLaizeBandsSvgV3Params): ExportRoomLaizeBandsSvgItem => {
    const bandDetails = laizeResult?.roomsBandsDetails.find((x) => x.roomId === room.roomId)?.bands || [];
    const bands: Array<BandV3> =
        laizeResult?.bands_v3
            ?.filter(
                (item) =>
                    item.roomId === room.roomId && ['band-new', 'band-reusable', 'band-reused'].includes(item.type)
            )
            .map(toBandV3) || [];

    const captureSvg = (toBase64: boolean = true) => {
        const holder = getHolder();
        return SvgHelpers.capture(holder?.svgWrapper?.current?.outerHTML, toBase64);
    };

    const capturePng = (toBase64: boolean = true) => {
        const imageSrc = captureSvg(toBase64);
        return ExportMapImageHelper.exportMapPng(imageSrc);
    };

    return {
        room,
        captureSvg,
        capturePng,
        getHolder,
        renderView: ({ id, style }: React.HTMLAttributes<any> = {}) => (
            <RoomLaizeBandsViewV3
                key={id}
                setSvgWrapper={(ref) => {
                    const holder = getHolder();
                    if (holder) {
                        holder.svgWrapper = ref;
                    }
                }}
                room={room}
                rooms={rooms}
                roomItems={roomItems}
                bands={bands}
                bandDetails={bandDetails}
                style={style}
            />
        ),
    };
};

const toBandV3 = (item: LaizeBandItemV3): BandV3 => {
    const { bandId, points, type } = item;

    //TODO : REFACTOR THIS WITH LaizeV3Traces
    const textDimensions = getTextDimensions(LaizeBands.getBandDimensions(points));

    return {
        origin: item,
        type,
        id: bandId,
        coords: points,
        center: PointUtils.bboxCenter(points),
        textDimensions,
    };
};

type BandV3 = {
    id?: number;
    coords: Array<CoordPoint>;
    type?: string;
    center: CoordPoint;
    textDimensions: string;

    surfaceCoveredDimensions?: string;
    leftOverDimensions?: string;

    origin: LaizeBandItemV3;
};

type SvgViewProps = {
    setSvgWrapper: (ref: React.RefObject<SVGSVGElement>) => void;
    room: IRoom;
    rooms: Array<IRoom>;
    roomItems: Array<IRoomItem>;
    bands: Array<BandV3>;
    bandDetails: Array<LaizeBand>;
    style?: CSSProperties;
    exportMode?: boolean;
};
const RoomLaizeBandsViewV3 = ({
    setSvgWrapper,
    room,
    rooms,
    roomItems,
    bands,
    bandDetails,
    style,
    exportMode,
}: SvgViewProps) => {
    const svgWrapper = React.useRef<SVGSVGElement>(null);

    React.useEffect(() => {
        setSvgWrapper(svgWrapper);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [svgWrapper.current]);

    const { boxBandList, bandNumberList, legendItemList } = toWidgets({ bands, bandDetails });
    const bandPoints = bands?.reduce<Array<CoordPoint>>((current, b) => [...current, ...b.coords], []);
    const overallBBox = PointUtils.calculateBoundingBox({ points: bandPoints })!;
    if (!overallBBox) {
        return null;
    }

    const legendHeight = 300;
    const margin = 50;
    const exportWidth = 1080;
    const xMin = overallBBox.xMin - margin - 320;
    const yMin = overallBBox.yMin - margin - 50;
    const xMax = overallBBox.xMax + margin;
    const yMax = overallBBox.yMax + margin + legendHeight;
    const width = xMax - xMin;
    const height = yMax - yMin;

    return (
        <Container id={`RoomLaizeBandsViewV3-${room.roomId}`} style={style}>
            <svg
                id={`RoomLaizeBandsViewV3-${room.roomId}`}
                ref={svgWrapper}
                xmlns="http://www.w3.org/2000/svg" //!\ IMPORTANT FOR EXPORT
                viewBox={`${xMin} ${yMin} ${width} ${height}`}
                width={exportMode ? exportWidth : width}
                height={exportMode ? (exportWidth * height) / width : height}>
                <g id="planGroup">
                    <g id="boxwall">
                        <RawBoxWalls rooms={rooms} roomId={room.roomId} readonly />
                    </g>
                    <g id="boxRoomItem">
                        <RawBoxRoomItems roomItems={roomItems} readonly roomId={room.roomId} />
                    </g>
                    <g id="boxDebug">{boxBandList}</g>
                    <g id="bandNumbers">{bandNumberList}</g>
                </g>
                <g id="legend" transform={`translate(${xMin + 10}, ${yMin + 30})`}>
                    <text
                        id="legend-title"
                        x="0"
                        y="0"
                        style={{ fontSize: 32, fontWeight: 800, fill: '#424448' }}
                        textAnchor="left">
                        {room.name}
                        {room.questionProduitPrincipal?.produitValue?.code && (
                            <tspan style={{ fontSize: 24, fontWeight: 400, fill: '#424448' }}>
                                {' '}
                                - {room.questionProduitPrincipal?.produitValue?.code}
                            </tspan>
                        )}
                    </text>
                    {legendItemList}
                </g>
            </svg>
        </Container>
    );
};

const Container = styled('div')({ display: 'none' });

type WidgetsProps = { bands: Array<BandV3>; bandDetails: Array<LaizeBand> };

const getTextDimensions = (dimensions: LaizeBandDimensions) => {
    const meter = MapConstants.meter;
    const displayedWidth = Math.round((dimensions.width * 100) / meter);
    const displayedHeight = Math.round((dimensions.height * 100) / meter);
    const textDimensions = `${displayedWidth}cm x ${displayedHeight}cm`;
    return textDimensions;
};
const toWidgets = ({ bands, bandDetails }: WidgetsProps) => {
    let bandCount = 0;

    const boxBandList: Array<React.ReactNode> = [];
    const bandNumberList: Array<React.ReactNode> = [];
    const legendItemList: Array<React.ReactNode> = [];

    const bandFillColorPair = '#fbe995';
    const bandStrokeColorPair = '#e5a033';
    const bandNumberColorPair = '#e5a033';
    const bandFillColorImpair = '#24e8fb';
    const bandStrokeColorImpair = '#172e60';
    const bandNumberColorImpair = '#1c1a37';

    let textY = 40;
    let colorPairImpair = false;
    bands?.forEach((band: BandV3, i) => {
        if (band.type !== 'band-reusable') {
            const correspondingBand = bandDetails[bandCount];

            if (correspondingBand !== undefined) {
                //! On prends la première chute. A revoir avec #36360
                const leftOverCoords = correspondingBand.leftOverCoords?.[0];
                if (leftOverCoords !== undefined) {
                    band.leftOverDimensions = getTextDimensions(LaizeBands.getBandDimensions(leftOverCoords));
                }
                if (correspondingBand.surfaceCoveredCoords !== undefined) {
                    band.surfaceCoveredDimensions = getTextDimensions(
                        LaizeBands.getBandDimensions(correspondingBand.surfaceCoveredCoords)
                    );
                }
            }
            bandCount++;
        }

        let fillColor;
        let strokeColor;
        let fillOpacity;
        let dashArray = false;

        switch (band.type) {
            case 'band-new':
                fillColor = colorPairImpair ? bandFillColorImpair : bandFillColorPair;
                strokeColor = colorPairImpair ? bandStrokeColorImpair : bandStrokeColorPair;
                fillOpacity = colorPairImpair ? 0.08 : 0.46;
                break;
            case 'band-reusable':
                colorPairImpair = !colorPairImpair;
                strokeColor = colorPairImpair ? bandStrokeColorImpair : bandStrokeColorPair;
                fillOpacity = 0.1;
                dashArray = true;
                break;
            case 'band-reused':
                fillColor = colorPairImpair ? bandFillColorImpair : bandFillColorPair;
                strokeColor = colorPairImpair ? bandStrokeColorImpair : bandStrokeColorPair;
                fillOpacity = colorPairImpair ? 0.08 : 0.46;
                break;
            default:
                break;
        }
        const coords = band.coords.map((p) => `${p.x},${p.y}`);

        const props: React.SVGAttributes<any> = {
            d: `M${coords[0]} ${coords.map((coord) => `L${coord}`).join(' ')} L${coords[0]}`,
            stroke: strokeColor,
            strokeWidth: band.type === 'band-reusable' ? 4 : 2,
            strokeOpacity: 1,
            strokeDasharray: dashArray ? '3 3' : undefined,
            fill: fillColor,
            fillOpacity: fillOpacity,
            fillRule: 'evenodd',
        };

        boxBandList.push(<path key={`boxband-${i}-${bandNumberList.length}`} {...props} />);

        if (band.type === 'band-reusable') {
            const props: React.SVGAttributes<any> = {
                d: `M${coords[0]} ${coords.map((coord) => `L${coord}`).join(' ')} L${coords[0]}`,
                stroke: strokeColor,
                strokeWidth: 2,
                strokeOpacity: 1,
                fill: 'white',
                fillOpacity: fillOpacity,
                fillRule: 'evenodd',
            };
            boxBandList.push(<path key={`boxband-${i}-${boxBandList.length}`} {...props} />);
        }

        if (band.type !== 'band-reusable') {
            bandNumberList.push(
                <g key={bandNumberList.length} transform={`translate(${band.center.x},${band.center.y}) scale(1)`}>
                    <circle r="18" fill={`${colorPairImpair ? bandNumberColorImpair : bandNumberColorPair}`} />
                    <BandText x="0" y="7" textAnchor="middle" style={{ fill: '#FFFFFF' }}>
                        {band.id}
                    </BandText>
                </g>
            );

            //* Gestion légende des bandes
            if (band.type === 'band-new' && band.leftOverDimensions) {
                //* check next band
                legendItemList.push(
                    <LegendItem
                        key={bandNumberList.length}
                        color={colorPairImpair ? bandNumberColorImpair : bandNumberColorPair}
                        posY={textY}
                        bandId={band.id!}
                        bandName={`BANDE ${band.id}`}
                        text1={`Bande à utiliser : ${band.textDimensions}`}
                        text2={`Découpe : ${band.surfaceCoveredDimensions}`}
                        text3={`Bande à réutiliser : ${band.leftOverDimensions}`}
                    />
                );
                textY += 144;
            } else if (band.type === 'band-reused') {
                legendItemList.push(
                    <LegendItem
                        key={bandNumberList.length}
                        color={colorPairImpair ? bandNumberColorImpair : bandNumberColorPair}
                        posY={textY}
                        bandId={band.id!}
                        bandName={`BANDE ${band.id}`}
                        text1={`Bande réutilisé : ${band.textDimensions}`}
                    />
                );
                textY += 90;
            } else {
                legendItemList.push(
                    <LegendItem
                        key={bandNumberList.length}
                        color={colorPairImpair ? bandNumberColorImpair : bandNumberColorPair}
                        posY={textY}
                        bandId={band.id!}
                        bandName={`BANDE ${band.id}`}
                        text1={`Bande à utiliser : ${band.textDimensions}`}
                    />
                );
                textY += 90;
            }
        }

        colorPairImpair = !colorPairImpair;
    });

    return { bandCount, boxBandList, bandNumberList, legendItemList };
};

const BandText = styled('text')({ fontFamily: 'NunitoSans', fontWeight: 900, fontSize: 18 });

type LegendItemProps = {
    bandId: number;
    bandName: string;

    color: string;
    posY: number;

    text1?: string;
    text2?: string;
    text3?: string;
};
const LegendItem = ({ bandId, bandName, text1, text2, text3, color, posY }: LegendItemProps) => {
    return (
        <g y="78">
            <g transform={`translate(20, ${posY}) scale(1)`}>
                <circle r="18" fill={color} />
                <BandText x="0" y="7" textAnchor="middle" style={{ fill: '#FFFFFF' }}>
                    {bandId}
                </BandText>
            </g>
            <text x="66" y={posY + 3} style={{ fontFamily: ThemeRes.Fonts.fontFamilyNunitoSans, fill: '#0f0e2d' }}>
                <TextSpan x="48" dy="3" style={{ fontWeight: 800 }}>
                    {bandName}
                </TextSpan>
                {text1 && (
                    <TextSpan x="48" dy="30">
                        {text1}
                    </TextSpan>
                )}
                {text2 && (
                    <TextSpan x="48" dy="27">
                        {text2}
                    </TextSpan>
                )}
                {text3 && (
                    <TextSpan x="48" dy="27">
                        {text3}
                    </TextSpan>
                )}
            </text>
        </g>
    );
};
const TextSpan = styled('tspan')({ fontWeight: 400, fontSize: 16 });
