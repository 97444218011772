import { Logger } from '../../../Errors/Logger';
import { FindFirstIncompatibilityResult } from '../../../Helpers/QuestionsHelper';
import { TagType } from '../../../Web/Pages/Quote/Widgets/TagLabel';
import { CompleteStatus, IMapItem, YesOrNo } from '../Models/IMapItem';
import { IOpening } from '../Models/IOpening';
import { IRoom, RoomLocalisation, RoomType } from '../Models/IRoom';
import { IRoomItem, RoomItemType } from '../Models/IRoomItem';

export class CompleteHelper {
    public static allComplete = (rooms: Array<IRoom> = []) => {
        const allCompleted = Boolean(rooms.length) && !rooms.some((r) => !r.completionStatus?.isCompleted);
        return allCompleted;
    };

    //#region //* ROOM

    public static isRoomComplete = (item: IRoom, rooms: Array<IRoom>): CompleteStatus => {
        const propertiesCompleted = this.isRoomPropertiesCompleted(item);
        const infosCompleted = this.isRoomInfoCompleted(item, rooms);
        const questionsComplete = this.isQuestionsComplete(item);
        const amenagementsCompletedType = this.getRoomAmenagementCompletionType(item);
        const amenagementsCompleted = amenagementsCompletedType === TagType.Done;
        const questionProduitCompleted = item.questionProduitPrincipal?.isValid;
        const isRoomCompleted = Boolean(
            infosCompleted && questionsComplete && amenagementsCompleted && questionProduitCompleted
        );

        return {
            propertiesCompleted,
            infosCompleted,
            questionsComplete,
            amenagementsCompleted,
            isCompleted: isRoomCompleted,
            completedType: this.getCompletionType(isRoomCompleted, item.findIncompatibilityResult),
            infoCompletedType: this.getCompletionType(infosCompleted, item.findIncompatibilityResult),
            amenagementsCompletedType,
        };
    };

    private static isRoomPropertiesCompleted = (room: IRoom) => {
        let isComplete: boolean =
            Boolean(room.localisation) &&
            Boolean(room.roomType) &&
            Boolean(room.roomFloor) &&
            Boolean(room.roomExistingFlooring) &&
            Boolean(room.roomExistingFloorType);

        if (room.localisation === RoomLocalisation.Inside && room.type === RoomType.Sol) {
            isComplete = isComplete && Boolean(room.roomHeating) && Boolean(room.roomHeatingType);
        }

        if (room.type === RoomType.Wall) {
            isComplete = isComplete && Boolean(room.roomWall);
        }

        return isComplete;
    };

    private static isRoomInfoCompleted = (room: IRoom, rooms: Array<IRoom>) => {
        let isComplete: boolean =
            this.isRoomPropertiesCompleted(room) &&
            Boolean(room.questionsTva?.endQuestion) &&
            Boolean(room.questionProduitPrincipal?.produitValue);

        const needCopyQuestionsAnswer =
            Boolean(
                rooms.filter(
                    (r) => r.localisation === room.localisation && r.roomId !== room.roomId && r.type === room.type
                ).length
            ) && !Boolean(room.questionsCopiedFromId);
        isComplete = isComplete && !needCopyQuestionsAnswer;
        return isComplete;
    };

    private static getRoomAmenagementCompletionType = (item: IRoom): TagType => {
        const { openings = [], roomItems = [] } = item;
        const completionTypes = [...openings, ...roomItems].map((i) => i.completionStatus?.completedType);
        if (completionTypes.includes(TagType.Error)) {
            return TagType.Error;
        } else if (completionTypes.includes(TagType.ToDo)) {
            return TagType.ToDo;
        }
        return TagType.Done;
    };

    public static getCompletionType = (
        completed: boolean,
        findFirstIncompatibilityResult?: FindFirstIncompatibilityResult
    ): TagType => {
        if (findFirstIncompatibilityResult?.findIncompatibility) {
            return TagType.Error;
        } else if (completed) {
            return TagType.Done;
        }
        return TagType.ToDo;
    };

    public static isOnlyRoomQuestionsCopiedAnswerMising = (room: IRoom) => {
        let isInfoComplete: boolean =
            Boolean(room.localisation) &&
            Boolean(room.roomType) &&
            Boolean(room.roomFloor) &&
            Boolean(room.roomExistingFlooring) &&
            Boolean(room.roomExistingFloorType) &&
            Boolean(room.questionProduitPrincipal?.produitValue);

        if (room.localisation === RoomLocalisation.Inside) {
            isInfoComplete = isInfoComplete && Boolean(room.roomHeating) && Boolean(room.roomHeatingType);
        }
        const isQuestionsCompleted = Boolean(this.isQuestionsComplete(room));
        const isAmenagementCompleted = this.getRoomAmenagementCompletionType(room) === TagType.Done;

        const isComplete = isInfoComplete && isQuestionsCompleted && isAmenagementCompleted;

        return isComplete && !room.questionsCopiedFromId;
    };

    //#endregion

    //#region //* OPENING

    public static isOpeningComplete = (item: IOpening, openings: Array<IOpening>): CompleteStatus => {
        const infosCompleted = this.isOpeningInfosComplete(item, openings);
        const questionsComplete = this.isQuestionsComplete(item);
        const isCompleted = Boolean(infosCompleted && questionsComplete);
        return {
            isCompleted,
            completedType: this.getCompletionType(isCompleted, item.findIncompatibilityResult),
            infoCompletedType: this.getCompletionType(infosCompleted, item.findIncompatibilityResult),
            infosCompleted,
            questionsComplete,
        };
    };

    public static isOpeningInfosComplete = (item: IOpening, openings: Array<IOpening>) => {
        let isComplete = Boolean(item.size);
        if (
            openings.filter(
                (o) =>
                    o.openingId !== item.openingId &&
                    o.type === item.type &&
                    CompleteHelper.isAmenagementHasVisibleQuestions(o)
            ).length > 1
        ) {
            isComplete = isComplete && Boolean(item.questionsCopiedFromId);
        }
        return isComplete;
    };

    //#endregion

    //#region //* ROOM_ITEM

    public static isRoomItemComplete = (item: IRoomItem, roomItems: Array<IRoomItem>): CompleteStatus => {
        const infosCompleted = this.isRoomItemInfoComplete(item, roomItems);
        const questionsComplete = this.isQuestionsComplete(item);
        const isCompleted = Boolean(infosCompleted && questionsComplete);
        return {
            isCompleted,
            infosCompleted,
            completedType: this.getCompletionType(isCompleted, item.findIncompatibilityResult),
            infoCompletedType: this.getCompletionType(infosCompleted, item.findIncompatibilityResult),
            questionsComplete,
        };
    };
    public static isRoomItemInfoComplete = (item: IRoomItem, roomItems: Array<IRoomItem>) => {
        let isComplete = Boolean(item.width) && Boolean(item.height);
        if (
            roomItems.filter(
                (ri) =>
                    ri.roomItemId !== item.roomItemId &&
                    ri.type === item.type &&
                    CompleteHelper.isAmenagementHasVisibleQuestions(ri)
            ).length > 1
        ) {
            isComplete = isComplete && Boolean(item.questionsCopiedFromId);
        }
        if ([RoomItemType.Cupboard, RoomItemType.Stairs, RoomItemType.ShowerBathtub].includes(item.type!)) {
            isComplete = Boolean(item.putFlooring);
        }
        if (item.type === RoomItemType.Cupboard && item.putFlooring === YesOrNo.Yes) {
            isComplete = Boolean(item.putPlinthes);
        }
        return isComplete;
    };

    //#endregion

    public static isAmenagementHasVisibleQuestions = (item: IMapItem) => {
        const itemSteps = [item.questionsFinition, item.questionsServices];
        const itemWithQuestions = itemSteps.some((step) => !step?.isEmpty);
        return itemWithQuestions;
    };

    public static isQuestionsComplete = (item: IMapItem) => {
        return (
            item.questionsTva?.endQuestion &&
            item.questionsPose?.endQuestion &&
            item.questionsSupport?.endQuestion &&
            item.questionsPreparation?.endQuestion &&
            item.questionsFinition?.endQuestion &&
            item.questionsServices?.endQuestion
        );
    };
}
