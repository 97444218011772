import React, { CSSProperties } from 'react';

import { useDebugContext } from '../../../../../Web/Context/Debug/DebugContext';
import { useMapContextState } from '../../../Context/MapContext';
import { MapConstants } from '../../../MapConstants';
import { PointUtils } from '../../../Utils/PointUtils';
import { RoomMove } from '../../../Utils/Room/RoomMove';
import { CoordPoint } from '../../../Utils/Types';
import { qSVG } from '../../../Utils/qSVG';
import { LaizeBandItemV3 } from './LaizeBandTrace';

export const LaizeV3Traces = () => {
    const { laizeResults = [] } = useMapContextState();
    const laizeDebug = laizeResults?.map((x) => x.calculator);
    const showTraces = Boolean(useDebugContext().state.floorPlanLaizeTraceEnabled);
    return !showTraces ? null : (
        <LaizeV3TraceList
            bands={laizeDebug?.reduce((acc, curr) => [...acc, ...(curr?.bands_v3 ?? [])], [] as LaizeBandItemV3[])}
        />
    );
};

type LaizeV3TraceListProps = { bands?: Array<LaizeBandItemV3>; roomId?: string };
export const LaizeV3TraceList = ({ bands, roomId }: LaizeV3TraceListProps) => {
    return (
        <React.Fragment>
            {bands
                ?.filter((item) => (roomId ? item.roomId === roomId : true))
                .map((item, index) => (
                    <Trace key={index} {...item} />
                ))}
        </React.Fragment>
    );
};

export const Trace = (item: LaizeBandItemV3) => {
    return (
        <g id={RoomMove.roomTraceBandId(item.roomId!, item.mainText)} className={RoomMove.class(item.roomId!)}>
            {item.displayText && <Description {...item} />}
            <Polygon {...item} />
        </g>
    );
};

const Polygon = ({
    points,
    pointsWithMargins,
    color = 'red',
    strokeWidth = 1,
    type,
    bandId,
    fillColor = 'none',
    dashed = false,
}: LaizeBandItemV3) => {
    const bandPolygonProps: React.SVGAttributes<any> = {
        type: type,
        //@ts-ignore
        bandId: bandId,
        points: (pointsWithMargins ?? points).map((p) => `${p.x},${p.y}`).join(' '),
        stroke: color,
        strokeWidth,
        fill: fillColor,
        opacity: fillColor !== 'none' ? 0.5 : 1,
        strokeDasharray: dashed ? '10,10' : '',
    };
    return <polygon {...bandPolygonProps} />;
};

const Description = ({
    points,
    pointsWithMargins,
    mainText = '',
    color = 'red',
    position = 'top',
    type,
    surface,
    leftAlignText = true,
    displayText = true,
}: LaizeBandItemV3) => {
    const meter = MapConstants.meter;

    const bbox = PointUtils.calculateBoundingBox({ points: pointsWithMargins ?? points })!;
    const textPos = { x: leftAlignText ? bbox.xMin : bbox.xMax - 180, y: bbox.yMin + 15 };
    const width = qSVG.measure({ x: bbox.xMin, y: bbox.yMin }, { x: bbox.xMax, y: bbox.yMin });
    const height = qSVG.measure({ x: bbox.xMin, y: bbox.yMin }, { x: bbox.xMin, y: bbox.yMax });

    let rotate = false;
    position = rotate ? 'bottom' : position;

    const textPositionX = textPos.x + (rotate ? 10 : 0);
    const textPositionY = textPos.y + (position === 'top' ? 0 : height);

    const textWrapperProps: React.SVGAttributes<any> = {
        transform: `translate(${textPositionX},${textPositionY}) ${rotate ? 'rotate(-90)' : ''}`,
    };

    const styled: CSSProperties = { fill: color, fontSize: 16, fontFamily: 'Avenir', textAnchor: 'start' };

    //* WITHOUT SURFACE
    const displayedWidth = Math.round((width * 100) / meter);
    const displayedHeight = Math.round((height * 100) / meter);
    const dimensionsText = `${displayedWidth}cm x ${displayedHeight}cm`;

    //* WITH SURFACE
    const styledSurface: CSSProperties = {
        color: '#000000',
        fontSize: 14,
        fontFamily: 'Avenir',
        textAnchor: 'start',
        fontWeight: 'bold',
    };
    const getSurfaceText = (surface: number) => {
        const surfaceRounded = Math.round(surface / meter / 100);
        const surfaceText = `Surface couverte : ${surfaceRounded}m2`;
        return surfaceText;
    };

    type LabelProps = { label: string; pos: CoordPoint; styled: CSSProperties; id: string; attrs?: any };
    const Label = ({ label, pos, styled, id, attrs = {} }: LabelProps) => {
        return (
            <text id={id} x={pos.x} y={pos.y} style={styled} textAnchor="middle" {...attrs}>
                {label}
            </text>
        );
    };

    return !displayText ? null : (
        <g {...textWrapperProps}>
            <Label
                id={`debugTextTrace-${textPos.x};${textPos.y}`}
                label={mainText}
                pos={{ x: rotate ? 10 : 0, y: 10 }}
                styled={styled}
                attrs={{ type }}
            />
            {!surface && (
                <Label
                    id={`debugTextTrace-${textPos.x};${textPos.y} 2`}
                    label={dimensionsText}
                    pos={{ x: rotate ? 10 : 0, y: 32 }}
                    styled={styled}
                    attrs={{
                        type,
                        'text-content': 'dimension',
                        'band-width': displayedWidth,
                        'band-height': displayedHeight,
                    }}
                />
            )}
            {surface && (
                <Label
                    id={`debugTextTrace-${textPos.x};${textPos.y} 2`}
                    label={getSurfaceText(surface)}
                    pos={{ x: rotate ? 10 : 5, y: 20 }}
                    styled={styledSurface}
                    attrs={{ type }}
                />
            )}
        </g>
    );
};
