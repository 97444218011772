import { YesOrNo } from '../../../../../../../Plugins/FloorPlan/Models/IMapItem';
import { IRoom, RoomLocalisation, RoomType } from '../../../../../../../Plugins/FloorPlan/Models/IRoom';
import { useIsMobile } from '../../../../../../Context/PlatformContext/PlatformContext';
import { Scroller, useScroller } from '../../../../Helpers/Scroller';

type RoomInfoFocus = {
    localisation?: boolean;
    roomType?: boolean;
    roomWall?: boolean;
    roomFloor?: boolean;
    roomExistingFlooring?: boolean;
    roomHeating?: boolean;
    roomHeatingType?: boolean;
    roomExistingFloorType?: boolean;
    produitPrincipal?: boolean;
    questionsCopiedFromId?: boolean;
    flooringDirection?: boolean;
};

export enum RoomInfoIds {
    Localisation = 'Localisation',
    Type = 'Type',
    Wall = 'Wall',
    Floor = 'Floor',
    RevetementAuSol = 'RevetementAuSol',
    FloorType = 'FloorType',
    Heating = 'Heating',
    HeatingType = 'HeatingType',
    StepTva = 'StepTva', //???
    ProduitPrincipal = 'ProduitPrincipal',
    CopyQuestions = 'CopyQuestions',
    FlooringDirection = 'FlooringDirection',
}

export const useRoomInfosFocus = (room: IRoom, roomsToCopy: IRoom[] = []) => {
    const isMobile = useIsMobile();
    const scroller = useScroller();
    const focus: RoomInfoFocus = {};

    const needAnwserCopieQuestion = roomsToCopy.length > 0 && !room.questionsCopiedFromId;

    if (!room.localisation) {
        focus.localisation = true;
    } else if (!room.roomType) {
        focus.roomType = true;
    } else if (!room.roomWall && room.type === RoomType.Wall) {
        focus.roomWall = true;
    } else if (!room.roomFloor) {
        focus.roomFloor = true;
    } else if (!room.roomExistingFlooring) {
        focus.roomExistingFlooring = true;
    } else if (!room.roomExistingFloorType) {
        focus.roomExistingFloorType = true;
    } else if (!room.roomHeating && room.localisation === RoomLocalisation.Inside && room.type === RoomType.Sol) {
        focus.roomHeating = true;
    } else if (
        !room.roomHeatingType &&
        room.localisation === RoomLocalisation.Inside &&
        room.roomHeating === YesOrNo.Yes &&
        room.type === RoomType.Sol
    ) {
        focus.roomHeatingType = true;
    } else if (needAnwserCopieQuestion && room.questionsTva?.endQuestion) {
        focus.questionsCopiedFromId = true;
    } else if (
        room.questionsTva?.endQuestion &&
        !needAnwserCopieQuestion &&
        !room.questionProduitPrincipal?.produitValue
    ) {
        focus.produitPrincipal = true;
    } else if (!room.flooringDirection) {
        focus.flooringDirection = true;
    }

    const scollToId = (divId: string) => {
        setTimeout(() => {
            scroller.scrollToDiv(Scroller.ID, divId, isMobile);
        }, Scroller.ShortDelay);
    };

    return { focus, needAnwserCopieQuestion, scollToId };
};
