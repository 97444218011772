import { CoordPoint } from '../Utils/Types';
import { IMapItem } from './IMapItem';

export enum OpeningType {
    ArmoredDoor = 'armored_doors',
    Door = 'doors',
    Window = 'windows',
    FrenchDoor = 'french_doors',
    FrontDoor = 'front_doors',
    PatioDoor = 'patio_doors',
    Opening = 'opening', //!\ also called WallOpening
}

export function toOpeningKeyFromValue(value: string): string | undefined {
    const entry = Object.entries(OpeningType).find(([key, val]) => val === value);
    return entry ? entry[0] : undefined;
}

export interface IOpening extends IMapItem {
    roomId?: string;
    openingId?: string;

    //* INFOS
    type?: OpeningType;
    size?: number;
    customSize?: boolean;

    //* COORDS
    x?: number;
    y?: number;
    limit?: CoordPoint[];
    angle?: number;

    location_side?: string;
    location_position_from_edge?: number;
    contiguous_between_rooms?: string[];

    //* Used for gestures
    isMoving?: boolean;

    //!\ not utile, so => remove
    // hinge?: string;//! ALWAYS 'normal'
    // position?: CoordPoint;
    // family?: string; //like inWall, stick, collision, free,
    // class?: string; // door, window, energy, stair, measure, text ?
    // classe?: string, // door, window, energy, stair, measure, text ?
    // angleSign?: number;

    // thick?: number;
    // scale?: CoordPoint;
    // value?: number;
    // width?: string;
    // height?: string;

}

export class OpeningTypeUtils {
    public static isDoorSpecific = (type?: OpeningType) => {
        return [OpeningType.Door, OpeningType.FrenchDoor, OpeningType.FrontDoor].includes(type!);
    };
}
