import { FlooringDirection, LaizeProps } from '../Components/Laizes/Laize';
import { MapContextState, MapPanelsState } from '../Context/MapContext';
import { MapItemContants } from '../Models/IMapItem';
import { IOpening } from '../Models/IOpening';
import { IRoom } from '../Models/IRoom';
import { IRoomItem } from '../Models/IRoomItem';
import { Editor } from '../Utils/Editor';
import { OpeningUtils } from '../Utils/Opening/OpeningUtils';
import { RoomDiffUtils } from '../Utils/Room/RoomDiffUtils';
import { CreateRoomFactory } from '../Utils/Room/RoomUtils';
import { RoomItemUtils } from '../Utils/RoomItem/RoomItemUtils';
import { Wall } from '../Utils/Types';
import { WallUtils } from '../Utils/Wall/WallUtils';
import { RoomFactory } from './BaseRoomFactory';
import { CompleteHelper } from './CompleteHelper';
import { MapResult } from './MapResult';

type ImportRoomsParams = {
    oldWalls?: Array<Wall>;
    oldRooms?: Array<IRoom>;
    openings?: Array<IOpening>;
    roomItems?: Array<IRoomItem>;
    importRooms: Array<IRoom>;
};

type LoadMapParams = {
    rooms: Array<IRoom>;
    laizeProps: Array<LaizeProps>;
    flooringDirectionByRoomId?: { [roomId: string]: FlooringDirection };
};

export interface MapError {
    isMapError: boolean;
    error: any;
}

export class MapHelper {
    public static LOAD_MAP_ERROR_MESSAGE = 'LOAD_MAP_ERROR_MESSAGE';

    public static isPanelOpen = (state: MapPanelsState) =>
        Boolean(
            state.selectRoomShapeOpen ||
            state.selectAmenagementOpen ||
            state.selectedRoomId ||
            state.selectedOpeningId ||
            state.selectedRoomItemId ||
            state.showEstimation ||
            state.showHabitation ||
            state.showStoreLocator
        );

    //#region   //* LOAD MAP FROM API + CALC LAIZE RESULT

    public static loadRooms = ({ rooms, laizeProps, flooringDirectionByRoomId }: LoadMapParams): MapContextState => {
        try {
            let updatedWalls: Array<Wall> = [];
            let updatedRooms: Array<IRoom> = [];
            let updatedOpenings: Array<IOpening> = [];
            let updatedRoomItems: Array<IRoomItem> = [];

            rooms.forEach((baseRoom) => {
                const { room, roomWalls = [] } = CreateRoomFactory.createRoom(baseRoom, updatedWalls);
                updatedWalls = [...updatedWalls, ...roomWalls];
                room.walls = roomWalls;

                updatedRooms = [...updatedRooms, room];
                Editor.architect(updatedWalls, updatedRooms);

                //! fix opening position from location_position_from_edge (occurs when duplicate room or import room)
                OpeningUtils.fixPositionOpenings(room, room.openings, updatedWalls);

                updatedOpenings = [...updatedOpenings, ...(room.openings || [])];
                updatedRoomItems = [...updatedRoomItems, ...(room.roomItems || [])];
                WallUtils.refreshWallsMinimumSizeAndOriginalAngle(updatedWalls, updatedOpenings, updatedRooms);

                baseRoom.flooringDirection = flooringDirectionByRoomId?.[baseRoom.roomId!];
            });

            updatedRooms.forEach(
                (item) =>
                (item.questionsCopiedFromId =
                    item.questionsCopiedFromId || MapItemContants.QuestionsCopiedFromId_NONE)
            );
            updatedOpenings.forEach(
                (item) =>
                (item.questionsCopiedFromId =
                    item.questionsCopiedFromId || MapItemContants.QuestionsCopiedFromId_NONE)
            );
            updatedRoomItems.forEach(
                (item) =>
                (item.questionsCopiedFromId =
                    item.questionsCopiedFromId || MapItemContants.QuestionsCopiedFromId_NONE)
            );

            if (!updatedRooms.length) {
                return {};
            } else {
                updatedOpenings = OpeningUtils.resetOpeningsPlacement(updatedOpenings, updatedWalls);
                Editor.architect(updatedWalls, updatedRooms);
                updatedRooms.forEach((room) => {
                    OpeningUtils.updateRoomOpenings(room, updatedOpenings);
                    RoomItemUtils.updateRoomItems(room, updatedRoomItems);
                    RoomDiffUtils.roomComputeDifference(room, updatedRoomItems, updatedWalls);
                });
                const state = MapResult.calculate({
                    rooms: updatedRooms,
                    roomItems: updatedRoomItems,
                    walls: updatedWalls,
                    openings: updatedOpenings,
                    laizeProps,
                    logs: 'called by loadRooms',
                });
                const roomsCompleted = CompleteHelper.allComplete(rooms);
                return { ...state, laizeProps, roomsCompleted, addRoomTutorialShown: true };
            }
        } catch (error) {
            const mapError: MapError = { isMapError: true, error };
            throw mapError;
        }
    };

    //#endregion

    //#region   //*USED ONLY IN LaizeV2DebugDialog

    public static importRooms = ({
        oldRooms = [],
        oldWalls = [],
        openings = [],
        roomItems = [],
        importRooms = [],
    }: ImportRoomsParams) => {
        let updatedWalls = oldWalls;
        let updatedRooms = oldRooms;
        let updatedOpenings = openings;
        let updatedRoomItems = roomItems;

        importRooms.forEach((baseRoom) => {
            baseRoom.name = baseRoom.name || RoomFactory.defaultName(updatedRooms.length);

            const { room, roomWalls = [] } = CreateRoomFactory.createRoom(baseRoom, updatedWalls);
            updatedWalls = [...updatedWalls, ...roomWalls];
            room.walls = roomWalls;

            updatedRooms = [...updatedRooms, room];
            Editor.architect(updatedWalls, updatedRooms);

            //! fix opening position from location_position_from_edge (occurs when duplicate room or import room)
            OpeningUtils.fixPositionOpenings(room, room.openings, updatedWalls);

            updatedOpenings = [...updatedOpenings, ...(room.openings || [])];
            updatedRoomItems = [...updatedRoomItems, ...(room.roomItems || [])];
            WallUtils.refreshWallsMinimumSizeAndOriginalAngle(updatedWalls, updatedOpenings, updatedRooms);
        });

        Editor.architect(updatedWalls, updatedRooms);
        updatedRooms.forEach((room) => {
            OpeningUtils.updateRoomOpenings(room, updatedOpenings);
            RoomItemUtils.updateRoomItems(room, updatedRoomItems);
            RoomDiffUtils.roomComputeDifference(room, updatedRoomItems, updatedWalls);
        });
        WallUtils.refreshWallsMinimumSizeAndOriginalAngle(updatedWalls, updatedOpenings, updatedRooms);

        return {
            walls: updatedWalls,
            rooms: updatedRooms,
            openings: updatedOpenings,
            roomItems: updatedRoomItems,
        };
    };

    //#endregion
}
