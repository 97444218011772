import { styled } from '@material-ui/styles';
import React, { CSSProperties, forwardRef } from 'react';

import { Logger } from '../../../../Errors/Logger';
import { StringKey } from '../../../../Locales/fr';
import { Message } from '../../../../Locales/I18nService';
import { LogLevelType } from '../../../../Services/Analytics/LogLevelType';
import { ThemeRes } from '../../../../Web/Themes/ThemeRes';
import { MapContextState } from '../../Context/MapContext';
import { IOpening } from '../../Models/IOpening';
import { IRoom, RoomType } from '../../Models/IRoom';
import { IRoomItem } from '../../Models/IRoomItem';
import { PointUtils } from '../../Utils/PointUtils';
import { BoundingBox, CoordPoint, Wall } from '../../Utils/Types';
import { RawBoxRoomsRibs } from '../Widgets/BoxWalls/BoxWallRibs';
import { RawBoxWalls } from '../Widgets/BoxWalls/BoxWalls';
import { RawCarpentryItems } from '../Widgets/Openings/BoxCarpentryItems';
import { RawBoxRoomItems } from '../Widgets/RoomItems/BoxRoomItems';
import { RawBoxAreas } from '../Widgets/Rooms/BoxAreas';
import { ExportMapImageHelper } from './Helpers/ExportMapPngHelper';
import { SvgHelpers } from './Helpers/SvgGenerationHelpers';

type ExportMapSvgV3Props = MapContextState & { style?: CSSProperties };

export const useExportMapSvgV3 = ({ style, ...mapState }: ExportMapSvgV3Props = {}) => {
    const svgWrapper = React.useRef<SVGSVGElement>(null);
    const svgWrapperWall = React.useRef<SVGSVGElement>(null);

    const assertHasRooms = (type: RoomType) => {
        return mapState.rooms?.filter((x) => x.type === type).length !== 0;
    };
    const getRef = (type: RoomType) => (type === RoomType.Sol ? svgWrapper : svgWrapperWall);
    const captureSvg = (type: RoomType) => {
        if (!assertHasRooms(type)) {
            return '';
        }
        return SvgHelpers.capture(getRef(type).current?.outerHTML, true);
    };
    const capturePng = (type: RoomType) => {
        if (!assertHasRooms(type)) {
            return Promise.resolve('');
        }
        const svgImage = captureSvg(type);
        if (!svgImage) {
            Logger.logAnalytics({
                message: 'capturePng',
                level: LogLevelType.Warning,
                type: 'NULL_SVG_IMAGE',
                data: {
                    svgWrapper: svgWrapper.current?.outerHTML || '-',
                    document: document.getElementById(`MapSvgV3-${type}`)?.outerHTML || '-',
                    roomLength: mapState.rooms?.length || 0,
                },
            });
            return Promise.resolve('');
        }
        return ExportMapImageHelper.exportMapPng(svgImage);
    };

    return {
        captureSvg,
        capturePng,
        mapView: (type: RoomType) => (
            <ExportMapView
                type={type}
                ref={type === RoomType.Sol ? svgWrapper : svgWrapperWall}
                walls={mapState.walls}
                rooms={mapState.rooms}
                openings={mapState.openings}
                roomItems={mapState.roomItems}
                style={style}
            />
        ),
    };
};

type ExportMapViewProps = {
    walls?: Array<Wall>;
    rooms?: Array<IRoom>;
    roomItems?: Array<IRoomItem>;
    openings?: Array<IOpening>;
    showLegend?: boolean;
    rotationAngle?: number;
    style?: CSSProperties;
    type?: RoomType;
    tagId?: string;
};

export const ExportMapView = forwardRef<SVGSVGElement, ExportMapViewProps>(
    (
        { style, walls = [], rooms = [], openings = [], roomItems = [], showLegend = true, rotationAngle = 0, type },
        ref
    ) => {
        const roomsByType = rooms.filter((r) => r.type === type);
        const roomIdsByType = roomsByType.map((r) => r.roomId!);
        const wallsByType = walls.filter((x) => roomIdsByType.includes(x.roomId!));
        const openingsByType = openings.filter((x) => roomIdsByType.includes(x.roomId!));
        const roomItemsByType = roomItems.filter((x) => roomIdsByType.includes(x.roomId!));

        const points = [
            ...wallsByType.map((x) => x.start),
            ...roomItemsByType.reduce<Array<CoordPoint>>((items, x) => [...items, ...(x.coordsReal || [])], []),
        ];

        const overallBBox = PointUtils.calculateBoundingBox({ points: points })!;
        if (!overallBBox) {
            return null;
        }

        const legendHeight = 50;
        const margin = rotationAngle !== 0 ? 300 : 100;
        const boxMargin = margin / 5;
        const exportWidth = 1080;
        const minWidth = 480;

        const xMin = overallBBox.xMin - boxMargin;
        const yMin = overallBBox.yMin - boxMargin;
        const xMax = overallBBox.xMax + boxMargin;
        const yMax = overallBBox.yMax + boxMargin + legendHeight;
        const height = yMax - yMin + legendHeight;
        const width = Math.max(xMax - xMin, minWidth);

        const shrinkScale = toShrinkScale(width);

        return (
            <Container id={`ExportMapSvgV3-${type}`} style={style}>
                <svg
                    id={`MapSvgV3-${type}`}
                    ref={ref}
                    xmlns="http://www.w3.org/2000/svg" //!\ IMPORTANT FOR EXPORT
                    viewBox={`${xMin} ${yMin} ${width} ${height}`}
                    width={width}
                    height={height}
                    transform={`rotate(${rotationAngle} 0 0)`}>
                    <g id="planGroup">
                        <g id="mapBoxWalls">
                            <RawBoxWalls rooms={roomsByType} readonly />
                        </g>
                        <g id="mapBoxWalls">
                            <RawBoxWalls rooms={roomsByType} readonly />
                        </g>
                        <g id="mapBoxOpenings">
                            <RawCarpentryItems openings={openingsByType} readonly />
                        </g>
                        <g id="mapBoxRoomItems">
                            <RawBoxRoomItems roomItems={roomItemsByType} readonly />
                        </g>
                        <g id="mapBoxArea">
                            <RawBoxAreas rooms={roomsByType} readonly />
                        </g>
                        <g id="mapBoxRibs">
                            <RawBoxRoomsRibs rooms={roomsByType} readonly shrinkScale={shrinkScale} />
                        </g>
                    </g>
                    {showLegend && <Legend overallBBox={overallBBox} margin={margin} type={type} />}
                </svg>
            </Container>
        );
    }
);

const Container = styled('div')({ display: 'none' });

//#region  //* LEGEND

type LegendProps = { overallBBox: BoundingBox; margin: number; type?: RoomType };
const Legend = ({ overallBBox, margin, type }: LegendProps) => {
    return (
        <g id="Legend">
            <LegendText
                id="LegendInfo"
                x={overallBBox.xMin}
                y={overallBBox.yMax + margin}
                fontFamily={ThemeRes.Fonts.fontFamilyNunitoSans}>
                <Message id={`LegendInfoText_${type}` as StringKey} />
            </LegendText>
        </g>
    );
};

const LegendText = styled('text')({ fontSize: 8, fill: '#414447', textAnchor: 'start' });

//#endregion

const toShrinkScale = (width: number) => {
    if (width < 500) {
        return 4;
    }
    if (width < 800) {
        return 2;
    }
    return 1;
};
