import { ILaizeOpening } from '../Components/Laizes/LaizeCalc';
import { MapConstants } from '../MapConstants';
import { IRoom, RoomShape } from '../Models/IRoom';
import { IRoomItem } from '../Models/IRoomItem';
import { isObjectsEquals } from './Funcs';
import { PointUtils } from './PointUtils';
import { qSVG } from './qSVG';
import { CreateRoomFactory } from './Room/RoomUtils';
import { CoordPoint, Equation, Wall, WallLine, WallNode, WallPolygon } from './Types';
import { WallUtils } from './Wall/WallUtils';

export class Editor {
    public static wall = (
        start: CoordPoint,
        end: CoordPoint,
        type: string,
        thick: number,
        roomShape?: RoomShape,
        roomId?: string,
        sideName?: string
    ): Wall => {
        return {
            thick: 0.00000001,
            start,
            end,
            type,
            parent: undefined,
            child: undefined,
            angle: 0,
            originalAngle: 0,
            equations: {},
            coords: [],
            backUp: false,
            roomShape,
            roomId,
            sideName,
            coteManuallySetted: false,
        };
    };

    public static architect = (walls: Array<Wall>, rooms: Array<IRoom>) => {
        this.wallsComputing(walls);
        const wallPolygons = qSVG.polygonize(walls);
        this.setRoomCoordsFromWall(wallPolygons, rooms, walls);
        return true;
    };

    //* Regenere le polygon des murs (graph)
    public static wallsComputing = (walls: Array<Wall>, action?: string) => {
        let previousWall: Wall;
        let previousWallStart: CoordPoint;
        let previousWallEnd: CoordPoint;
        let nextWall: Wall;
        let nextWallStart: CoordPoint;
        let nextWallEnd: CoordPoint;

        for (let vertice = 0; vertice < walls.length; vertice++) {
            const wall = walls[vertice];
            if (wall.parent) {
                previousWall = wall.parent;
                if (isObjectsEquals(wall.parent.start, wall.start)) {
                    previousWallStart = previousWall.end;
                    previousWallEnd = previousWall.start;
                }
                if (isObjectsEquals(wall.parent.end, wall.start)) {
                    previousWallStart = previousWall.start;
                    previousWallEnd = previousWall.end;
                }
            } else {
                const S: Array<WallNode> = this.getWallNode(walls, wall.start, wall);
                //  if (wallInhibation && isObjectsEquals(wall, wallInhibation)) S = false;
                for (let k in S) {
                    const eqInter = this.createEquationFromWall(S[k].wall, S[k].wall.equations.base);
                    let angleInter = 90; // TO PASS TEST
                    if (action === 'move') {
                        //angleInter = qSVG.angleBetweenEquations(eqInter.A, equation2.A); //TODO OLD
                        angleInter = qSVG.angleBetweenEquations(eqInter.A);
                    }
                    if (S[k].type === 'start' && S[k].wall.parent == null && angleInter > 20 && angleInter < 160) {
                        wall.parent = S[k].wall;
                        S[k].wall.parent = wall;
                        previousWall = wall.parent;
                        previousWallStart = previousWall.end;
                        previousWallEnd = previousWall.start;
                    }
                    if (S[k].type === 'end' && S[k].wall.child == null && angleInter > 20 && angleInter < 160) {
                        wall.parent = S[k].wall;
                        S[k].wall.child = wall;
                        previousWall = wall.parent;
                        previousWallStart = previousWall.start;
                        previousWallEnd = previousWall.end;
                    }
                }
            }
            if (wall.child) {
                nextWall = wall.child;
                if (isObjectsEquals(wall.child.end, wall.end)) {
                    nextWallStart = nextWall.end;
                    nextWallEnd = nextWall.start;
                } else {
                    nextWallStart = nextWall.start;
                    nextWallEnd = nextWall.end;
                }
            } else {
                const E: Array<WallNode> = this.getWallNode(walls, wall.end, wall);
                // if (wallInhibation && isObjectsEquals(wall, wallInhibation)) E = false;
                for (let k in E) {
                    const eqInter = this.createEquationFromWall(E[k].wall, E[k].wall.equations.base);
                    let angleInter = 90; // TO PASS TEST
                    if (action === 'move') {
                        // angleInter = qSVG.angleBetweenEquations(eqInter.A, equation2.A); //TODO OLD
                        angleInter = qSVG.angleBetweenEquations(eqInter.A); //TODO FIX ASK @thierry
                    }
                    if (E[k].type === 'end' && E[k].wall.child == null && angleInter > 20 && angleInter < 160) {
                        wall.child = E[k].wall;
                        E[k].wall.child = wall;
                        nextWall = wall.child;
                        nextWallStart = nextWall.end;
                        nextWallEnd = nextWall.start;
                    }
                    if (E[k].type === 'start' && E[k].wall.parent == null && angleInter > 20 && angleInter < 160) {
                        wall.child = E[k].wall;
                        E[k].wall.parent = wall;
                        nextWall = wall.child;
                        nextWallStart = nextWall.start;
                        nextWallEnd = nextWall.end;
                    }
                }
            }
            const angleWall = Math.atan2(wall.end.y - wall.start.y, wall.end.x - wall.start.x);
            wall.angle = angleWall;
            const wallThickX = (wall.thick / 2) * Math.sin(angleWall);
            const wallThickY = (wall.thick / 2) * Math.cos(angleWall);
            const eqWallUp = qSVG.createEquation(
                wall.start.x + wallThickX,
                wall.start.y - wallThickY,
                wall.end.x + wallThickX,
                wall.end.y - wallThickY,
                wall.equations.up
            );
            const eqWallDw = qSVG.createEquation(
                wall.start.x - wallThickX,
                wall.start.y + wallThickY,
                wall.end.x - wallThickX,
                wall.end.y + wallThickY,
                wall.equations.down
            );
            const eqWallBase = qSVG.createEquation(
                wall.start.x,
                wall.start.y,
                wall.end.x,
                wall.end.y,
                wall.equations.base
            );
            wall.equations = { up: eqWallUp, down: eqWallDw, base: eqWallBase };
            let dWay;

            // WALL STARTED
            if (!wall.parent) {
                const eqP = qSVG.perpendicularEquation(eqWallUp, wall.start.x, wall.start.y)!;
                const interUp: any = qSVG.intersectionOfEquations(eqWallUp, eqP!, 'object') as Equation;
                const interDw: any = qSVG.intersectionOfEquations(eqWallDw, eqP!, 'object') as Equation;
                wall.coords = [interUp, interDw];
                dWay = 'M' + interUp.x + ',' + interUp.y + ' L' + interDw.x + ',' + interDw.y + ' ';
            } else {
                const eqP = qSVG.perpendicularEquation(eqWallUp, wall.start.x, wall.start.y)!;
                // var previousWall = wall.parent;
                //   var previousWallStart = previousWall.start;
                //   var previousWallEnd = previousWall.end;
                const anglePreviousWall = Math.atan2(
                    previousWallEnd!.y - previousWallStart!.y,
                    previousWallEnd!.x - previousWallStart!.x
                );
                const previousWallThickX = (previousWall!.thick / 2) * Math.sin(anglePreviousWall);
                const previousWallThickY = (previousWall!.thick / 2) * Math.cos(anglePreviousWall);
                const eqPreviousWallUp = qSVG.createEquation(
                    previousWallStart!.x + previousWallThickX,
                    previousWallStart!.y - previousWallThickY,
                    previousWallEnd!.x + previousWallThickX,
                    previousWallEnd!.y - previousWallThickY,
                    wall.parent.equations.up
                );
                const eqPreviousWallDw = qSVG.createEquation(
                    previousWallStart!.x - previousWallThickX,
                    previousWallStart!.y + previousWallThickY,
                    previousWallEnd!.x - previousWallThickX,
                    previousWallEnd!.y + previousWallThickY,
                    wall.parent.equations.down
                );
                let interUp: any;
                let interDw: any;

                if (Math.abs(anglePreviousWall - angleWall) > 0.09) {
                    interUp = qSVG.intersectionOfEquations(eqWallUp, eqPreviousWallUp, 'object')!;
                    interDw = qSVG.intersectionOfEquations(eqWallDw, eqPreviousWallDw, 'object')!;

                    if (eqWallUp.A === eqPreviousWallUp.A) {
                        interUp = { x: wall.start.x + wallThickX, y: wall.start.y - wallThickY };
                        interDw = { x: wall.start.x - wallThickX, y: wall.start.y + wallThickY };
                    }

                    const miter = qSVG.gap(interUp, { x: previousWallEnd!.x, y: previousWallEnd!.y });
                    if (miter > 1000) {
                        interUp = qSVG.intersectionOfEquations(eqP, eqWallUp, 'object');
                        interDw = qSVG.intersectionOfEquations(eqP, eqWallDw, 'object');
                    }
                }
                if (Math.abs(anglePreviousWall - angleWall) <= 0.09) {
                    interUp = qSVG.intersectionOfEquations(eqP, eqWallUp, 'object');
                    interDw = qSVG.intersectionOfEquations(eqP, eqWallDw, 'object');
                }
                wall.coords = [interUp, interDw];
                dWay = 'M' + interUp.x + ',' + interUp.y + ' L' + interDw.x + ',' + interDw.y + ' ';
            }
            // WALL FINISHED
            if (!wall.child) {
                const eqP = qSVG.perpendicularEquation(eqWallUp, wall.end.x, wall.end.y);
                const interUp: any = qSVG.intersectionOfEquations(eqWallUp, eqP!, 'object');
                const interDw: any = qSVG.intersectionOfEquations(eqWallDw, eqP!, 'object');
                wall.coords.push(interDw, interUp);
                dWay = dWay + 'L' + interDw.x + ',' + interDw.y + ' L' + interUp.x + ',' + interUp.y + ' Z';
            } else {
                const eqP: any = qSVG.perpendicularEquation(eqWallUp, wall.end.x, wall.end.y);
                // var nextWall = wall.child;
                //   var nextWallStart = nextWall.start;
                //   var nextWallEnd = nextWall.end;
                const angleNextWall = Math.atan2(nextWallEnd!.y - nextWallStart!.y, nextWallEnd!.x - nextWallStart!.x);
                const nextWallThickX = (nextWall!.thick / 2) * Math.sin(angleNextWall);
                const nextWallThickY = (nextWall!.thick / 2) * Math.cos(angleNextWall);
                const eqNextWallUp = qSVG.createEquation(
                    nextWallStart!.x + nextWallThickX,
                    nextWallStart!.y - nextWallThickY,
                    nextWallEnd!.x + nextWallThickX,
                    nextWallEnd!.y - nextWallThickY,
                    wall.child.equations.up
                );
                const eqNextWallDw = qSVG.createEquation(
                    nextWallStart!.x - nextWallThickX,
                    nextWallStart!.y + nextWallThickY,
                    nextWallEnd!.x - nextWallThickX,
                    nextWallEnd!.y + nextWallThickY,
                    wall.child.equations.down
                );

                let interUp: any;
                let interDw: any;

                if (Math.abs(angleNextWall - angleWall) > 0.09) {
                    interUp = qSVG.intersectionOfEquations(eqWallUp, eqNextWallUp, 'object');
                    interDw = qSVG.intersectionOfEquations(eqWallDw, eqNextWallDw, 'object');

                    if (eqWallUp.A === eqNextWallUp.A) {
                        interUp = { x: wall.end.x + wallThickX, y: wall.end.y - wallThickY };
                        interDw = { x: wall.end.x - wallThickX, y: wall.end.y + wallThickY };
                    }

                    const miter = qSVG.gap(interUp, { x: nextWallStart!.x, y: nextWallStart!.y });
                    if (miter > 1000) {
                        interUp = qSVG.intersectionOfEquations(eqWallUp, eqP, 'object');
                        interDw = qSVG.intersectionOfEquations(eqWallDw, eqP, 'object');
                    }
                }
                if (Math.abs(angleNextWall - angleWall) <= 0.09) {
                    interUp = qSVG.intersectionOfEquations(eqWallUp, eqP, 'object');
                    interDw = qSVG.intersectionOfEquations(eqWallDw, eqP, 'object');
                }

                wall.coords.push(interDw, interUp);
                dWay = dWay + 'L' + interDw.x + ',' + interDw.y + ' L' + interUp.x + ',' + interUp.y + ' Z';
            }
        }

        //* after update walls in state
    };

    // RETURN OBJECTS ARRAY INDEX OF WALLS [WALL1, WALL2, n...] WALLS WITH THIS NODE, EXCEPT PARAM = OBJECT WALL
    public static getWallNode = (walls: Array<Wall>, coords: CoordPoint, except: Wall): Array<WallNode> => {
        const nodes: Array<WallNode> = [];
        for (let k in walls) {
            if (!isObjectsEquals(walls[k], except!)) {
                if (isObjectsEquals(walls[k].start, coords)) {
                    nodes.push({ wall: walls[k], type: 'start' });
                }
                if (isObjectsEquals(walls[k].end, coords)) {
                    nodes.push({ wall: walls[k], type: 'end' });
                }
            }
        }
        return nodes;
    };

    public static testPointBelongToWall = (wall: WallLine, point: CoordPoint) => {
        return this.testPointBelongToLine([wall.start, wall.end], point);
    };

    public static testPointBelongToLine = (line: Array<CoordPoint>, point: CoordPoint) => {
        const eq = qSVG.createEquation(line[0].x, line[0].y, line[1].x, line[1].y);
        const search = qSVG.nearPointOnEquation(eq, point);
        const tolerance_round_value = MapConstants.tolerance_round_value;
        return (
            search.distance < 1.0 &&
            qSVG.btwn(point.x, line[0].x, line[1].x, false, tolerance_round_value) &&
            qSVG.btwn(point.y, line[0].y, line[1].y, false, tolerance_round_value)
        );
    };

    public static createEquationFromWall = (wall: WallLine, previousEquation?: Equation) => {
        if (!wall) {
            return previousEquation!;
        }
        return qSVG.createEquation(wall.start.x, wall.start.y, wall.end.x, wall.end.y, previousEquation);
    };

    public static setRoomCoordsFromWall = (
        roomsPolygons: Array<WallPolygon>,
        rooms: Array<IRoom>,
        walls: Array<Wall>
    ) => {
        roomsPolygons.forEach((polygon, index) => {
            const room = rooms.find((r) => r.roomId === polygon.roomId);
            if (room) {
                room.area = polygon.area;
                room.inside = polygon.inside;
                room.coords = polygon.coords;
                room.coordsInside = polygon.coordsInside;
                room.walls = walls.filter((x) => x.roomId === room.roomId);
                room.sides = CreateRoomFactory.toRoomSides(room);
                room.sidesObject = Object.fromEntries(room.sides);
            }
        });
    };

    // RETURN OBJDATA INDEX LIST FROM AN WALL
    public static objFromWall = (wall: WallLine, openings: Array<ILaizeOpening>) => {
        const wallOpenings: Array<ILaizeOpening> = [];
        for (let scan = 0; scan < openings.length; scan++) {
            const opening: ILaizeOpening = openings[scan];
            //if (opening.family === 'inWall') {
            if (this.testPointBelongToWall(wall, opening as CoordPoint)) {
                wallOpenings.push(opening);
            }
            // WARNING 0.01 TO NO COUNT OBJECT ON LIMITS OF THE EDGE !!!!!!!!!!!! UGLY CODE( MOUSE PRECISION)
            // TRY WITH ANGLE MAYBE ???
            //}
        }
        return wallOpenings;
    };

    // RETURN OBJLIST WITH ROOM FILTER
    //todo review this: urgent
    public static objFromWallRoom = (wall: WallLine, currentOpenings: Array<ILaizeOpening>) => {
        //return this.objFromWall(wall, currentOpenings).filter((x) => x.graph.parent().attr('class') === 'room-' + wall.roomId);
        return this.objFromWall(wall, currentOpenings).filter((x) => x.roomId === wall.roomId);
    };

    /** Calculates the overlap distance of the two given lines (line = array of two points) */
    public static calcLineOverlapping = (lineA: Array<CoordPoint>, lineB: Array<CoordPoint>) => {
        const lineApointsOnLineB = [];
        for (const point of lineA) {
            if (this.testPointBelongToLine(lineB, point)) {
                lineApointsOnLineB.push(point);
            }
        }

        const lineBpointsOnLineA = [];
        for (const point of lineB) {
            if (this.testPointBelongToLine(lineA, point)) {
                lineBpointsOnLineA.push(point);
            }
        }

        if (lineApointsOnLineB.length === 2) {
            return qSVG.measure(lineA[1], lineA[0]);
        }

        if (lineBpointsOnLineA.length === 2) {
            return qSVG.measure(lineB[0], lineB[1]);
        }

        if (lineApointsOnLineB.length === 1 && lineBpointsOnLineA.length === 1) {
            return qSVG.measure(lineApointsOnLineB[0], lineBpointsOnLineA[0]);
        }
        return 0;
    };

    /**
     * Returns one of the given poly's side as a line (line = array of two points).
     */
    public static getLineFromPoly = (poly: Array<CoordPoint> = [], lineStartPointIndex: number) => {
        return [poly[lineStartPointIndex % (poly.length - 1)], poly[(lineStartPointIndex + 1) % (poly.length - 1)]];
    };

    public static rayCastingRoom = (point: CoordPoint, rooms: Array<IRoom>) => {
        // let x = point.x;
        // let y = point.y;

        let roomGroup = [];
        for (let polygon = 0; polygon < rooms.length; polygon++) {
            const inside = qSVG.rayCasting(point, rooms[polygon].coords);
            if (inside) {
                roomGroup.push(polygon);
            }
        }
        if (roomGroup.length > 0) {
            let bestArea = rooms[roomGroup[0]].area;
            let roomTarget;
            for (let siz = 0; siz < roomGroup.length; siz++) {
                if (rooms[roomGroup[siz]].area! <= bestArea!) {
                    bestArea = rooms[roomGroup[siz]].area;
                    roomTarget = rooms[roomGroup[siz]];
                }
            }
            return roomTarget;
        } else {
            return undefined;
        }
    };

    public static rayCastingRoomItem = (point: CoordPoint, roomItems: Array<IRoomItem>) => {
        let roomItemGroup = [];
        for (let polygon = 0; polygon < roomItems.length; polygon++) {
            const inside = qSVG.rayCasting(point, roomItems[polygon].coordsReal);
            if (inside) {
                roomItemGroup.push(polygon);
            }
        }
        if (roomItemGroup.length > 0) {
            return roomItems[roomItemGroup[0]];
        } else {
            return undefined;
        }
    };

    public static testIfPolygonCrossesDiagonalWall = (poly: Array<CoordPoint>, walls: Array<Wall>) => {
        const diagonalWalls = walls.filter((x) => !WallUtils.isWallHorV(x));
        for (const diagonalWall of diagonalWalls) {
            for (let i = 0; i < poly.length; i++) {
                const side = this.getLineFromPoly(poly, i);
                if (
                    PointUtils.intersect(
                        { p1: diagonalWall.start, p2: diagonalWall.end },
                        { p1: side[0], p2: side[1] }
                    ) !== false
                ) {
                    return true;
                }
            }
        }
        return false;
    };
}
