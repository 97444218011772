import { SetConfigurationDto } from '../Models/Projects/Configuration';
import {
    BaseDeCalculDto,
    OpeningsContextPropertiesDto,
    PrixContextType,
    PrixDto,
    QuestionItem,
    RoomElementsContextPropertiesDto,
} from '../Models/Questions/QuestionDto';
import {
    DetailQuotationProduitDto,
    EvaluationByPlanItemDto,
    GetQuotationDto,
    QuotationEvaluationDto,
    QuotationPrixDto,
    QuotationReplyBaseDto,
    ReponsePrixContextTypeDto,
    ReponsePrixMetierDePoseContextPropertiesDto,
    ReponsePrixOpeningsContextPropertiesDto,
    ReponsePrixRoomContextPropertiesDto,
    ReponsePrixRoomElementsContextPropertiesDto,
    RoomDetailSection,
    SetQuotationAdditionalDetailsDto,
} from '../Models/Quotations/Quotations';
import { MapContextState } from '../Plugins/FloorPlan/Context/MapContext';
import { MapExportContextValue } from '../Plugins/FloorPlan/Context/MapExportContext';
import { IMapItem } from '../Plugins/FloorPlan/Models/IMapItem';
import { IOpening, OpeningType } from '../Plugins/FloorPlan/Models/IOpening';
import { IRoom, RoomType } from '../Plugins/FloorPlan/Models/IRoom';
import { IRoomItem, RoomItemType } from '../Plugins/FloorPlan/Models/IRoomItem';
import { AuthHelper } from '../Services/Authentication/AuthHelper';
import { ConditionHelper } from './ConditionHelper';
import { getNotSupportedError } from './ConfigurationUtils';
import { ObjectUtils } from './ObjectUtils';
import { ProductHelper } from './ProductHelper';
import { EvaluationContext, QuestionContext, QuestionContextHelper } from './QuestionContextHelper';
import { QuestionsHelper } from './QuestionsHelper';
import { RoomHeatingTypeErrorHelper } from './RoomHeatingTypeErrorHelper';
import { QuestionsSyntheses, SynthesesHelperV3 } from './SynthesesHelperV3';

type BaseDeCalculValueComment = { value: number; commentaire?: string };

export interface GetBaseCalculParameters {
    baseDeCalcul: BaseDeCalculDto;
    map?: MapContextState;
    contextType?: PrixContextType;
    item?: IRoom | IRoomItem | IOpening;
}

interface GetGetQuotationDtoParameters {
    map: MapContextState;
    habitationQuestions: Array<QuestionItem>;
    lastGeneralQuestion: QuestionItem;
    configuration: SetConfigurationDto;
    prixList?: Array<PrixDto>;
    exportImageMap: MapExportContextValue;
}

interface GetQuotationAdditionalDetailsParameters {
    exportImageMap: MapExportContextValue;
    map: MapContextState;
    syntheses: QuestionsSyntheses;
}

type FillPrixListParams = {
    prix: PrixDto;
    baseDeCalcul: number | undefined;
    contextType: PrixContextType;
    prixContextProperties:
        | ReponsePrixMetierDePoseContextPropertiesDto
        | ReponsePrixRoomContextPropertiesDto
        | ReponsePrixOpeningsContextPropertiesDto
        | ReponsePrixRoomElementsContextPropertiesDto;
    commentaire?: string;
};

export class QuotationHelperV3 {
    public static getReducedProduitList = (produits?: DetailQuotationProduitDto[]) => {
        return produits?.reduce((acc, cur) => {
            const existingProduit = acc.find((x) => x.code_produit === cur.code_produit && x.pu_ttc === cur.pu_ttc);
            if (existingProduit) {
                existingProduit.total_ttc += cur.total_ttc;
                existingProduit.quantity += cur.quantity;
                existingProduit.eco_contribution += cur.eco_contribution;
            } else {
                acc.push({ ...cur });
            }
            return acc;
        }, [] as DetailQuotationProduitDto[]);
    };

    public static getEcoContributionTotal = (quote?: QuotationReplyBaseDto): number => {
        return (
            this.getEcoContribution(quote?.detail_main_produit_list) +
            this.getEcoContribution(quote?.detail_produits_complementaires.items)
        );
    };

    private static getEcoContribution = (produits?: DetailQuotationProduitDto[]): number => {
        return produits?.reduce((acc, item) => acc + (item.eco_contribution ?? 0) * (item.quantity ?? 0), 0) ?? 0;
    };

    public static async getGetQuotationDto(params: GetGetQuotationDtoParameters) {
        const { habitationQuestions, configuration, map, lastGeneralQuestion, exportImageMap } = params;

        if (RoomHeatingTypeErrorHelper.isRoomHeatingTypeMissing(map.rooms, lastGeneralQuestion?.context?.evaluations)) {
            throw RoomHeatingTypeErrorHelper.createMissingRoomHeatingTypeError();
        }

        const { quotationPrixList: prix_list, quotePrixContextDebugMap } = this.toQuotationPrixList(params);
        const evaluations: QuotationEvaluationDto = this.getQuotationEvaluation(params);
        const additional_details: SetQuotationAdditionalDetailsDto = await this.getQuotationAdditionalDetails({
            exportImageMap,
            map,
            syntheses: SynthesesHelperV3.getSyntheses({ habitationQuestions, map }),
        });

        const bodyRequest: GetQuotationDto = { evaluations, prix_list, configuration, additional_details };

        return { bodyRequest, quotePrixContextDebugMap };
    }

    private static getQuotationEvaluation({
        map: { rooms = [], openings = [], roomItems = [] },
        lastGeneralQuestion,
    }: GetGetQuotationDtoParameters): QuotationEvaluationDto {
        const globalsEvaluations: EvaluationContext = lastGeneralQuestion?.context?.evaluations ?? {};

        const roomElementsEvaluations: EvaluationByPlanItemDto = {};
        const roomsEvaluations: EvaluationByPlanItemDto = {};
        const openingsEvaluations: EvaluationByPlanItemDto = {};

        const addEvaluations = <T extends IMapItem>(
            items: Array<T>,
            getId: (item: T) => string,
            evaluationByPlanItem: EvaluationByPlanItemDto
        ) => {
            items.forEach(
                (item: T) => (evaluationByPlanItem[getId(item)] = item.questionsBaseContext?.evaluations ?? {})
            );
            items.forEach((item: T) => {
                const tryAddEvaluations = (questions: Array<QuestionItem> = []) => {
                    const { lastShownQuestion } = QuestionsHelper.getLastShownQuestion(questions);
                    if (lastShownQuestion) {
                        const itemId = getId(item);
                        const evaluations: EvaluationContext = lastShownQuestion.context?.evaluations ?? {};
                        const existingEvaluations: Record<string, any> | undefined = evaluationByPlanItem[itemId];

                        if (existingEvaluations) {
                            const mergeEvaluations: EvaluationContext = {
                                ...ObjectUtils.clone(existingEvaluations),
                                ...ObjectUtils.clone(evaluations),
                            };
                            evaluationByPlanItem[itemId] = mergeEvaluations;
                        } else {
                            evaluationByPlanItem[itemId] = evaluations;
                        }
                    }
                };
                tryAddEvaluations(item.questionsPose?.questions);
                tryAddEvaluations(item.questionsSupport?.questions);
                tryAddEvaluations(item.questionsPreparation?.questions);
                tryAddEvaluations(item.questionsFinition?.questions);
                tryAddEvaluations(item.questionsServices?.questions);
            });
        };

        addEvaluations<IRoom>(rooms, (item) => item.roomId!, roomsEvaluations);
        addEvaluations<IOpening>(openings, (item) => item.openingId!, openingsEvaluations);
        addEvaluations<IRoomItem>(roomItems, (item) => item.roomItemId!, roomElementsEvaluations);

        const evaluations: QuotationEvaluationDto = {
            globals: globalsEvaluations,
            room_elements: roomElementsEvaluations,
            rooms: roomsEvaluations,
            openings: openingsEvaluations,
        };
        return evaluations;
    }

    private static async getQuotationAdditionalDetails({
        exportImageMap,
        map,
        syntheses,
    }: GetQuotationAdditionalDetailsParameters): Promise<SetQuotationAdditionalDetailsDto> {
        const { rooms = [], openings = [], roomItems = [] } = map;
        const roomDetailList = await Promise.all<RoomDetailSection>(
            rooms.map(async (room) => {
                const plan_png_base64 = await exportImageMap
                    .exportBandItems()
                    .find((x) => x.room.roomId === room.roomId)
                    ?.capturePng();

                const roomDetailSection: RoomDetailSection = {
                    id: room.roomId!,
                    formatted_syntheses:
                        syntheses.synthesesByRoom.find((x) => x.item?.roomId === room.roomId)?.formattedSyntheses ?? [],
                    plan_png_base64,
                    properties: {
                        name: room.name,
                        type: room.type!,
                        existing_flooring: room.roomExistingFlooring,
                        existing_floor_type: room.roomExistingFloorType,
                        floor: room.roomFloor,
                        heating_type: room.roomHeating,
                        contournements_numbers: room.contournements || {},
                        //contournements_numbers: room.contournements?.ToContournementsNumbersDefaultValue(),
                    },
                };
                return roomDetailSection;
            })
        );

        return {
            general: {
                plan_png_base64: await exportImageMap.capturePng(RoomType.Sol),
                plan_walls_png_base64: await exportImageMap.capturePng(RoomType.Wall),
                formatted_syntheses: syntheses.synthesesGeneral,
            },
            rooms: roomDetailList,
            openings: openings.map((opening) => ({
                id: opening.openingId!,
                formatted_syntheses:
                    syntheses.synthesesByOpening.find((x) => x.item?.openingId === opening.openingId)
                        ?.formattedSyntheses ?? [],
                properties: {
                    name: opening.name,
                    type: opening.type,
                    size: opening.size,
                    room_id: opening.roomId,
                },
            })),
            room_elements: roomItems.map((roomItem) => ({
                id: roomItem.roomItemId!,
                formatted_syntheses:
                    syntheses.synthesesByRoomElement.find((x) => x.item?.roomItemId === roomItem.roomItemId)
                        ?.formattedSyntheses ?? [],
                properties: {
                    name: roomItem.name,
                    type: roomItem.type,
                    room_id: roomItem.roomId,
                },
            })),
        };
    }

    //#region //* PRICES

    private static toQuotationPrixDto({
        prix,
        baseDeCalcul,
        contextType,
        commentaire,
        prixContextProperties,
    }: FillPrixListParams): QuotationPrixDto {
        const quotationPrix: QuotationPrixDto = {
            prix_id: prix.id,
            base_calcul: baseDeCalcul,
            context_type: this.toPrixContextTypeDto(contextType),
            commentaire: commentaire,
        };
        if (contextType === PrixContextType.MetierDePose) {
            quotationPrix.metier_de_pose_context_properties =
                prixContextProperties as ReponsePrixMetierDePoseContextPropertiesDto;
        }
        if (contextType === PrixContextType.Rooms || contextType === PrixContextType.Walls) {
            quotationPrix.rooms_context_properties = prixContextProperties as ReponsePrixRoomContextPropertiesDto;
        }
        if (contextType === PrixContextType.Openings) {
            quotationPrix.openings_context_properties =
                prixContextProperties as ReponsePrixOpeningsContextPropertiesDto;
        }
        if (contextType === PrixContextType.RoomElements) {
            quotationPrix.room_elements_context_properties =
                prixContextProperties as ReponsePrixRoomElementsContextPropertiesDto;
        }
        return quotationPrix;
    }

    private static getBaseCalcul = (
        baseCalculParameters: GetBaseCalculParameters
    ): Array<BaseDeCalculValueComment> | BaseDeCalculValueComment | undefined => {
        const { contextType, baseDeCalcul, map, item } = baseCalculParameters;

        const toBaseDeCalcul = (value?: any) => {
            return value ? parseFloat(value) : 0;
        };

        if (baseDeCalcul === BaseDeCalculDto.NoBase) {
            //* do nothing, handled by api
        }

        //#region //* OPENING
        if (contextType === PrixContextType.Openings) {
            const opening = item as IOpening;
            if (baseDeCalcul === BaseDeCalculDto.OpeningDimensionCentimetre) {
                return { value: toBaseDeCalcul(opening?.size) };
            }
        }
        //#endregion

        //#region //* WALL
        if (contextType === PrixContextType.Walls) {
            const room = item as IRoom;
            if (baseDeCalcul === BaseDeCalculDto.RoomLongueurTotaleLaizeMetre) {
                return map?.laizeResults
                    ?.find((x) => x.roomIds?.includes(room.roomId!))
                    ?.laizes?.filter((x) => !x.leftOverUsed && x.roomId === room.roomId!)
                    ?.map((x) => ({ value: x.length, commentaire: `Bande ${x.id}` }));
            }

            if (baseDeCalcul === BaseDeCalculDto.LongueurRaccordMetre) {
                return { value: toBaseDeCalcul(room.raccord_length) };
            }
            if (baseDeCalcul === BaseDeCalculDto.RoomPerimeterMetre) {
                return { value: toBaseDeCalcul(room.perimeter) };
            }
            if (baseDeCalcul === BaseDeCalculDto.RoomSurfaceMetreCarre) {
                return { value: toBaseDeCalcul(room.surface_area_without_room_item) };
            }
            if (baseDeCalcul === BaseDeCalculDto.RoomSurfaceManipuleeProrataMetreCarre) {
                return { value: toBaseDeCalcul(room.surface_manipulee_prorata) };
            }
            //* CONTOURNEMENTS
            if (baseDeCalcul === BaseDeCalculDto.WallPipeCount) {
                return { value: toBaseDeCalcul(room.contournements?.WallPipe) };
            }
            if (baseDeCalcul === BaseDeCalculDto.WallPipeHoleCount) {
                return { value: toBaseDeCalcul(room.contournements?.WallPipeHole) };
            }
            if (baseDeCalcul === BaseDeCalculDto.ElectricEquipmentCount) {
                return { value: toBaseDeCalcul(room.contournements?.ElectricEquipment) };
            }
            if (baseDeCalcul === BaseDeCalculDto.WallPillarCount) {
                return { value: toBaseDeCalcul(room.contournements?.WallPillar) };
            }
            if (baseDeCalcul === BaseDeCalculDto.HotspotCount) {
                return { value: toBaseDeCalcul(room.contournements?.Hotspot) };
            }
        }
        //#endregion

        //#region //* ROOM
        if (contextType === PrixContextType.Rooms) {
            const room = item as IRoom;

            if (baseDeCalcul === BaseDeCalculDto.RoomLongueurTotaleLaizeMetre) {
                return map?.laizeResults
                    ?.find((x) => x.roomIds?.includes(room.roomId!))
                    ?.laizes?.filter((x) => !x.leftOverUsed && x.roomId === room.roomId!)
                    ?.map((x) => ({ value: x.length, commentaire: `Bande ${x.id}` }));
            }
            if (baseDeCalcul === BaseDeCalculDto.RoomLamePleineQuantity) {
                return {
                    value:
                        map?.laizeResults
                            ?.find((x) => x.roomIds?.includes(room.roomId!))
                            ?.laizes?.filter((x) => !x.leftOverUsed && x.roomId === room.roomId!)?.length ?? 0,
                };
            }
            if (baseDeCalcul === BaseDeCalculDto.LongueurRaccordMetre) {
                return { value: toBaseDeCalcul(room.raccord_length) };
            }
            if (baseDeCalcul === BaseDeCalculDto.RoomPerimeterMetre) {
                return { value: toBaseDeCalcul(room.perimeter) };
            }
            if (baseDeCalcul === BaseDeCalculDto.RoomPerimeterPlinthesMetre) {
                return { value: toBaseDeCalcul(room.perimeter_plinthes) };
            }
            if (baseDeCalcul === BaseDeCalculDto.RoomSurfaceMetreCarre) {
                return { value: toBaseDeCalcul(room.surface_area_without_room_item) };
            }
            if (baseDeCalcul === BaseDeCalculDto.RoomSurfaceManipuleeProrataMetreCarre) {
                return { value: toBaseDeCalcul(room.surface_manipulee_prorata) };
            }
            //* CONTOURNEMENTS
            if (baseDeCalcul === BaseDeCalculDto.RoomPipeOnFloorCount) {
                return { value: toBaseDeCalcul(room.contournements?.Pipe) };
            }
            if (baseDeCalcul === BaseDeCalculDto.RoomThingsOnAStandCount) {
                return { value: toBaseDeCalcul(room.contournements?.MountedElement) };
            }
            if (baseDeCalcul === BaseDeCalculDto.RoomRadiatorCount) {
                return { value: toBaseDeCalcul(room.contournements?.MountedRadiator) };
            }
            if (baseDeCalcul === BaseDeCalculDto.RoomObstacleCount) {
                return { value: toBaseDeCalcul(room.contournements?.Pillar) };
            }
            if (baseDeCalcul === BaseDeCalculDto.RoomStairsCount) {
                return { value: toBaseDeCalcul(room.contournements?.Stairs) };
            }
            if (baseDeCalcul === BaseDeCalculDto.RoomStoneWallMetre) {
                return { value: toBaseDeCalcul(room.contournements?.Stonewall) };
            }
            if (baseDeCalcul === BaseDeCalculDto.RoomRailingCount) {
                return { value: toBaseDeCalcul(room.contournements?.Railing) };
            }
            if (baseDeCalcul === BaseDeCalculDto.RoomTreeCount) {
                return { value: toBaseDeCalcul(room.contournements?.Tree) };
            }
        }
        //#endregion

        //#region //* ROOM ELEMENT
        if (contextType === PrixContextType.RoomElements) {
            const roomItem = item as IRoomItem;

            if (baseDeCalcul === BaseDeCalculDto.RoomElementsArea) {
                return { value: toBaseDeCalcul(roomItem?.surface) };
            }
            if (baseDeCalcul === BaseDeCalculDto.RoomElementsMetreLineaire) {
                return { value: toBaseDeCalcul(roomItem?.room_elements_length) };
            }
            if (baseDeCalcul === BaseDeCalculDto.MarcheLengthMetreLineaire) {
                const value =
                    (Math.min(roomItem?.horizontal_size!, roomItem?.vertical_size!) * 2 +
                        Math.max(roomItem?.horizontal_size!, roomItem?.vertical_size!)) /
                    100;

                return { value: toBaseDeCalcul(value) };
            }
        }
        //#endregion

        return undefined;
    };

    public static toQuotationPrixList(params: GetGetQuotationDtoParameters) {
        const { map, prixList = [] } = params;
        const quotationPrixList: QuotationPrixDto[] = [];

        type AddPrixParameters<T extends IMapItem> = {
            context: QuestionContext;
            prixItems: Array<PrixDto>;
            contextType: PrixContextType;
            item?: T;
            openingType?: OpeningType;
            roomItemType?: RoomItemType;
            questionProduitPrincipal?: QuestionItem;
            prixContextProperties:
                | ReponsePrixMetierDePoseContextPropertiesDto
                | ReponsePrixRoomContextPropertiesDto
                | ReponsePrixOpeningsContextPropertiesDto
                | ReponsePrixRoomElementsContextPropertiesDto;
        };

        const addPrices = <T extends IMapItem>({
            context,
            item,
            prixItems,
            contextType,
            openingType,
            roomItemType,
            questionProduitPrincipal,
            prixContextProperties,
        }: AddPrixParameters<T>) => {
            const isPrixInContext = (prix: PrixDto): boolean => {
                const isPrixWithContext = (): boolean => prix.context_type === contextType;

                let prixIsInContext: boolean = false;
                if (prix.openings_context_properties) {
                    const openingsContext: OpeningsContextPropertiesDto = prix.openings_context_properties;
                    if (contextType === PrixContextType.Openings && openingType) {
                        if (openingType === OpeningType.Door) {
                            prixIsInContext = isPrixWithContext() && openingsContext.is_for_porte;
                        }
                        if (openingType === OpeningType.ArmoredDoor) {
                            prixIsInContext = isPrixWithContext() && openingsContext.is_for_porte_blindee;
                        }
                        if (openingType === OpeningType.FrontDoor) {
                            prixIsInContext = isPrixWithContext() && openingsContext.is_for_porte_entree;
                        }
                        if (openingType === OpeningType.FrenchDoor) {
                            prixIsInContext = isPrixWithContext() && openingsContext.is_for_porte_fenetre;
                        }
                        if (openingType === OpeningType.Opening) {
                            prixIsInContext = isPrixWithContext() && openingsContext.is_for_passage_ouvert;
                        }
                        if (openingType === OpeningType.PatioDoor) {
                            prixIsInContext = isPrixWithContext() && openingsContext.is_for_baie_vitree;
                        }
                    }
                } else if (prix.room_elements_context_properties) {
                    const roomElementsContext: RoomElementsContextPropertiesDto = prix.room_elements_context_properties;
                    if (contextType === PrixContextType.RoomElements && roomItemType) {
                        if (roomItemType === RoomItemType.ShowerBathtub) {
                            prixIsInContext = isPrixWithContext() && roomElementsContext.is_for_shower_bathtub;
                        }
                        if (roomItemType === RoomItemType.FixedFurniture) {
                            prixIsInContext = isPrixWithContext() && roomElementsContext.is_for_fixed_furniture;
                        }
                        if (roomItemType === RoomItemType.Cupboard) {
                            prixIsInContext = isPrixWithContext() && roomElementsContext.is_for_cupboard;
                        }
                        if (roomItemType === RoomItemType.KitchenFurniture) {
                            prixIsInContext = isPrixWithContext() && roomElementsContext.is_for_kitchen_furniture;
                        }
                        if (roomItemType === RoomItemType.Trap) {
                            prixIsInContext = isPrixWithContext() && roomElementsContext.is_for_trap;
                        }
                        if (roomItemType === RoomItemType.Fireplace) {
                            prixIsInContext = isPrixWithContext() && roomElementsContext.is_for_fireplace;
                        }
                        if (roomItemType === RoomItemType.Opening) {
                            prixIsInContext = isPrixWithContext() && roomElementsContext.is_for_opening;
                        }
                        if (roomItemType === RoomItemType.Stairs) {
                            prixIsInContext = isPrixWithContext() && roomElementsContext.is_for_stairs;
                        }
                        if (roomItemType === RoomItemType.Divider) {
                            prixIsInContext = isPrixWithContext() && roomElementsContext.is_for_divider;
                        }
                        if (roomItemType === RoomItemType.HeavyElement) {
                            prixIsInContext = isPrixWithContext() && roomElementsContext.is_for_heavy_element;
                        }
                        if (roomItemType === RoomItemType.SewerPlate) {
                            prixIsInContext = isPrixWithContext() && roomElementsContext.is_for_sewer_plate;
                        }
                        if (roomItemType === RoomItemType.Door) {
                            prixIsInContext = isPrixWithContext() && roomElementsContext.is_for_door;
                        }
                        if (roomItemType === RoomItemType.SewerPlate) {
                            prixIsInContext = isPrixWithContext() && roomElementsContext.is_for_window;
                        }
                        if (roomItemType === RoomItemType.SewerPlate) {
                            prixIsInContext = isPrixWithContext() && roomElementsContext.is_for_unlaid_area;
                        }
                    }
                } else {
                    prixIsInContext = isPrixWithContext();
                }
                return prixIsInContext;
            };

            const isPrixPrevisible = (prix: PrixDto): boolean => {
                return QuestionsHelper.isConditionVisible({
                    context,
                    condition: prix.pre_visibility_condition,
                    familleProduitCodeList: prix.famille_produit_code_list,
                    produitPrincipalCompleteCode: ProductHelper.getCompleteCode(questionProduitPrincipal?.produitValue),
                });
            };

            prixItems.forEach((prix: PrixDto) => {
                const prixInContext = isPrixInContext(prix);
                const prixIsPrevisible = isPrixPrevisible(prix);
                const isConditionEvaluateToTrue = ConditionHelper.isConditionEvaluateToTrue({
                    condition: prix.visibility_condition,
                    rawContext: context,
                });

                if (prixInContext && prixIsPrevisible && isConditionEvaluateToTrue) {
                    const baseDeCalcul: Array<BaseDeCalculValueComment> | BaseDeCalculValueComment | undefined =
                        this.getBaseCalcul({ contextType, baseDeCalcul: prix.base_de_calcul_type, map, item });
                    //@ts-ignore
                    if (typeof baseDeCalcul?.value === 'number' || typeof baseDeCalcul === 'undefined') {
                        const baseDeCalculNotArray = baseDeCalcul as BaseDeCalculValueComment | undefined;
                        quotationPrixList.push(
                            this.toQuotationPrixDto({
                                prix,
                                baseDeCalcul: baseDeCalculNotArray?.value,
                                contextType: prix.context_type,
                                prixContextProperties,
                                commentaire: baseDeCalculNotArray?.commentaire,
                            })
                        );
                    } else {
                        const baseDeCalculArray = baseDeCalcul as Array<BaseDeCalculValueComment>;
                        for (const detailedBaseCalcul of baseDeCalculArray) {
                            quotationPrixList.push(
                                this.toQuotationPrixDto({
                                    prix,
                                    baseDeCalcul: detailedBaseCalcul?.value,
                                    contextType: prix.context_type,
                                    prixContextProperties,
                                    commentaire: detailedBaseCalcul?.commentaire,
                                })
                            );
                        }
                    }
                }
            });
        };

        const isSeller = AuthHelper.isAuthenticatedAsStoreUser();
        const prixListFiltered = prixList.filter((p) => (isSeller ? true : !p.show_only_to_sellers));

        const generalPrixList = prixListFiltered.filter((p) => p.context_type === PrixContextType.MetierDePose);
        const roomPrixList = prixListFiltered.filter((p) => p.context_type === PrixContextType.Rooms);
        const wallPrixList = prixListFiltered.filter((p) => p.context_type === PrixContextType.Walls);
        const roomElementsPrixList = prixListFiltered.filter((p) => p.context_type === PrixContextType.RoomElements);
        const openingsPrixList = prixListFiltered.filter((p) => p.context_type === PrixContextType.Openings);

        const { rooms = [], roomItems = [], openings = [] } = map;

        const metierDePoseListContext = this.getMetierDePoseListContext(params);

        const quotePrixContextDebugMap: PrixContextDebugMap = {
            metierDePoses: {},
            rooms: {},
            openings: {},
            roomItems: {},
        };

        metierDePoseListContext.forEach(({ metierDePose, metierDePoseContext }) => {
            quotePrixContextDebugMap.metierDePoses[metierDePose] = metierDePoseContext;
            addPrices({
                prixItems: generalPrixList,
                context: metierDePoseContext,
                contextType: PrixContextType.MetierDePose,
                prixContextProperties: { metier_de_pose: metierDePose },
            });
        });

        rooms
            .filter((x) => x.type === RoomType.Sol)
            .forEach((room) => {
                const roomPrixContext = this.getContext(room, room.questionsBaseContext);
                quotePrixContextDebugMap.rooms[room.roomId!] = roomPrixContext;
                addPrices({
                    prixItems: roomPrixList,
                    item: room,
                    context: roomPrixContext,
                    contextType: PrixContextType.Rooms,
                    questionProduitPrincipal: room.questionProduitPrincipal,
                    prixContextProperties: { room_id: room.roomId! },
                });
            });
        rooms
            .filter((x) => x.type === RoomType.Wall)
            .forEach((room) => {
                const roomPrixContext = this.getContext(room, room.questionsBaseContext);
                quotePrixContextDebugMap.rooms[room.roomId!] = roomPrixContext;
                addPrices({
                    prixItems: wallPrixList,
                    item: room,
                    context: roomPrixContext,
                    contextType: PrixContextType.Walls,
                    questionProduitPrincipal: room.questionProduitPrincipal,
                    prixContextProperties: { room_id: room.roomId! },
                });
            });

        roomItems.forEach((roomItem) => {
            const room = rooms.find((item) => item.roomId === roomItem.roomId)!;
            const roomItemPrixContext = this.getContext(roomItem, roomItem.questionsBaseContext);
            quotePrixContextDebugMap.rooms[roomItem.roomItemId!] = roomItemPrixContext;
            addPrices({
                prixItems: roomElementsPrixList,
                item: roomItem,
                context: roomItemPrixContext,
                contextType: PrixContextType.RoomElements,
                roomItemType: roomItem.type,
                questionProduitPrincipal: room.questionProduitPrincipal,
                prixContextProperties: { room_element_id: roomItem.roomItemId!, room_id: room.roomId! },
            });
        });
        openings.forEach((opening) => {
            const room = rooms.find((item) => item.roomId === opening.roomId)!;
            const openingPrixContext = this.getContext(opening, opening.questionsBaseContext);
            quotePrixContextDebugMap.rooms[opening.openingId!] = openingPrixContext;
            addPrices({
                prixItems: openingsPrixList,
                item: opening,
                context: openingPrixContext,
                contextType: PrixContextType.Openings,
                openingType: opening.type,
                questionProduitPrincipal: room.questionProduitPrincipal,
                prixContextProperties: { opening_id: opening.openingId!, room_id: room.roomId! },
            });
        });

        return { quotationPrixList, quotePrixContextDebugMap };
    }

    public static getContext = (item: IMapItem, defaultContext: QuestionContext = {}) => {
        const questions = [
            ...item.questionsPose?.questions! || [],
            ...item.questionsSupport?.questions! || [],
            ...item.questionsPreparation?.questions! || [],
            ...item.questionsFinition?.questions! || [],
            ...item.questionsServices?.questions! || [],
        ];
        return QuestionsHelper.getLastShownQuestion(questions).lastShownQuestion?.context || defaultContext;
    };

    public static getMetierDePoseListContext = ({ map, lastGeneralQuestion }: GetGetQuotationDtoParameters) => {
        const { rooms = [] } = map;
        const metierDePoseList = Array.from(
            new Set(
                rooms.map((r) => ProductHelper.toMetierDePose(r.questionProduitPrincipal?.produitValue)).filter(Boolean)
            )
        );

        const generalContext = QuestionContextHelper.mergeContext(lastGeneralQuestion?.context);

        const metierDePoseListContext: MetierDePoseWithContext[] = metierDePoseList.map((metierDePose) => {
            //* Un tableau des contextes de chacune des pièces reliées à ce métier de pose
            const roomsContext = rooms
                .filter((r) => ProductHelper.toMetierDePose(r.questionProduitPrincipal?.produitValue) === metierDePose)
                .map((room) => {
                    const roomContext = this.getContext(room, lastGeneralQuestion?.context);

                    //! UNLOCK LATER #36373 : Quand on aura baisser la liste des attributs
                    //* Dans chaque pièce, un tableau des contextes des ouvertures de la pièce
                    // roomContext.openings = room.openings?.map(item => this.getContext(item, lastGeneralQuestion?.context));
                    //* Dans chaque pièce, un tableau des contextes des autres éléments de la pièce
                    //roomContext.room_items = room.roomItems?.map(item => this.getContext(item, lastGeneralQuestion?.context));
                    return roomContext;
                });
            return {
                metierDePose,
                //* Le contexte général
                metierDePoseContext: { ...ObjectUtils.clone(generalContext), rooms: roomsContext },
            };
        });

        return metierDePoseListContext;
    };

    public static toPrixContextTypeDto(contextType: PrixContextType): ReponsePrixContextTypeDto {
        switch (contextType) {
            case PrixContextType.MetierDePose:
                return ReponsePrixContextTypeDto.MetierDePose;
            case PrixContextType.Rooms:
                return ReponsePrixContextTypeDto.Rooms;
            case PrixContextType.Walls:
                return ReponsePrixContextTypeDto.Walls;
            case PrixContextType.Openings:
                return ReponsePrixContextTypeDto.Openings;
            case PrixContextType.RoomElements:
                return ReponsePrixContextTypeDto.RoomElements;
            default:
                throw getNotSupportedError(contextType);
        }
    }

    //#endregion
}

type MetierDePoseWithContext = { metierDePose: string; metierDePoseContext: QuestionContext };

export type PrixContextDebugMap = {
    metierDePoses: Record<string, QuestionContext>;
    rooms: Record<string, QuestionContext>;
    openings: Record<string, QuestionContext>;
    roomItems: Record<string, QuestionContext>;
};
