import { Button, Typography } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import React, { CSSProperties } from 'react';
import { Link } from 'react-router-dom';
import { GestureEvent } from '../../../../Events/withEvent';

import { ThemeRes } from '../../../Themes/ThemeRes';
import { CustomLoader } from '../../Templates/CustomLoader';

export enum ButtonVariant {
    Danger = 'Danger',
    Light = 'Light',
    Blue = 'Blue',
    Start = 'Start',
    BorderedBlue = 'BorderedBlue',
    BorderedGrey = 'BorderedGrey',
}

export interface CustomButtonProps {
    id?: string;
    icon?: React.ReactNode;
    endRowIcon?: React.ReactElement;
    title?: React.ReactNode;
    variant?: ButtonVariant | string;
    onClick?: (event?: GestureEvent) => void;
    disabled?: boolean;
    type?: 'submit' | 'reset' | 'button';
    isBusy?: boolean;
    link?: string;
    visible?: boolean;
    fullWidth?: boolean;
    classes?: { root?: string; label?: string; content?: string; icon?: string };
    styles?: {
        root?: CSSProperties;
        label?: CSSProperties;
        content?: CSSProperties;
        icon?: CSSProperties;
        iconEnd?: CSSProperties;
    };
    ref?: any;
}

export const CustomButton = ({
    title = '',
    icon,
    endRowIcon,
    variant,
    onClick,
    disabled = false,
    type,
    isBusy,
    link,
    visible = true,
    styles,
    classes,
    ref,
    ...props
}: CustomButtonProps) => {
    if (!visible) {
        return null;
    }

    const getContent = (): React.ReactNode => {
        const startIconStyle: CSSProperties = { marginRight: title ? 16 : 0, ...styles?.icon };
        return (
            <Content style={styles?.content}>
                {icon && <StartIcon style={startIconStyle}>{icon}</StartIcon>}
                {title && <Title style={styles?.label}>{title}</Title>}
                {endRowIcon && <EndIcon style={styles?.iconEnd}>{endRowIcon}</EndIcon>}
            </Content>
        );
    };

    return (
        <ButtonBase
            classes={{ root: classes?.root }}
            ref={ref}
            onClick={onClick}
            disabled={disabled || isBusy}
            type={type}
            style={{
                ...withStyle({ disabled, variant }),
                ...styles?.root,
            }}
            {...props}>
            {link ? <LinkContainer to={link}>{getContent()}</LinkContainer> : getContent()}
            <BusyView variant={variant} busy={isBusy} />
        </ButtonBase>
    );
};

const ButtonBase = styled(Button)({
    '@media (hover)': {
        '&:hover': { backgroundColor: '#E5A033 !important' },
        '&:hover .MuiTypography-root': {
            color: '#FFFFFF !important',
            //transition: 'color 0.3s',
        },
    },
});

const Content = styled('div')({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    padding: '0px 24px 0px 24px', //* TRBL
    fontFamily: 'Avenir',
    fontSize: 16,
    fontWeight: 900,
    textAlign: 'center',
});
const StartIcon = styled('div')({ marginRight: 16, display: 'flex', alignItems: 'center' });
const EndIcon = styled('div')({ marginLeft: 10, display: 'flex', alignItems: 'center' });

const LinkContainer = styled(Link)({ width: '100%', height: '100', ...ThemeRes.LinkButtonStyle });

const Title = styled(Typography)({
    fontFamily: 'NunitoSans',
    fontWeight: 700,
    fontSize: 18,
    lineHeight: '20px',
});

type WithStyleParams = { disabled?: boolean; variant?: ButtonVariant | string; fullWidth?: boolean };
const withStyle = ({ disabled, variant, fullWidth }: WithStyleParams): CSSProperties => {
    let style: CSSProperties = {
        minHeight: 50,
        opacity: disabled ? 0.6 : 1,
        borderRadius: 0,
        background: '#ec9e30',
        padding: 0,
        color: '#FFFFFF',
        ...withButtonVariantStyle(variant),
    };
    if (fullWidth) {
        style.width = '100%';
    }

    return style;
};

const withButtonVariantStyle = (variant?: string, style: CSSProperties = {}) => {
    if (variant === ButtonVariant.Danger) {
        style.background = '#d62341';
        style.color = '#FFFFFF';
    }
    if (variant === ButtonVariant.Light) {
        style.background = '#f4f4f4';
        style.color = '#7f7f7f';
    }
    if (variant === ButtonVariant.BorderedGrey) {
        style.border = 'solid 1.4px #ec9e30';
        style.background = '#ffffff';
        style.color = '#ec9e30';
    }
    if (variant === ButtonVariant.Blue) {
        style.background = '#1c1a37';
        style.color = '#ffffff';
    }
    if (variant === ButtonVariant.Start) {
        style.background = '#ec9e30';
        style.color = '#ffffff';
    }
    if (variant === ButtonVariant.BorderedBlue) {
        style.border = 'solid 1.4px #1c1a37';
        style.background = '#FFFFFF';
        style.color = '#1c1a37';
    }
    return style;
};

type BusyViewProps = { variant?: string; busy?: boolean };
const BusyView = ({ busy, variant }: BusyViewProps) => {
    const Container = styled('div')({
        position: 'absolute',
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        lineHeight: 0,
    });
    const style = withButtonVariantStyle(variant);

    return !busy ? null : (
        <Container style={style}>
            <CustomLoader darkColor={variant !== ButtonVariant.Blue} />
        </Container>
    );
};
