import React from 'react';
import { FindFirstIncompatibilityResult, QuestionsHelper } from '../../../Helpers/QuestionsHelper';
import { useWindowLog } from '../../../Hooks/WindowLog';
import { I18n } from '../../../Locales/I18nService';
import { IncompatibilityTypeDto, QuestionItem } from '../../../Models/Questions/QuestionDto';
import { useIsMobile } from '../../../Web/Context/PlatformContext/PlatformContext';
import { useViewState } from '../../../Web/Hooks/ViewState/ViewState';
import { YesNoChoiceDialog } from '../../../Web/Pages/Projects/Components/ChoiceDialog/YesNoChoiceDialog';
import { RoomInfoIds } from '../../../Web/Pages/Quote/Components/Pannels/Rooms/Controllers/RoomInfosFocus';
import { Scroller, useScroller } from '../../../Web/Pages/Quote/Helpers/Scroller';
import { IMapItem } from '../Models/IMapItem';
import { useMapContext } from './MapContext';

export const HabitationIncompatibility: string = 'HabitationIncompatibility';
export type IncompatibilityState = { [roomId: string]: FindFirstIncompatibilityResult | undefined };

export type IncompatibilityContextValue = {
    state: IncompatibilityState;
    update: (values: Partial<IncompatibilityState>) => void;
    reset: (roomId?: string) => void;
    showIncompatibilityDialog: (
        roomId: string,
        findFirstIncompatibilityResult: FindFirstIncompatibilityResult,
        cancelCurrentQuestion?: () => void
    ) => void;
};
export const IncompatibilityContext = React.createContext<IncompatibilityContextValue>({
    state: {},
    update: () => {},
    reset: () => {},
    showIncompatibilityDialog: () => {},
});

export const useIncompatibilityContext = () => React.useContext(IncompatibilityContext);

export const IncompatibilityProvider: React.FC = ({ children }) => {
    const incompatibilities = useViewState<IncompatibilityState>();
    const map = useMapContext();
    const scroller = useScroller();
    const isMobile = useIsMobile();
    const reset = (roomId?: string) => {
        if (Object.keys(incompatibilities.getState()).length) {
            if (roomId) {
                incompatibilities.update({ [roomId]: undefined });
            } else {
                incompatibilities.resetState();
            }
        }
    };

    useWindowLog(() => (window.IncompatibilityState = incompatibilities.getState));

    type Request = {
        type?: IncompatibilityTypeDto;
        url?: string;
        message: string;
        afterClose?: () => void;
        afterYes?: () => void;
        cta?: string;
    };
    const [request, setRequest] = React.useState<Request>();

    const showIncompatibilityDialog = (
        roomId: string,
        findFirstIncompatibilityResult: FindFirstIncompatibilityResult,
        cancelCurrentQuestion?: () => void
    ) => {
        const { findIncompatibility } = findFirstIncompatibilityResult;
        const type: IncompatibilityTypeDto | undefined = findIncompatibility?.type;
        const message: string = findIncompatibility?.explanation ?? I18n.get('Quote_IncompatibilityAnswer');
        if (findIncompatibility?.type === IncompatibilityTypeDto.FinDeParcours) {
            const cta: string = findIncompatibility?.cta;
            setRequest({
                type,
                url: findIncompatibility.redirect_url,
                afterClose: cancelCurrentQuestion,
                message,
                cta,
            });
        } else {
            incompatibilities.update({ [roomId]: findFirstIncompatibilityResult });
            const afterYes = () => {
                const rooms = map.getState().rooms;
                const room = rooms?.find((room) => room.roomId === roomId)!;
                room.openSections = { infos: true };
                map.setPanel({
                    values: { selectedRoomId: roomId, rooms },
                    logs: { event: 'showIncompatibilityDialog afterYes', roomId, findFirstIncompatibilityResult },
                });
                setTimeout(
                    () => scroller.scrollToDiv(Scroller.ID, RoomInfoIds.ProduitPrincipal, isMobile),
                    Scroller.ShortDelay
                );
            };
            setRequest({ type, afterYes: afterYes, message });
        }
    };
    const leftAction = Boolean(request?.cta)
        ? request?.cta
        : request?.type === IncompatibilityTypeDto.Produit
        ? I18n.get('COMMON_OK')
        : I18n.get('Quote_IncompatibilityAnswer_RedirectButton');

    const rightAction =
        request?.type === IncompatibilityTypeDto.Produit
            ? I18n.get('COMMON_NO')
            : I18n.get('Quote_IncompatibilityAnswer_FinDeParcours_RightAction');

    React.useEffect(() => {
        const incompatibilityByRoom: IncompatibilityState = {};
        if (map.state.rooms?.length) {
            const appendIncompatibility = (item: IMapItem, questionProduit?: QuestionItem) => {
                const questions = questionProduit ? [questionProduit] : [];
                questions.push(
                    ...(item.questionsPose?.questions! || []),
                    ...(item.questionsSupport?.questions! || []),
                    ...(item.questionsPreparation?.questions! || []),
                    ...(item.questionsFinition?.questions! || []),
                    ...(item.questionsServices?.questions! || [])
                );
                const findFirstIncompatibility = QuestionsHelper.findFirstIncompatibility(questions);
                if (findFirstIncompatibility.findIncompatibility) {
                    incompatibilityByRoom[item.roomId!] = findFirstIncompatibility;
                }
            };
            map.state.rooms.forEach((room) => {
                appendIncompatibility(room, room.questionProduitPrincipal!);
                room.roomItems?.forEach((item) => appendIncompatibility(item));
                room.openings?.forEach((item) => appendIncompatibility(item));
            });
            incompatibilities.resetState(incompatibilityByRoom);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map.state.rooms]);

    return (
        <IncompatibilityContext.Provider
            value={{
                state: incompatibilities.state,
                update: incompatibilities.update,
                reset,
                showIncompatibilityDialog,
            }}>
            {children}
            {request && (
                <YesNoChoiceDialog
                    title={I18n.get('Quote_IncompatibilityAnswer_Title')}
                    subtitle={request.message}
                    onClickYes={() => {
                        if (request?.url) {
                            window.open(request?.url);
                        }
                        setRequest(undefined);
                        request?.afterClose?.();
                        request?.afterYes?.();
                    }}
                    onClose={() => {
                        setRequest(undefined);
                        request?.afterClose?.();
                    }}
                    onClickNo={() => {
                        setRequest(undefined);
                        request?.afterClose?.();
                    }}
                    leftAction={leftAction}
                    rightAction={rightAction}
                    styles={{ rightAction: { flex: 'unset' } }}
                    noHighlight={false}
                    yesHighlight
                />
            )}
        </IncompatibilityContext.Provider>
    );
};
