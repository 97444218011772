import React from 'react';

import _ from 'lodash';
import { GestureEvent } from '../../../../../Events/withEvent';
import { Toast } from '../../../../../Web/Services/ToastService';
import { useGestureContext } from '../../../Context/GestureContext';
import { useMapContext, useMapContextState } from '../../../Context/MapContext';
import { MapConstants } from '../../../MapConstants';
import { IOpening } from '../../../Models/IOpening';
import { IRoom, RoomType } from '../../../Models/IRoom';
import { IRoomItem } from '../../../Models/IRoomItem';
import { InvalidPositionDetection } from '../../../Utils/Collisions/InvalidPositionDetection';
import { RoomMove } from '../../../Utils/Room/RoomMove';
import { CoordPoint, Wall } from '../../../Utils/Types';
import { WallMove } from '../../../Utils/Wall/WallMove';
import { WallUtils } from '../../../Utils/Wall/WallUtils';
import { qSVG } from '../../../Utils/qSVG';
import { CoteConstants, CoteEndArrow, CoteLabel, CoteLine, CoteLineEnd, CoteSizeText, CoteUtils } from '../Cotes';
import { EditCoteDialog } from './EditCoteDialog';
import { useUndoRedoMap } from '../../../Context/UndoRedoMapContext';
import { useScaleCoteSizeText, useTextSizeRectWidth } from './ZoomScaleComponents';
import { OpeningUtils } from '../../../Utils/Opening/OpeningUtils';

type BoxRibsProps = {
    readonly?: boolean;
    roomId?: string;
    walls?: Array<Wall>;
    rooms?: Array<IRoom>;
    roomItems?: Array<IRoomItem>;
    selectedRoomId?: string;
    selectedRoomItemId?: string;
    selectedOpeningId?: string;
    type?: RoomType;
    shrinkScale?: number;
};
export const MapBoxRoomsRibs = ({ readonly, roomId }: BoxRibsProps) => {
    const { selectedRoomId, selectedRoomItemId, selectedOpeningId, type, rooms = [] } = useMapContextState();
    const roomIds = rooms.filter((room) => room.type === type).map((room) => room.roomId!);
    const roomsToUse = rooms.filter((item) => roomIds.includes(item.roomId!) || !item.roomId);
    return (
        <RawBoxRoomsRibs
            walls={roomsToUse.flatMap((room) => room.walls || [])}
            rooms={roomsToUse}
            roomItems={roomsToUse.flatMap((room) => room.roomItems || [])}
            selectedRoomId={selectedRoomId}
            selectedRoomItemId={selectedRoomItemId}
            selectedOpeningId={selectedOpeningId}
            readonly={readonly}
            roomId={roomId}
        />
    );
};

export const RawBoxRoomsRibs = ({
    readonly,
    roomId,
    walls: ribWalls = [],
    rooms = [],
    roomItems = [],
    selectedRoomId,
    selectedRoomItemId,
    selectedOpeningId,
    type,
    shrinkScale,
}: BoxRibsProps) => {
    const { rooms: allRooms = [], walls: allWalls = [], roomItems: allRoomItems = [] } = useMapContextState();
    let roomsToDisplay = rooms;
    let roomItemsToDisplay = roomItems;
    let wallsToDisplay = ribWalls;
    if (type) {
        const roomIds = rooms.filter((room) => room.type === type).map((room) => room.roomId!);
        roomsToDisplay = allRooms.filter((item) => roomIds.includes(item.roomId!) || !item.roomId);
        wallsToDisplay = allWalls.filter((item) => roomIds.includes(item.roomId!) || !item.roomId);
        roomItemsToDisplay = allRoomItems.filter((item) => roomIds.includes(item.roomId!) || !item.roomId);
    }
    const gesture = useGestureContext().state;
    const ribWallsToUse = wallsToDisplay.filter((wall) => wall.roomId === roomId || !roomId);
    const adjacentWalls = WallUtils.getWallsAdjacentList(ribWallsToUse, ribWallsToUse, roomItemsToDisplay);

    const showCotes = (room: IRoom) =>
        readonly ||
        (selectedRoomItemId || selectedOpeningId || gesture.roomItemId
            ? false
            : selectedRoomId
            ? room.roomId === selectedRoomId
            : true);
    return (
        <React.Fragment>
            {roomsToDisplay
                .filter((item) => (roomId ? item.roomId === roomId : true))
                .filter(showCotes)
                .map((room) => {
                    return (
                        <BoxWallCotes
                            key={`BoxWallCotes-Room-${room.roomId}`}
                            roomId={room.roomId!}
                            ribWalls={room.walls!}
                            adjacentWalls={adjacentWalls}
                            insideCoteOnly={readonly}
                            shrinkScale={shrinkScale}
                        />
                    );
                })}
        </React.Fragment>
    );
};

type BoxWallRibsProps = {
    roomId: string;
    ribWalls: Array<Wall>;
    adjacentWalls: Array<Array<Wall>>;
    withAdjacentCotes?: boolean;
    insideCoteOnly?: boolean;
    shrinkScale?: number;
};
const BoxWallCotes = ({ roomId, ribWalls, adjacentWalls, insideCoteOnly, shrinkScale }: BoxWallRibsProps) => {
    const wallSize = (wall: Wall) => (qSVG.measure(wall.start, wall.end) / MapConstants.meter) * 100;
    const [coteWallToEdit, setCoteWallToEdit] = React.useState<Wall>();
    const map = useMapContext();
    const undoRedo = useUndoRedoMap();

    const onValidate = (dimens: number) => {
        if (coteWallToEdit) {
            undoRedo.store(map.getState());

            const { walls = [], rooms = [], openings = [] } = map.getState();
            const wall = WallMove.getResizeBindedWall(coteWallToEdit)!; //binding one of the siblings wall to make the resize;
            let moveResult = WallMove.prepareWallMove(coteWallToEdit, wall, dimens, openings, rooms, walls);
            if (!moveResult.wallMoveSuccess) {
                const wall = WallMove.getResizeBindedWall(coteWallToEdit, false, true)!; //retry by resetting CoteManuallySetted
                moveResult = WallMove.prepareWallMove(coteWallToEdit, wall, dimens, openings, rooms, walls);
            }
            const invalidRoomIds = InvalidPositionDetection.getInvalidRoomIds(moveResult.rooms, roomId);
            if (invalidRoomIds.length > 0) {
                Toast.error('ERROR_INVALID_WALL_MOVE');
                const undoState = undoRedo.undo();
                map.update({
                    values: { ...undoState, busy: false },
                    logs: { event: 'BoxWallCotes onValidate error', dimens },
                });
            } else {
                map.update({
                    values: {
                        walls: moveResult.walls,
                        rooms: moveResult.rooms,
                        openings: moveResult.openings,
                        busy: false,
                    },
                    mapModified: true,
                    logs: { event: 'BoxWallCotes onValidate success', dimens },
                });
            }
            setCoteWallToEdit(undefined);
            const room = rooms.find((x) => x.roomId === wall.roomId);
            if (room) {
                OpeningUtils.resetOpeningsPlacement(moveResult.openings, moveResult.walls);
            }
        }
    };

    const getWallLimits = (coteWallToEdit: Wall) => {
        const { walls = [], rooms = [], openings = [], roomItems = [] } = map.getState();
        return getMinMaxResizeWall(coteWallToEdit, walls, openings, rooms, roomItems);
    };

    const props: React.SVGProps<SVGGElement> = {
        id: RoomMove.roomRibsId(roomId),
        className: RoomMove.class(roomId),
    };
    return (
        <React.Fragment>
            <g {...props}>
                {ribWalls.map((wall, index) => {
                    const adjWall = adjacentWalls.find((x) => x[0] === wall);
                    return (
                        <WallRibCote
                            metaId="wall¤wallStart¤wallEnd" //metaId
                            attrs={props}
                            key={index}
                            roomId={wall.roomId!}
                            p1={wall.start}
                            p2={wall.end}
                            inOut={adjWall || insideCoteOnly ? 'inside' : 'outside'}
                            side={wall.sideName!}
                            value={wallSize(wall)}
                            angle={wall.angle * (180 / Math.PI)}
                            coteMeasureOffset={insideCoteOnly ? MapConstants.wallThickness : 0}
                            highlight={
                                coteWallToEdit
                                    ? wall.roomId === coteWallToEdit.roomId && wall.sideName === coteWallToEdit.sideName
                                    : false
                            }
                            editCote={(e) => setCoteWallToEdit(wall)}
                            shrinkScale={shrinkScale}
                        />
                    );
                })}
            </g>
            {coteWallToEdit && (
                <EditCoteDialog
                    value={wallSize(coteWallToEdit).toFixed(0)}
                    onValidate={onValidate}
                    onClose={() => setCoteWallToEdit(undefined)}
                    getLimit={() => getWallLimits(coteWallToEdit)}
                />
            )}
        </React.Fragment>
    );
};

type WallRibCoteProps = {
    roomId: string;
    p1: CoordPoint; //start
    p2: CoordPoint; //end
    inOut: 'inside' | 'outside';
    side: string;
    value: number;
    angle: number;
    metaId: string;
    coteMeasureOffset: number;
    attrs: React.SVGProps<SVGGElement>;

    highlight?: boolean;
    editCote: (e?: GestureEvent) => void;
    shrinkScale?: number;
};
const WallRibCote = ({
    roomId,
    p1,
    p2,
    inOut,
    side,
    value,
    angle,
    metaId,
    coteMeasureOffset = 0,
    highlight,
    editCote,
    shrinkScale,
}: WallRibCoteProps) => {
    const startText = qSVG.middle(p1.x, p1.y, p2.x, p2.y);
    const measure = qSVG.measure(p1, p2) - coteMeasureOffset;
    const densedDisplay = value < CoteConstants.densedDisplayRuptureValue(shrinkScale);

    const sizeText = value.toFixed(0) + ' ' + CoteConstants.quote_unit;
    const sizeTextLength = useTextSizeRectWidth(sizeText.length);
    const fontSize = useScaleCoteSizeText();

    const { angle: newAngle, shiftValue, reversed } = CoteUtils.calculateShift(inOut, angle, shrinkScale);
    const densedShiftOffset = CoteUtils.calculateDensedShiftOffset(reversed, densedDisplay, shrinkScale);

    const props: React.SVGProps<SVGGElement> = {
        id: `wall:room-${roomId}¤side-${side}¤value-${value.toFixed(0)}metaId-${metaId ? '¤' + metaId : ''}`,
        className: 'cote',
        transform: `rotate(${newAngle} ${startText.x},${startText.y}) translate(${startText.x},${
            startText.y + (shiftValue + densedShiftOffset)
        })`,
    };

    return (
        <g {...props}>
            <CoteLine measure={measure} />
            <CoteLineEnd
                x={-measure / 2}
                shiftValue={shiftValue + densedShiftOffset}
                reversed={reversed}
                inOut={inOut}
                shrinkScale={shrinkScale}
            />
            <CoteLineEnd
                x={measure / 2}
                shiftValue={shiftValue + densedShiftOffset}
                reversed={reversed}
                inOut={inOut}
                shrinkScale={shrinkScale}
            />
            <CoteEndArrow x={-measure / 2} reversed shrinkScale={shrinkScale} />
            <CoteEndArrow x={measure / 2} shrinkScale={shrinkScale} />

            <CoteLabel textLength={sizeTextLength} highlight={highlight} shrinkScale={shrinkScale} />
            <CoteSizeText
                text={sizeText}
                fontSize={fontSize}
                angle={newAngle}
                onClick={editCote}
                shrinkScale={shrinkScale}
            />
        </g>
    );
};

const getMinMaxResizeWall = (
    wall: Wall,
    walls: Wall[],
    openings: IOpening[] = [],
    rooms: IRoom[] = [],
    roomItems: IRoomItem[]
) => {
    const min = wall.minimumSize!;
    const max = Math.round(WallMove.wallResizeSimulate(wall, openings, rooms, roomItems, walls));
    return { min, max };
};
