import { I18n } from '../Locales/I18nService';
import { AttributDto, ProduitDto } from '../Models/Questions/ProduitDto';
import { QuestionItem } from '../Models/Questions/QuestionDto';
import { LocalityDto } from '../Models/TravelExpenses/TravelExpenseDto';
import { RoomType } from '../Plugins/FloorPlan/Models/IRoom';
import { ObjectUtils } from './ObjectUtils';
import { QuestionsHelper } from './QuestionsHelper';

export type EvaluationContext = Record<string, any>;
export interface ContournementsContext {
    pipe?: number;
    mounted_element?: number;
    mounted_radiator?: number;
    pillar?: number;
    stonewall?: number;
    stairs?: number;
    railing?: number;
    tree?: number;
    wall_pipe?: number;
    wall_pipe_hole?: number;
    electric_equipment?: number;
    wall_pillar?: number;
    hotspot?: number;
}

export interface QuestionContext {
    revetement_type?: string;
    //* in evaluations : { '$name1' : $value1,  '$name2' : $value2 }
    evaluations?: EvaluationContext;

    contournements?: ContournementsContext;

    km_over_20?: number;

    //* Pièce contiguës et présence de longueur du raccord
    are_rooms_contiguous?: any;
    connection_needed?: any;

    //* Questions Floorplans, room
    room_length?: any;
    room_surface?: any;

    type?: RoomType;
    room_wall?: any;
    room_type?: any;
    room_floor?: any;
    room_heating_type?: any;
    has_room_existing_flooring?: any;
    room_existing_floor_type?: any;
    room_should_lay_plinthes_inside_room_element?: any;

    //* Questions Floorplans, opening
    opening_type_localized?: any;
    opening_dimension_centimetre?: any;

    //* Questions Floorplans, roomElement
    room_element_type_localized?: any;
    should_lay_flooring_inside?: any;
    should_lay_plinthes_inside?: any;
    room_element_area?: any;

    localisation_piece?: any;
    flooring_direction?: any;

    rooms?: QuestionContext[];
    openings?: QuestionContext[];
    room_items?: QuestionContext[];

    [field: string]: any;
}

export class QuestionContextHelper {
    //* To identify optional questions without value
    public static NOT_SET = 'NOT_SET';

    public static toQuestionDebug = (id: string): string => `question_debug_${id}`;

    //* question type SingleChoice
    public static toQuestionOption = (id: string): string => `question_${id}_option`;
    public static toQuestionValue = (id: string): string => `question_${id}_value`;
    public static toOptionId = (id: string): string => `option_${id}`;

    //* question type Comment
    public static toQuestionCommentvalue = (id: string): string => `question_${id}_value`;

    //* question type Produit
    //* public static toQuestion = (id: string): string => `question_${id}`; //
    //!: {
    //!     attribut_produit_F001-ChauffageSol : F001-ChauffageSol,
    //!     attribut_produit_F001-Decor : F001-Decor
    //!: }
    public static toQuestionProduitValue = (id: string) => `question_${id}_produit`; //* NOT USED
    public static toQuestionProduitKey = (id: string) => `question_${id}`;
    public static toProduitAttributName = (name: string) => `attribut_produit_${name}`;
    public static infoProduitKey = 'infos_produit';

    public static toProduitValue = (produit: ProduitDto) => {
        const produitContext: QuestionContext = {};
        const attributs: Array<AttributDto> = produit.attributs ?? [];
        attributs.forEach((attribut: AttributDto) => {
            produitContext[QuestionContextHelper.toProduitAttributName(attribut.name)] = attribut.value;
        });
        produitContext[QuestionContextHelper.infoProduitKey] = produit.infos_produit;

        return produitContext;
    };

    public static toZipCodeValue = (localityDto: LocalityDto) => {
        const zipCodeContext: QuestionContext = {};
        if (localityDto.travel_expenses) {
            zipCodeContext.km_over_20 = localityDto.travel_expenses.billable_distance_in_meters / 1000;
        }
        return zipCodeContext;
    };

    public static withRegionParisienne = (localityDto: LocalityDto, context: QuestionContext = {}): QuestionContext => {
        const evaluationsContext = context.evaluations ?? {};
        evaluationsContext[QuestionContextHelper.toRegionParisienne] = localityDto.travel_expenses?.is_paris_area
            ? QuestionContextHelper.toIsRegionPariennes
            : undefined;
        context.evaluations = evaluationsContext;
        return context;
    };

    public static toEvaluationValue = (key: string): string => `${key}`;

    public static toRegionParisienne = 'région parisienne';
    public static toIsRegionPariennes = 'oui';

    //ragréage obligatoire
    public static ragreageObligatoreKey = 'Ragréage';
    public static ragreageObligatoreValue = 'Obligatoire';

    public static transformIntoLongFloorName(floorName: string = '') {
        return floorName === I18n.get('Floorplan_RoomFloorList_RDC_Short')
            ? I18n.get('Floorplan_RoomFloorList_RDC_Long')
            : floorName;
    }

    //!\todo: fix this @mmc
    public static isRagreageObligatoireExist(rooms: Array<any>): boolean {
        const ragreageObligatoireIsInEvaluation = (evaluationContext: EvaluationContext = {}) => {
            return (
                evaluationContext[QuestionContextHelper.ragreageObligatoreKey] ===
                QuestionContextHelper.ragreageObligatoreValue
            );
        };

        let hasRagreageObligatoire: boolean = false;
        let continueProcess: boolean = true;
        rooms.forEach((x) => {
            if (continueProcess) {
                const roomQuestions = x.questions.filter((x: QuestionItem) => x.showQuestion);
                const findQuestion = roomQuestions.find((x: QuestionItem) =>
                    ragreageObligatoireIsInEvaluation(x.context.evaluations)
                );
                hasRagreageObligatoire = Boolean(findQuestion);
                continueProcess = !hasRagreageObligatoire;
            }
        });
        return hasRagreageObligatoire;
    }

    public static mergeContext = (
        previousContext: QuestionContext = {},
        context: QuestionContext = {}
    ): QuestionContext => {
        context.evaluations = QuestionContextHelper.mergeEvaluations(previousContext.evaluations, context.evaluations);
        return {
            ...ObjectUtils.clone(previousContext),
            ...ObjectUtils.clone(context),
        };
    };

    public static mergeEvaluations = (
        previousEvaluationContext: EvaluationContext = {},
        evaluationContext: EvaluationContext = {}
    ): QuestionContext => {
        const mergeEvaluationContext: EvaluationContext = {
            ...ObjectUtils.clone(previousEvaluationContext ?? {}),
            ...ObjectUtils.clone(evaluationContext ?? {}),
        };
        return ObjectUtils.clone(mergeEvaluationContext);
    };

    //* INv3
    //* last question habitation + question produit principal
    public static getBaseQuestionsContext = (
        habitationQuestions: Array<QuestionItem> = [],
        questionProduitPrincipal?: QuestionItem
    ): QuestionContext => {
        const lastHabitationQuestionContext = habitationQuestions[habitationQuestions.length - 1]?.context;
        if (questionProduitPrincipal) {
            const valueContext = QuestionsHelper.getValidatedContext(questionProduitPrincipal);
            return QuestionContextHelper.mergeContext(lastHabitationQuestionContext, valueContext);
        }
        return QuestionContextHelper.mergeContext(lastHabitationQuestionContext, {});
    };
}
