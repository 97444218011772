import React from 'react';

import { useQuoteContext } from '../../../Web/Pages/Quote/QuotePageController';
import { useMapContext } from './MapContext';

//* USE STORE FEATURE FROM zustand
//link : https://zustand-demo.pmnd.rs/

type MapReadyValue = { ready: boolean; setReady: (ready: boolean) => void };
const MapReadyContext = React.createContext<MapReadyValue>({ ready: false, setReady: () => ({}) });
export const useMapReady = () => React.useContext(MapReadyContext);

export const MapReadyProvider: React.FC<any> = ({ children }) => {
    const [ready, setReady] = React.useState<boolean>(false);
    return <MapReadyContext.Provider value={{ ready, setReady }}>{children}</MapReadyContext.Provider>;
};

//* FIND A BETTER NAME : used to determine if the estimation panel is ready to show or not
//* TODO REVIEW WHEN START "FIN DE PARCOURS"

export function MapReady({ children }: React.PropsWithChildren<unknown>) {
    const map = useMapContext();
    const { ready, setReady } = useMapReady();
    const { addRoomTutorialShown, showTutorial, showStoreLocator } = map.state;
    const { loading, versionData, habitationQuestionsOpen } = useQuoteContext().state;
    const show =
        versionData &&
        !Boolean(loading || habitationQuestionsOpen || (showTutorial && !addRoomTutorialShown) || showStoreLocator);

    React.useEffect(() => {
        if (ready !== show) {
            setReady(Boolean(show));
        }
    }, [show, ready, setReady]);

    return <>{children}</>;
}
