import { IRoom } from '../../Models/IRoom';
import { IRoomItem } from '../../Models/IRoomItem';
import { JstsUtils } from '../JstsUtils';
import { RoomCollision } from '../SAT/SATUtils';
import { CoordPoint } from '../Types';

export class CollisionRoomUtils {
    public static testCollision(satPolygonA: Array<RoomCollision> = [], satPolygonB: Array<RoomCollision> = []) {
        const totalOverlap = { x: 0, y: 0 };
        for (const subPolygonA of satPolygonA) {
            for (const subPolygonB of satPolygonB) {
                const response = new SAT.Response();
                const result = SAT.testPolygonPolygon(
                    subPolygonA as never as SAT.Polygon,
                    subPolygonB as never as SAT.Polygon,
                    response
                );
                if (result) {
                    if (totalOverlap.x === 0) {
                        totalOverlap.x += response.overlapV.x;
                    }
                    if (totalOverlap.y === 0) {
                        totalOverlap.y += response.overlapV.y;
                    }
                }
            }
        }
        return totalOverlap;
    }

    public static testAllRoomItemsAreInsideRoom(rooms: Array<IRoom>, roomItems: Array<IRoomItem>) {
        for (const roomItem of roomItems) {
            const room = rooms.find((x) => x.roomId === roomItem.roomId);

            if (
                !this.testIfPolygonAContainedInPolygonB(
                    roomItem.coordsReal!.slice(0, -1),
                    room?.coords!.slice(0, -1)
                ) &&
                !roomItem.isMoveableOutside
            ) {
                return false;
            }
        }
        return true;
    }

    public static testIfPolygonAContainedInPolygonB(polyA: Array<CoordPoint> = [], polyB: Array<CoordPoint> = []) {
        const geometryFactory = JstsUtils.GeometryFactory();

        const geoInputPolyA = JstsUtils.vectorCoordinates2JTS(polyA);
        geoInputPolyA.push(geoInputPolyA[0]);
        const shellPolyA = geometryFactory.createPolygon(geoInputPolyA);

        const geoInputPolyB = JstsUtils.vectorCoordinates2JTS(polyB);
        geoInputPolyB.push(geoInputPolyB[0]);
        const shellPolyB = geometryFactory.createPolygon(geoInputPolyB);

        return shellPolyB.contains(shellPolyA);
    }

    public static testIfPolygonAContainedIn(polyA: Array<CoordPoint>, rooms: Array<IRoom>) {
        const geometryFactory = JstsUtils.GeometryFactory();

        const geoInputPolyA = JstsUtils.vectorCoordinates2JTS(polyA);
        geoInputPolyA.push(geoInputPolyA[0]);

        const shellPolyA = geometryFactory.createPolygon(geoInputPolyA);
        for (const room of rooms) {
            const geoInputRoom = JstsUtils.vectorCoordinates2JTS(room.coords);
            geoInputRoom.push(geoInputRoom[0]);

            const shellRoom = geometryFactory.createPolygon(geoInputRoom);
            if (shellRoom.contains(shellPolyA)) {
                return room.roomId;
            }
        }
        return undefined;
    }
}
