import React from 'react';

import { useExportMapSvgV3 } from '../Components/Images/ExportMapSvgV3';
import { ExportRoomLaizeBandsSvgItem } from '../Components/Images/Laizes/LaizeExportBands';
import { useExportMapLaizeBandsSvgV3 } from '../Components/Images/Laizes/LaizeExportBandsSvgV3';
import { RoomType } from '../Models/IRoom';
import { useMapContextState } from './MapContext';

export type MapExportContextValue = {
    captureSvg: (type: RoomType) => string;
    capturePng: (type: RoomType) => Promise<string>;
    exportBandItems: () => Array<ExportRoomLaizeBandsSvgItem>;
};
const MapExportContext = React.createContext<MapExportContextValue>({
    captureSvg: () => '',
    capturePng: () => Promise.resolve(''),
    exportBandItems: () => [],
});

export const useMapExport = () => React.useContext(MapExportContext);

export const MapExportProvider: React.FC = ({ children }) => {
    const mapState = useMapContextState();
    const { mapView, captureSvg, capturePng } = useExportMapSvgV3(mapState);
    const { exportItems: exportBandItems } = useExportMapLaizeBandsSvgV3(mapState);

    return (
        <MapExportContext.Provider value={{ captureSvg, capturePng, exportBandItems }}>
            {children}
            {mapView(RoomType.Sol)}
            {mapView(RoomType.Wall)}
            {exportBandItems().map((item) => item.renderView({ id: item.room.roomId }))}
        </MapExportContext.Provider>
    );
};
