import * as _ from 'lodash';

import { JstsPolygon, JstsUtils } from '../../../Utils/JstsUtils';
import { PointUtils } from '../../../Utils/PointUtils';
import { CoordPoint, RotationParams } from '../../../Utils/Types';
import { Direction, ProductType } from '../Laize';
import { LaizeCalcProps } from '../LaizeCalc';
import { LaizeBands } from './LaizeBands';

type CalculateLeftOverParams = {
    laizeCalcProps: LaizeCalcProps;
    coveredSurfacePoly: JstsPolygon;
    bandCoords: Array<CoordPoint>;
    verticalMargin: number;
    horizontalMargin: number;
    laizeDirection: Direction;
};

export type LaizeLeftOverItem = { id: string; leftOverPolygon: JstsPolygon, usedLeftOverCoords?: JstsPolygon; rotationParams?: RotationParams; };
export type UsableLeftOverItem = { leftOver: LaizeLeftOverItem; resultingLeftOverPoly: JstsPolygon; resultingUsedLeftOverPoly: JstsPolygon };

type BaseCalculateLeftOverParams = {
    laizeCalcProps: LaizeCalcProps;
    coveredSurfacePoly: JstsPolygon;
    bandSurfacePoly: JstsPolygon;
    verticalMargin: number;
    horizontalMargin: number;
    laizeDirection: Direction;
};

type TryUsingLeftOverParams = { band: Array<CoordPoint>; leftOvers: Array<LaizeLeftOverItem> };

type TestIfBandContainedInPolygonParams = { bandPoints: Array<CoordPoint>; polygon: JstsPolygon };

export type LeftOverResult = {
    leftOverCoords: Array<Array<CoordPoint>>;
    surfaceCoveredCoords: Array<CoordPoint>;
    surfaceCoveredWithMarginsCoords: Array<CoordPoint>;
};

export class LaizeLeftOver {
    /**
     * Calculates the left-over that may result when laying the given band.
     * Left-over happens when the band is wider or higher than the surface to cover's bounding box.
     */
    public static calculateLeftOver(props: CalculateLeftOverParams): LeftOverResult {
        const { laizeCalcProps, coveredSurfacePoly } = props;
        if (laizeCalcProps.TYPE === ProductType.Rouleau) {
            // const leftOverSurface = this.computeFromInflateHV(props);
            // const leftOverSurface = this.computeFromIntersection(props);
            const { leftOverSurface, surfaceCoveredWithMarginsCoords } = this.computeLaizeFromDensifiedPolygon(props);
            return this.filterLaizeLeftOver(leftOverSurface, laizeCalcProps, coveredSurfacePoly.getCoordinates(), surfaceCoveredWithMarginsCoords);
        } else {
            return this.computeLame(props);
        }
    }

    public static computeLame({
        laizeCalcProps,
        coveredSurfacePoly,
        bandCoords,
    }: CalculateLeftOverParams): LeftOverResult {
        const surfaceCoveredCoords = coveredSurfacePoly.getCoordinates();
        const surfaceCoverableBoundingBox = PointUtils.calculateBoundingBox({ points: surfaceCoveredCoords })!;
        const bandBoundingBox = PointUtils.calculateBoundingBox({ points: bandCoords })!;

        const xLeftOverStart = surfaceCoverableBoundingBox.xMin;
        const xLeftOverEnd = bandBoundingBox.xMax;
        const yLeftOverStart = surfaceCoverableBoundingBox.yMax;
        const ySurfaceCoveredStart = bandBoundingBox.yMin;
        const yLeftOverEnd = ySurfaceCoveredStart + laizeCalcProps.LONGUEUR_CM!;

        const leftOverCoords: Array<CoordPoint> = [
            { x: xLeftOverStart, y: yLeftOverStart },
            { x: xLeftOverEnd, y: yLeftOverStart },
            { x: xLeftOverEnd, y: yLeftOverEnd },
            { x: xLeftOverStart, y: yLeftOverEnd },
        ];

        const leftOverHeight = LaizeBands.getBandHeight({ band: leftOverCoords });

        if (leftOverHeight < laizeCalcProps.MARGIN_LENGTH) {
            return {
                leftOverCoords: [],
                surfaceCoveredCoords,
                surfaceCoveredWithMarginsCoords: surfaceCoveredCoords
            };
        }
        return {
            leftOverCoords: [leftOverCoords],
            surfaceCoveredCoords,
            surfaceCoveredWithMarginsCoords: surfaceCoveredCoords
        };
    }

    public static filterLaizeLeftOver(
        leftOverSurface: JstsPolygon,
        laizeCalcProps: LaizeCalcProps,
        surfaceCoveredCoords: Array<CoordPoint>,
        surfaceCoveredWithMarginsCoords: Array<CoordPoint>
    ): LeftOverResult {
        const leftOverSurfaces = Array<JstsPolygon>();
        const numberOfLeftOver = leftOverSurface.getNumGeometries();
        if (numberOfLeftOver === 0) {
            return {
                leftOverCoords: [],
                surfaceCoveredCoords,
                surfaceCoveredWithMarginsCoords
            };
        } else {
            for (let i = 0; i < numberOfLeftOver; i++) {
                const leftOver = leftOverSurface.getGeometryN(i);
                if (leftOver.getArea() !== 0) {
                    const width = LaizeBands.getBandWidth({ band: leftOver.getCoordinates() });
                    if (width > laizeCalcProps.LAIZE_MARGIN) {
                        leftOverSurfaces.push(leftOver);
                    }
                }
            }

            return {
                leftOverCoords: leftOverSurfaces.map((leftOver) => leftOver.getCoordinates()),
                surfaceCoveredCoords,
                surfaceCoveredWithMarginsCoords
            };
        }
    }

    public static baseComputeLaizeFromDensifiedPolygon({
        laizeCalcProps,
        coveredSurfacePoly,
        bandSurfacePoly,
        verticalMargin,
        horizontalMargin,
        laizeDirection,
    }: BaseCalculateLeftOverParams): { leftOverSurface: JstsPolygon, surfaceCoveredWithMarginsCoords: CoordPoint[] } {
        const surfaceCoveredCoords = coveredSurfacePoly.getCoordinates();
        let horizontalVectorSign = laizeDirection === Direction.LeftToRight ? 1 : -1;

        const marginHDefault = laizeCalcProps.LAIZE_CONSECUTIVE_BANDS_MARGIN / 2;
        const marginH = horizontalVectorSign * marginHDefault;
        const marginHLarge = horizontalVectorSign * (horizontalMargin + marginHDefault);
        const marginV = verticalMargin / 2;

        let leftOverSurface = bandSurfacePoly;
        let step = laizeCalcProps.LAIZE_MARGIN;
        const densifyEdge = (startX: number, endX: number, startY: number, endY: number) => {
            const reduceLeftOver = (coords: Array<CoordPoint>) =>
                (leftOverSurface = leftOverSurface.difference(JstsUtils.createJstsPolygon(coords)));
            let currX: number = startX;
            let currY: number = startY;
            const shouldContinue = () =>
                (startX < endX ? currX < endX : currX > endX) || (startY < endY ? currY < endY : currY > endY);
            do {
                // eslint-disable-next-line no-loop-func
                const coords = _.cloneDeep(surfaceCoveredCoords).map(c => PointUtils.translation(c, { x: currX, y: currY }));
                reduceLeftOver(coords);

                currX += step * (startX === endX ? 0 : startX < endX ? 1 : -1);
                currY += step * (startY === endY ? 0 : startY < endY ? 1 : -1);
            } while (shouldContinue());
            const coords = _.cloneDeep(surfaceCoveredCoords).map(c => PointUtils.translation(c, { x: endX, y: endY }));
            reduceLeftOver(coords);
        };
        //TOP
        densifyEdge(-marginHLarge, marginH, -marginV, -marginV);
        //RIGHT
        densifyEdge(marginH, marginH, -marginV, marginV);
        //BOTTOM
        densifyEdge(marginH, -marginHLarge, marginV, marginV);
        //LEFT
        densifyEdge(-marginHLarge, -marginHLarge, marginV, -marginV);

        const surfaceCoveredWithMargin = bandSurfacePoly.difference(leftOverSurface).buffer(0);

        return { leftOverSurface, surfaceCoveredWithMarginsCoords: surfaceCoveredWithMargin.getCoordinates() };
    }

    public static computeLaizeFromDensifiedPolygon(params: CalculateLeftOverParams): { leftOverSurface: JstsPolygon, surfaceCoveredWithMarginsCoords: CoordPoint[] } {
        const { bandCoords } = params;
        const bandBoundingBox = PointUtils.calculateBoundingBox({ points: bandCoords })!;
        const bandSurfacePoly = JstsUtils.createJstsPolygonFromBoundingBox(bandBoundingBox);
        return LaizeLeftOver.baseComputeLaizeFromDensifiedPolygon({ bandSurfacePoly, ...params });
    }

    public static computeLaidLaizeFromDensifiedPolygon(params: CalculateLeftOverParams): JstsPolygon {
        const { bandCoords } = params;
        const bandBoundingBox = PointUtils.calculateBoundingBox({ points: bandCoords })!;
        const bandSurfacePoly = JstsUtils.createJstsPolygonFromBoundingBox(bandBoundingBox);
        const { leftOverSurface } = LaizeLeftOver.computeLaizeFromDensifiedPolygon(params);
        const laidLaizeSurface = bandSurfacePoly.difference(leftOverSurface);
        return laidLaizeSurface;
    }

    public static computeLaizeFromIntersection({
        laizeCalcProps,
        coveredSurfacePoly,
        bandCoords,
        verticalMargin,
        horizontalMargin,
        laizeDirection,
    }: CalculateLeftOverParams): JstsPolygon {
        const surfaceCoveredCoords = coveredSurfacePoly.getCoordinates();
        const bandBoundingBox = PointUtils.calculateBoundingBox({ points: bandCoords })!;
        let horizontalVectorSign: number = 1;
        switch (laizeDirection) {
            case 'leftToRight':
                horizontalVectorSign = 1;
                break;
            case 'rightToLeft':
                horizontalVectorSign = -1;
                break;
        }
        const horizontalDefaultMargin = horizontalVectorSign * (laizeCalcProps.LAIZE_MARGIN / 2);
        const horizontalLeftMargin = horizontalVectorSign * (horizontalMargin + horizontalDefaultMargin);
        const verticalDefaultMargin = verticalMargin / 2;
        const topRight = _.cloneDeep(surfaceCoveredCoords).map((c) =>
            PointUtils.translation(c, { x: horizontalDefaultMargin, y: -verticalDefaultMargin })
        );
        const topLeft = _.cloneDeep(surfaceCoveredCoords).map((c) =>
            PointUtils.translation(c, { x: -horizontalLeftMargin, y: -verticalDefaultMargin })
        );
        const bottomRight = _.cloneDeep(surfaceCoveredCoords).map((c) =>
            PointUtils.translation(c, { x: horizontalDefaultMargin, y: verticalDefaultMargin })
        );
        const bottomLeft = _.cloneDeep(surfaceCoveredCoords).map((c) =>
            PointUtils.translation(c, { x: -horizontalLeftMargin, y: verticalDefaultMargin })
        );
        const bandSurfacePoly = JstsUtils.createJstsPolygonFromBoundingBox(bandBoundingBox);

        const topRightOver = bandSurfacePoly.difference(JstsUtils.createJstsPolygon(topRight));
        const topLeftOver = bandSurfacePoly.difference(JstsUtils.createJstsPolygon(topLeft));
        const bottomRightOver = bandSurfacePoly.difference(JstsUtils.createJstsPolygon(bottomRight));
        const bottomLeftOver = bandSurfacePoly.difference(JstsUtils.createJstsPolygon(bottomLeft));

        const leftOverSurface = topRightOver
            .intersection(topLeftOver)
            .intersection(bottomRightOver)
            .intersection(bottomLeftOver);

        return leftOverSurface;
    }

    public static computeLaizeFromInflateHV({
        laizeCalcProps,
        coveredSurfacePoly,
        bandCoords,
        verticalMargin,
        horizontalMargin,
        laizeDirection,
    }: CalculateLeftOverParams): JstsPolygon {
        const surfaceCoveredCoords = coveredSurfacePoly.getCoordinates();
        const bandBoundingBox = PointUtils.calculateBoundingBox({ points: bandCoords })!;

        const bandSurfacePoly = JstsUtils.createJstsPolygonFromBoundingBox(bandBoundingBox);
        let inflatedSurfaceCoords = PointUtils.inflatePolygonHV(
            surfaceCoveredCoords,
            horizontalMargin / 2 + laizeCalcProps.LAIZE_MARGIN / 2,
            verticalMargin / 2
        );
        let vector: CoordPoint = { x: 0, y: 0 };
        switch (laizeDirection) {
            case 'leftToRight':
                vector = { x: -horizontalMargin / 2, y: 0 };
                break;
            case 'rightToLeft':
                vector = { x: horizontalMargin / 2, y: 0 };
                break;
        }
        inflatedSurfaceCoords = inflatedSurfaceCoords.map((c) => PointUtils.translation(c, vector));

        const inflatedSurface = JstsUtils.createJstsPolygon(inflatedSurfaceCoords);
        const leftOverSurface = bandSurfacePoly.difference(inflatedSurface);
        return leftOverSurface;
    }

    /**
     * - Tries to fit the band into each of the left-over surfaces (no rotation allowed, only translations)
     * - If the band fits into more than one left-over surface, determines the smallest left-over where it fits.
     * - Returns false if no left-over fits the band
     * - Otherwise returns the smallest left-over that can fit the band
     */
    public static tryUsingLeftOver({ band, leftOvers }: TryUsingLeftOverParams): LaizeLeftOverItem | undefined {
        if (leftOvers.length === 0) {
            return undefined;
        }

        const bandPoints = _.cloneDeep(band);

        let usableLeftOvers: Array<UsableLeftOverItem> = [];
        leftOvers.forEach((leftOver) => {
            const result = this.testIfBandContainedInPolygon({ bandPoints, polygon: leftOver.leftOverPolygon });
            if (result) {
                const { diff: resultingLeftOverPoly, intersection: resultingUsedLeftOverPoly } = result;
                usableLeftOvers.push({ leftOver, resultingLeftOverPoly, resultingUsedLeftOverPoly });
            }
        });

        if (usableLeftOvers.length > 0) {
            const optimalLeftOver = usableLeftOvers.reduce((res, currentLeftOver) => {
                if (currentLeftOver.leftOver.leftOverPolygon.getArea() < res.leftOver.leftOverPolygon.getArea()) {
                    return currentLeftOver;
                } else {
                    return res;
                }
            });

            optimalLeftOver.leftOver.leftOverPolygon = optimalLeftOver.resultingLeftOverPoly;
            optimalLeftOver.leftOver.usedLeftOverCoords = optimalLeftOver.resultingUsedLeftOverPoly;

            return optimalLeftOver.leftOver;
        }

        return undefined;
    }

    public static testIfBandContainedInPolygon({ bandPoints, polygon }: TestIfBandContainedInPolygonParams) {
        const geometryFactory = JstsUtils.GeometryFactory();
        const polygonCoords = polygon.getCoordinates();

        const bandHeight = LaizeBands.getBandHeight({ band: bandPoints });
        const bandWidth = LaizeBands.getBandWidth({ band: bandPoints });
        const polygonHeight = LaizeBands.getBandHeight({ band: polygonCoords });
        const polygonWidth = LaizeBands.getBandWidth({ band: polygonCoords });

        if (bandHeight > polygonHeight || bandWidth > polygonWidth) {
            return undefined;
        }

        for (const bandPoint of bandPoints) {
            for (const polyPoint of polygonCoords) {
                const vectorX = polyPoint.x - bandPoint.x;
                const vectorY = polyPoint.y - bandPoint.y;
                const vector = { x: vectorX, y: vectorY };
                const translatedBand = bandPoints.map((point) => PointUtils.translation(point, vector));

                const bandPoly = JstsUtils.createJstsPolygon(translatedBand, true, geometryFactory).buffer(0);
                if (polygon.contains(bandPoly)) {
                    return {
                        diff: polygon.difference(bandPoly),
                        intersection: polygon.intersection(bandPoly)
                    };
                }
            }
        }

        return undefined;
    }
}
