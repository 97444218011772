import React from 'react';
import { useMapContext } from './MapContext';
import { useUndoRedoMap } from './UndoRedoMapContext';

const useCtrlKeybord = (keybordKey: string, action: VoidFunction) => {
    const onKeyDown = (e: KeyboardEvent) => {
        if ((e.ctrlKey || e.metaKey) && e.key.toLowerCase() === keybordKey.toLowerCase()) {
            e.preventDefault();
            action();
        }
    };
    React.useEffect(() => {
        document.addEventListener('keydown', onKeyDown);
        return () => {
            document.removeEventListener('keydown', onKeyDown);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};

export function MapKeybordEventsWrapper({ children }: React.PropsWithChildren<unknown>) {
    const map = useMapContext();
    const undoMap = useUndoRedoMap();

    const undo = () => {
        const nextState = undoMap.undo(map.getState().type);
        if (nextState) {
            map.update({ values: nextState, mapModified: true, logs: { event: 'undo map state from CTR + Z' } });
        }
    };

    const redo = () => {
        const nextState = undoMap.redo(map.getState().type);
        if (nextState) {
            map.update({ values: nextState, mapModified: true, logs: { event: 'redo map state from CTR + Y' } });
        }
    };

    useCtrlKeybord('Z', undo);
    useCtrlKeybord('Y', redo);

    return <>{children}</>;
}
