import './PromoBandeau.scss';

import CloseIcon from '@material-ui/icons/Close';
import { styled } from '@material-ui/styles';
import React from 'react';
import { useIsVendeur } from '../../Web/Hooks/Vendeur/useVendeur';
import { ThemeRes } from '../../Web/Themes/ThemeRes';
import { PromotionBandeauDto } from '../../Models/Promos/PromotionBandeauDto';
import DOMPurify from 'dompurify';

const toPromoHiddenKey = (id: string) => `promo-${id}-hidden`;

const showPromo = (isVendeur: boolean, isHidden: boolean) => {
    return !isVendeur && !isHidden;
};

export const Promo = ({ promo }: { promo: PromotionBandeauDto }) => {
    const promoHiddenKey = toPromoHiddenKey(promo.id);

    const isVendeur = useIsVendeur();
    const [isHidden, setIsHidden] = React.useState(localStorage.getItem(promoHiddenKey) === 'true');
    const show = showPromo(isVendeur, isHidden);

    const link = ExtractHrefIfSingle(promo.description);
    const sanitizedHtml = DOMPurify.sanitize(promo.description, { ADD_ATTR: ['target'] });

    return !show ? null : (
        <Container
            {...(link ? { onClick: () => window.open(link, '_blank') } : undefined)}
            style={{
                cursor: link ? 'pointer' : 'default',
                backgroundColor: promo.background_color,
                color: promo.font_color,
                fontSize: promo.font_size,
            }}>
            <Description className="PromoBandeau-Description">
                <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
            </Description>
            <Close
                onClick={(event) => {
                    event.stopPropagation();
                    localStorage.setItem(promoHiddenKey, 'true');
                    setIsHidden(true);
                }}>
                <CloseIcon />
            </Close>
        </Container>
    );
};

//move to utils
const ExtractHrefIfSingle = (html: string) => {
    const regex = /<a\s+(?:[^>]*?\s+)?href=(["'])(.*?)\1/g;
    const match = [...html.matchAll(regex)];
    if (match?.length === 1) {
        return match[0][2];
    }
    return null;
};

const Container = styled('div')({
    height: 'auto',
    background: '#ff000c',
    margin: 0,
    padding: '0 8px',
    textAlign: 'center',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    fontSize: '1rem',
    a: {
        textDecoration: 'none',
    },
});

const Description = styled('div')({
    fontFamily: ThemeRes.Fonts.fontFamilyNunitoSans,
    padding: 8,
    flex: 1,
});

const Close = styled('div')({
    color: 'white',
    cursor: 'pointer',
    transition: 'color 0.3s',
    '&:hover': { color: 'black' },
    display: 'flex',
    alignItems: 'center',
});
