import React from 'react';

import { useMapContextState } from '../../../Context/MapContext';
import { RoomType } from '../../../Models/IRoom';
import { IRoomItem } from '../../../Models/IRoomItem';
import { PlacingRoomItem } from './BoxBindRoomItem';

type BoxRoomItemsProps = { readonly?: boolean; roomId?: string; roomItems?: Array<IRoomItem>; type?: RoomType };
export const MapBoxRoomItems = () => {
    const { type, roomItems = [], rooms = [] } = useMapContextState();
    const roomIds = rooms.filter((room) => room.type === type).map((room) => room.roomId!);
    const roomItemsToDisplay = roomItems.filter((item) => roomIds.includes(item.roomId!) || !item.roomId);
    return <RawBoxRoomItems roomItems={roomItemsToDisplay} />;
};

export const RawBoxRoomItems = ({ readonly, roomId, roomItems = [], type }: BoxRoomItemsProps) => {
    const { rooms = [] } = useMapContextState();
    let roomItemsToDisplay = roomItems;
    if (type) {
        const roomIds = rooms.filter((room) => room.type === type).map((room) => room.roomId!);
        roomItemsToDisplay = roomItems.filter((item) => roomIds.includes(item.roomId!) || !item.roomId);
    }
    return (
        <React.Fragment>
            {roomItemsToDisplay
                .filter((item) => (roomId ? item.roomId === roomId : true))
                .map((roomItem) => (
                    <BoxRoomItem key={roomItem.roomItemId} item={roomItem} readonly={readonly} />
                ))}
        </React.Fragment>
    );
};

type BoxRoomItemProps = { item: IRoomItem; readonly?: boolean };
const BoxRoomItem = ({ item, readonly }: BoxRoomItemProps) => {
    return (
        <React.Fragment>
            <PlacingRoomItem key={item.roomItemId} item={item} readonly={readonly} />
        </React.Fragment>
    );
};
