import { I18n } from '../Locales/I18nService';
import { ProduitDto } from '../Models/Questions/ProduitDto';
import { ProductProperties } from '../Models/Questions/QuestionDto';
import { TauxTvaDto } from '../Models/VersionParametrages/VersionParametrage';
import { DEFAULT_LAIZE_PROPS, LaizeProps, ProductType } from '../Plugins/FloorPlan/Components/Laizes/Laize';
import { IRoom } from '../Plugins/FloorPlan/Models/IRoom';
import { TauxDeTva } from './TauxDeTvaHelper';

export class ProductHelper {
    public static productCodeTrimFrontZero = (code: string) => parseInt(code).toString();

    public static toProduitPrixTTC(produit: ProduitDto, tauxDeTva?: TauxDeTva): number | undefined {
        switch (tauxDeTva) {
            case TauxDeTva.intermediaire:
            case TauxDeTva.intermédiaire:
                return (
                    produit.infos_vente?.prix_ttc?.tva_intermediaire?.prix_produit ??
                    produit.infos_vente?.prix_ttc?.tva_normale?.prix_produit
                );
            default:
                return produit.infos_vente?.prix_ttc?.tva_normale?.prix_produit;
        }
    }

    public static toPrixTTC(priceHT: number, contextTauxDeTva?: TauxDeTva, tauxTva?: TauxTvaDto): number {
        const getTvaPercentage = () => {
            if (tauxTva) {
                switch (contextTauxDeTva) {
                    case TauxDeTva.intermediaire:
                        return tauxTva.by_evaluation_value.intermediaire;
                    case TauxDeTva.intermédiaire:
                        return tauxTva.by_evaluation_value.intermédiaire;
                    case TauxDeTva.normal:
                        return tauxTva.by_evaluation_value.normal;
                    default:
                        return tauxTva.default;
                }
            }
            return undefined;
        };
        const tvaPercentage: number = getTvaPercentage() ?? 0;
        const priceTTC: number = tvaPercentage ? priceHT * (1 + tvaPercentage) : priceHT;
        const roundedPriceTTC = Math.round(priceTTC * 100) / 100;
        return roundedPriceTTC;
    }

    public static devidePrice = (price: number) => {
        const floor = Math.floor(price);
        const decimal = price.toFixed(2).split('.')[1];
        return { floor, decimal };
    };

    public static toUnitPrice = (unite_vente: string): string => {
        return I18n.format(`Quote_Step9_PriceUnitFormat_${unite_vente === 'Piece' ? 'F' : 'M'}`, {
            unitPrice: I18n.format(`Quote_Step9_PriceUnitFormat_${unite_vente}`),
        });
    };

    public static toReference = (p: { code_article: string; code_coloris: string }) => {
        return I18n.format('Quote_ProductCodeShort', {
            code_article: ProductHelper.productCodeTrimFrontZero(p.code_article),
            code_coloris: ProductHelper.productCodeTrimFrontZero(p.code_coloris),
        });
    };

    public static toShortCode = (codeProduit: string = '') => {
        const [code_article = '', code_coloris = ''] = codeProduit.split('-');
        return I18n.format('Quote_ProductCodeShort', {
            code_article: ProductHelper.productCodeTrimFrontZero(code_article),
            code_coloris: ProductHelper.productCodeTrimFrontZero(code_coloris),
        });
    };

    public static getCompleteCode = (produit?: ProduitDto) => produit?.sous_famille_produit_complete_code;

    public static hasDefaultItem = (items: Array<ProduitDto> = []) => {
        const result: ProduitDto | undefined = items.find((x) => x.is_default);
        return result !== undefined;
    };

    public static toExcludeResponse = (productProperties: ProductProperties) => {
        return productProperties.exclude_response_label && productProperties.exclude_response_image_id
            ? {
                label: productProperties.exclude_response_label,
                imageId: productProperties.exclude_response_image_id,
            }
            : undefined;
    };

    public static toLaizeProps = (produit?: ProduitDto, roomId?: string): LaizeProps => {
        let laizeProps: LaizeProps = {
            ...DEFAULT_LAIZE_PROPS,
            productCode: produit?.code,
            type: this.getProductType(produit),
            produit,
            roomIds: roomId ? [roomId] : [],
        };
        switch (laizeProps.type) {
            case ProductType.Rouleau:
                const useMotif = produit?.attributs.find((x) => x.name === 'RACCORD_MOTIF_SOL_ROULEAU')?.value ?? false;
                laizeProps = {
                    ...laizeProps,
                    raccordCm: useMotif
                        ? produit?.attributs.find((x) => x.name === 'LONGUEUR_RACCORD_CM_SOL_ROULEAU')?.value ?? 0
                        : 0,
                    largeurMarginCm: useMotif
                        ? produit?.attributs.find((x) => x.name === 'TAILLE_LARG_MOTIF_CM_MOQUETTE')?.value ?? 0
                        : 0,
                    longueurMarginCm: useMotif
                        ? produit?.attributs.find((x) => x.name === 'TAILLE_LONG_MOTIF_CM_MOQUETTE')?.value ?? 0
                        : 0,
                    largeurCm: (produit?.infos_produit.largeur ?? 1) * 100, // infos_produit.largeur en mètre, traitement en cm
                    longueurCm: (produit?.infos_produit.longueur_rouleau ?? 500) * 100, // infos_produit.longueur_rouleau en mètre, traitement en cm
                };
                break;
            case ProductType.LamePleine:
                laizeProps = {
                    ...laizeProps,
                    raccordCm: 0,
                    largeurMarginCm: 0,
                    longueurMarginCm: 5,
                    largeurCm: produit?.attributs.find((x) => x.name === 'F008-Largeur')?.value ?? 0,
                    longueurCm: produit?.attributs.find((x) => x.name === 'S057-Longueur')?.value ?? 0,
                };
                break;
        }
        return laizeProps;
    };

    public static ToArrayLaizeProps = (rooms?: Array<IRoom>) => {
        return (
            rooms?.reduce((acc, curr) => {
                const produitPrincipal = curr.questionProduitPrincipal?.produitValue;
                if (!produitPrincipal) {
                    return acc;
                }
                const laizeProps = acc.find((x) => x.productCode === produitPrincipal.code);
                if (laizeProps) {
                    laizeProps.roomIds?.push(curr.roomId!);
                } else {
                    acc.push(ProductHelper.toLaizeProps(produitPrincipal, curr.roomId));
                }
                return acc;
            }, [] as Array<LaizeProps>) ?? []
        );
    };

    public static getProductType = (produit?: ProduitDto): ProductType | undefined => {
        if (this.isLaizeProduct(produit)) {
            return ProductType.Rouleau;
        } else if (this.isLameProduct(produit)) {
            return ProductType.LamePleine;
        }
        return undefined;
    };

    public static isLaizeProduct(produitPrincipal?: ProduitDto) {
        const produitPrincipalCompleteCode = ProductHelper.getCompleteCode(produitPrincipal);

        const famille_code_laize_list = [
            'F001', //Moquette rouleau
            'F004_S023', //Autre revêtement - Gazon
            'F004_S034', //Autre revêtement - Sizal
            'F004_S035', //Autre revêtement - Jonc de mer
            'F006_S041', //Vinyle - Rouleau
        ];

        return produitPrincipalCompleteCode
            ? !!famille_code_laize_list.find((x) => produitPrincipalCompleteCode.startsWith(x))
            : false;
    }

    public static isLameProduct(produit?: ProduitDto) {
        const sous_famille_exterieur = 'S057';
        const produitIsExterieur =
            produit?.sous_famille_produit_complete_code.endsWith(sous_famille_exterieur) ?? false;
        const produitIsLame = produit?.attributs.find((x) => x.name === 'TYPE_SOL_TERRASSE')?.value === 'Lame';
        return produitIsExterieur && produitIsLame;
    }
    public static sortByLaizeType(p1: ProduitDto, p2: ProduitDto) {
        const p1IsLaize = ProductHelper.isLaizeProduct(p1);
        const p2IsLaize = ProductHelper.isLaizeProduct(p2);
        const compareCode = (p1: ProduitDto, p2: ProduitDto) => p1.code.localeCompare(p2.code);
        return (p1IsLaize && p2IsLaize) ? compareCode(p1, p2) : (p1IsLaize && !p2IsLaize) ? -1 : 1;
    }

    public static toMetierDePose = (produit?: ProduitDto): string => {
        return produit?.attributs?.find((x) => x.name === 'METIER_DE_POSE')?.value || '';
    };
}
