import { ObjectUtils } from '../../../Helpers/ObjectUtils';
import { I18n } from '../../../Locales/I18nService';
import { IRoomItem, RoomItemType } from '../Models/IRoomItem';
import { IdGenerator } from '../Utils/IdGenerator';
import { PointUtils } from '../Utils/PointUtils';

export class RoomItemFactory {
    //#region //* NAME GENERATOR
    public static getNewName = (baseRoomItem: IRoomItem, roomItems: Array<IRoomItem>) => {
        return RoomItemFactory.isDefaultName(baseRoomItem)
            ? RoomItemFactory.getDuplicateName(baseRoomItem, roomItems)
            : RoomItemFactory.toUniqueName(baseRoomItem.type!, roomItems);
    };
    public static defaultName = (roomItemCount: number = 0) => `Aménagement ${roomItemCount + 1}`;

    public static isDefaultName = (roomItem: IRoomItem) => {
        const [name, num] = roomItem.name!.split(' ');
        return name === 'Aménagement' && Boolean(num.length) && Number.isFinite(parseInt(num));
    };
    public static getDuplicateName(roomItem: IRoomItem, roomItems: Array<IRoomItem>) {
        const roomItemNumbers = roomItems.filter(this.isDefaultName).map((re) => {
            const num = re.name!.split(' ')[1];
            return parseInt(num);
        });
        if (roomItemNumbers.length) {
            const name = roomItem.name!.split(' ')[0];
            const max = Math.max(...roomItemNumbers) + 1;
            return `${name} ${max}`;
        }
        return `${roomItem.name} 2`;
    }

    public static toUniqueName = (type: RoomItemType, roomItems: Array<IRoomItem>) => {
        let baseName = '';
        switch (type) {
            case RoomItemType.Cupboard:
                baseName = I18n.get('RoomItem_Cupboard');
                break;
            case RoomItemType.Divider:
                baseName = I18n.get('RoomItem_Divider');
                break;
            case RoomItemType.Fireplace:
                baseName = I18n.get('RoomItem_Fireplace');
                break;
            case RoomItemType.FixedFurniture:
                baseName = I18n.get('RoomItem_FixedFurniture');
                break;
            case RoomItemType.HeavyElement:
                baseName = I18n.get('RoomItem_HeavyElement');
                break;
            case RoomItemType.KitchenFurniture:
                baseName = I18n.get('RoomItem_KitchenFurniture');
                break;
            case RoomItemType.Opening:
                baseName = I18n.get('RoomItem_Opening');
                break;
            case RoomItemType.SewerPlate:
                baseName = I18n.get('RoomItem_SewerPlate');
                break;
            case RoomItemType.ShowerBathtub:
                baseName = I18n.get('RoomItem_ShowerBathtub');
                break;
            case RoomItemType.Stairs:
                baseName = I18n.get('RoomItem_Stairs');
                break;
            case RoomItemType.Trap:
                baseName = I18n.get('RoomItem_Trap');
                break;
            case RoomItemType.Door:
                baseName = I18n.get('RoomItem_Door');
                break;
            case RoomItemType.Window:
                baseName = I18n.get('RoomItem_Window');
                break;
            case RoomItemType.UnlaidArea:
                baseName = I18n.get('RoomItem_UnlaidArea');
                break;
        }

        const toBaseName = () => {
            let wantedName = baseName;
            if (!roomItems.some((x) => x.name === wantedName)) {
                return wantedName;
            } else {
                let i = 2;
                wantedName = `${baseName} ${i}`;
                // eslint-disable-next-line no-loop-func
                while (roomItems.some((x) => x.name === wantedName)) {
                    i++;
                    wantedName = `${baseName} ${i}`;
                }
                return wantedName;
            }
        };
        return toBaseName();
    };

    //#endregion

    public static createRoomItem = (base: Partial<IRoomItem>): IRoomItem => {
        //from params : width, height, name, type, putFlooring, putPlinthes, isMoveableOutside
        const placingRoomItem: IRoomItem = ObjectUtils.clone(
            this.create({
                ...base,
                roomItemId: IdGenerator.generate(),
            })
        );
        return placingRoomItem;
    };

    public static create = (from: IRoomItem): IRoomItem => {
        const item: IRoomItem = {
            ...from,
            roomItemId: from.roomItemId,
            roomId: from.roomId,
            type: from.type,
            name: from.name,
            putFlooring: from.putFlooring,
            putPlinthes: from.putPlinthes,
            isMoveableOutside: from.isMoveableOutside || from.type === RoomItemType.Cupboard, // #20350
            width: from.width,
            height: from.height,
            graphTranslation: from.graphTranslation || { x: 3300, y: 1700 },
        };

        const halfWidth = item.width! / 2;
        const halfHeight = item.height! / 2;
        item.coords = [
            { x: -halfWidth, y: -halfHeight },
            { x: halfWidth, y: -halfHeight },
            { x: halfWidth, y: halfHeight },
            { x: -halfWidth, y: halfHeight },
            { x: -halfWidth, y: -halfHeight },
        ];

        item.coordsReal = item.coords.map((x) => PointUtils.translation(x, item.graphTranslation!));

        return item;
    };
}
